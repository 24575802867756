import React, { useState, useEffect } from "react";
import { Input } from "../formGroup/Index";
import DltModal from "../formGroup/DltModal";
import axios from "axios";
import { message } from "antd";

function EducationalDetails(props) {
  const [edustate, setEduState] = useState(false);

  // console.log(props.data.educationDetails);

  const [qualification, setQualification] = useState("");
  const [passingYear, setPassingYear] = useState("");
  const [institute, setInstitute] = useState("");

  const [editState, setEditState] = useState(false);
  const [state, setState] = useState("");
  const [tde, setTde] = useState([]);

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const checkFillidOrNot = () => {
    if (props.data.expertise.length >= 1) {
      // setHide(true)
      // setEduState(true)
      // setExpertExist(true)
      // setExpert(props.data.expertise[0].specialization)
      // setBrief(props.data.expertise[0].briefIntro)
      // setId(props.data.expertise[0].id)
    }
  };
  const addmore = (e) => {
    setEduState(false);
    setState({ [e.target.name]: "" });
  };
  const removeBatch = (id) => {
    const updateList = tde.filter((elm, ind) => {
      return ind !== id;
    });
    setTde(updateList);
  };
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const editA = (qualification, institute, passingYear, id) => {
    setQualification(qualification);
    setInstitute(institute);
    setPassingYear(passingYear);
    setId(id);
    setIsEdit(true);
    setEduState(true);
    // addD();
  };
  const [hdn, setHdn] = useState(false);

  const [k, setK] = useState("hidden");
  const addD = () => {

    setEduState(!edustate);
    setQualification("");
    setInstitute("");
    setPassingYear("");
    setId("");
    setIsEdit(false);
    setHdn(!hdn);
    if (hdn === false) {
      setK("block");
    } else {
      setK("hidden");
    }
  };
  const cancel = () => {
    setEduState(false)
    isEdit(false);
    setQualification("");
    setInstitute("");
    setPassingYear("");
  };
  const [dlt, setDlt] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    props.loader(true);
    axios
      .post("/api/v1/education-details", {
        qualification: qualification,
        institute: institute,
        passingYear: passingYear,
      })
      .then(function (response) {
        // console.log(response);
        // alert(response.data.message);
        // console.log({role:response.data.role});
        props.loader(false);
        // console.log(response.data);
        setEduState(false);
        message.success(response.data.message);
        props.onClick();
        // alert('response.data.message')
        // setState({ ...intialValue })
      })
      .catch(function (error) {
        // console.log(error)
        // console.log(error.response.data.errors);
        props.loader(false);
        message.error(error.response.data.errors);
      });
  };
  const remove = (id) => {
    // alert(id)
    props.loader(true);
    axios
      .delete(`/api/v1/education-details/${id}`)
      .then(function (response) {
        props.loader(false);
        message.success(response.data.message);
        props.onClick();
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response.data.errors);
        props.loader(false);
        message.error(error.response.data.errors);
      });
  };
  const update = () => {
    props.loader(true);
    axios
      .patch(`/api/v1/education-details/update/${id}`, {
        qualification: qualification,
        institute: institute,
        passingYear: passingYear,
      })
      .then(function (response) {
        // console.log(response.data);
        props.loader(false);
        setQualification("");
        setInstitute("");
        setPassingYear("");
        setEduState(false);
        message.success(response.data.message);
        props.onClick();
        setIsEdit(false);
      })
      .catch(function (error) {
        props.loader(false);
        console.log(error);
        console.log(error.response.data.errors);

        message.error(error.response.data.errors);
      });
  };
  useEffect(() => {
    checkFillidOrNot();
  });

  return (
    <div>
      <div className="  " style={{ width: "100%" }}>
        <div className="flex space-x-2 justify-between ">
          <h1 className=" text-[1.7rem] mt-[2rem]" id="edu">
            Educational Details
          </h1>
          <div className="flex gap-2">
            <button
              className="mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
              onClick={addD}
            >
              Add
            </button>
          </div>
        </div>

        {edustate ? (
          <>
            <div className="grid grid-cols-2  justify-between gap-2">
              <div className="w-full">
                <Input
                  placeholder="Qualification/Degree"
                  label="Qualification"
                  name="qualification"
                  type="text"
                  value={qualification}
                  onChange={(e) => setQualification(e.target.value)}
                />
              </div>
              <Input
                placeholder="Institute"
                label="Institute"
                name="institute"
                type="text"
                value={institute}
                onChange={(e) => setInstitute(e.target.value)}
              />
              <div className="col-span-full">
                <Input
                  placeholder="Graduate in"
                  label="Passing Year"
                  name="passingYear"
                  type="number"
                  value={passingYear}
                  onChange={(e) => setPassingYear(e.target.value)}
                />
              </div>

              <button
                type="submit"
                className="mt-auto px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none"
                onClick={isEdit ? update : onSubmit}
              >
                {isEdit ? "Update" : "Submit"}
              </button>
              <button
                className={`${k} mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`}
                onClick={isEdit ? cancel : addD}
              >
                {isEdit ? "Cancel" : "Close"}{" "}
              </button>
            </div>
          </>
        ) : null}

        {/* {props.data.educationDetails.length >= 1 &&
                    <> */}
        {props.data.educationDetails?.map((elm, ind) => {
          // console.log(elm);
          return (
            <>
              <div
                className="flex gap-2 items-center justify-between mt-7"
                style={{ width: "100%" }}
                key={ind}
              >
                <div className="edu1">
                  {elm.qualification} from {elm.institute}
                </div>
                <div className="edu2 flex space-x-2"> {elm.passingYear}</div>
                <div className="flex space-x-4">
                  <button
                    className="my-auto  px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-emerald-400 hover:text-black border-2 border-green-900 focus:outline-none"
                    onClick={() => {
                      editA(
                        elm.qualification,
                        elm.institute,
                        elm.passingYear,
                        elm.id
                      );
                    }}
                  >
                    edit
                  </button>
                  <button
                    className=" px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                    onClick={() => remove(elm.id)}
                  >
                    delete
                  </button>
                </div>
              </div>
              <hr className="facultyHr" />
            </>
          );
        })}
        {/* </>
                } */}
      </div>
    </div>
  );
}

export default EducationalDetails;
