import React, { useState } from "react";


function StudentFeedbackQues({handleChange,value}) {
    const questions = {
        q0:"",
          q1:"",
          q2:"",
          q3:"",
          q4:"",
          q5:"",
          q6:"",
          q7:"",
          q8:"",
          q9:"",
          q10:"",
      }
      const [question , setQuestion] = useState(questions)
      const [achievement , setAchievement] = useState('')


  return (
    <div>
      <div className=" grid grid-cols-1 justify-items-center w-1/2 gap-x-4 gap-y-2 ">
        <div className="col-span-full">
          <label for="question">
            Special Achievement (STET, CTET, SLET, NET, ICSSR, GET/ others)
            विशेष उपलब्धि (STET, CTET, SLET, NET, ICSSR, GET/ अन्य)
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
            value={value.q0}
            onChange={
              handleChange('q0')
            }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="STET">
              STET
            </option>
            {/* <option className="" value='1'>Admin</option> */}
            <option className="" value="CET">
              CET
            </option>
            <option className="" value="SLET">
              SLET
            </option>
            <option className="" value="NET">
              NET
            </option>
            <option className="" value="ICSSR">
              ICSSR
            </option>
            <option className="" value="GET">
              GET
            </option>
            <option className="uppercase" value="others">
              others
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          1. Curriculum and Syllabi of the Courses is interesting.
पाठ्यचर्या और पाठ्यक्रमों का पाठ्य विवरण रुचिकर/दिलचस्प है/
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
             value={value.q1}
             onChange={
               handleChange('q1')
             }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          2. Curriculum and Syllabus are need based.
पाठ्यचर्या और पाठ्यक्रम आवश्यकता आधारित है/
*
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
                value={value.q2}
                onChange={
                  handleChange('q2')
                }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          3. Course delivery by faculty members in the class properly.
कक्षा में संकाय सदस्यों/शिक्षकों द्वारा पाठ्यक्रम ठीक से प्रेषित/प्रस्तुतीकरण किया गया /
*

            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
               value={value.q3}
               onChange={
                 handleChange('q3')
               }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          4. Used of teaching aids and ICT in the class by faculty members to facilitate teaching.
बेहतर शिक्षण हेतु कक्षा में  शिक्षण सहायक सामग्री और आई0सी0टी0 का उपयोग शिक्षकों द्वारा किया गया/
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
                value={value.q4}
                onChange={
                  handleChange('q4')
                }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          5.  Opportunities are provided for students to participate in internships, field visits or research activities.
छात्रों के लिए इंटर्नशिप, फील्ड विजिट या शोध गतिविधियों में भाग लेने का अवसर दिए गए।
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
                value={value.q5}
                onChange={
                  handleChange('q5')
                }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          6. Opportunities for out of class room learning (guest lectures, seminars, workshop, value added programmes, conferences, competitions etc.).
कक्षा के बाहर सीखने के अवसर (अतिथि व्याख्यान, सेमिनार, कार्यशाला, मूल्य वर्धित कार्यक्रम, सम्मेलन, प्रतियोगिताएं आदि) दिए गए/
*

            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
            value={value.q6}
            onChange={
              handleChange('q6')
            }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          7.  There is fairness in the evaluation procedures (quiz, assignments etc.).
मूल्यांकन प्रक्रियाओं (प्रश्नोत्तरी, असाइनमेंट आदि) में निष्पक्षता है/
*

            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
             value={value.q7}
             onChange={
               handleChange('q7')
             }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          8.   Exam schedule and result announced on time.
परीक्षा कार्यक्रम और परिणाम की समय पर घोषणा की गई/
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
             value={value.q8}
             onChange={
               handleChange('q8')
             }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          9. you recommend others to take admission in this program.
आप दूसरों को इस कार्यक्रम में प्रवेश लेने की सलाह देते हैं।
*
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
             value={value.q9}
             onChange={
               handleChange('q9')
             }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          10.  Overall a great learning experience.
कुल मिलाकर सीखने का अनुभव बहुत अच्छा रहा/
*
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
             value={value.q10}
             onChange={
               handleChange('q10')
             }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
      </div>
    </div>
  )
}

export default StudentFeedbackQues