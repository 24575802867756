import React, { useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Input } from "../../formGroup/Index";
import axios from "axios";
import { message } from "antd";
import Spinner from "../../page/facultydashboard/formGroup/Spinner";
import ads from "../../assests/Add_student.xlsx";
function Addstu(props) {
  const [name, setName] = useState("");
  const [session, setSession] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [registrationYear, setRegistrationYear] = useState("");
  const [universityRollNo, setUniversityRollNo] = useState("");
  const [dob, setDob] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [loader, setLoader] = useState(false);
  const [fatherName, setFatherName] = useState("");
  const [umsId, setUmsId] = useState("");
  const [selected, setSelected] = useState();
  console.log(selected);
  const uploadBulkData = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("file", selected);
    axios
      .post("/api/v1/upload/student/bulk-data", formData)
      .then((response) => {
        alert("Student Data Upload Successfully");
        props.viewAllStudent();
        setLoader(false);
      })
      .catch((error) => {
        console.log("Something went wrong", error);
        alert("Error in Document Upload ");
        setLoader(false);
      });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoader(true);
    axios
      .post("/api/v1/student/signup", {
        name: name,
        session: session,
        regNo: registrationNumber,
        univRollno: universityRollNo,
        dob: dob,
        mobileNo: mobileNo,
        emailId: emailId,
        registrationYear,
        fatherName,
        umsId,
      })
      .then(function (response) {
        setLoader(false);
        message.success(response.data.message);
        setName("");
        setEmailId("");
        setDob("");
        setSession("");
        setMobileNo("");
        setUniversityRollNo("");
        setRegistrationNumber("");
        props.viewAllStudent();
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error.response.data.errors);
        message.error(error.response.data.errors);
      });
  };
  return (
    <>
      {loader && <Spinner />}

      <Breadcrumbs
        page="Add Student"
        pagelink="/admin/dashboard/addstu"
        sublink="/admin/dashboard"
        subpage="Dashboard"
      />

      <div className="bg-white rounded-lg w-5/6 mx-4 shadow-xl  px-4 py-2 ">
        <form
          onSubmit={handleSubmit}
          className="w-5/6 grid mx-auto  items-center md:grid-cols-2 gap-x-4 gap-y-2 "
        >
          <Input
            label="Name"
            type="text"
            man="*"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            label="Father's Name"
            type="text"
            man="*"
            value={fatherName}
            onChange={(e) => setFatherName(e.target.value)}
          />
          <Input
            label="Session"
            type="text"
            man="*"
            value={session}
            onChange={(e) => setSession(e.target.value)}
          />
          <Input
            label="Registration Year"
            type="text"
            man="*"
            value={registrationYear}
            onChange={(e) => setRegistrationYear(e.target.value)}
          />
          <Input
            label="Student ID"
            man="*"
            type="text"
            value={umsId}
            onChange={(e) => setUmsId(e.target.value)}
          />
          <Input
            label="Registration Number"
            man="*"
            type="text"
            value={registrationNumber}
            onChange={(e) => setRegistrationNumber(e.target.value)}
          />
          <Input
            label=" University Roll No"
            man="*"
            type="text"
            value={universityRollNo}
            onChange={(e) => setUniversityRollNo(e.target.value)}
          />
          <Input
            label="Date of Birth"
            man="*"
            type="date"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
          />
          <Input
            label="Mobile No"
            man="*"
            type="tel"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
          />
          <Input
            label="Email ID"
            man="*"
            type="email"
            value={emailId}
            onChange={(e) => setEmailId(e.target.value)}
          />
          <div className="col-span-full">
            <div className="flex justify-center mt-2">
              <button className="mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="bg-white mt-2 rounded-lg w-5/6 mx-4 shadow-xl grid grid-cols-2  px-4 py-2 ">
        <fieldset className="w-full space-y-1 dark:text-gray-100">
          <label for="files" className="block text-sm font-medium">
            Or Upload in bulk{" "}
          </label>
          <div className="flex">
            <input
              type="file"
              name="files"
              accept=".xlsx"
              id="files"
              className="px-8 py-12 border-2 border-dashed rounded-md dark:border-gray-700 dark:text-gray-400 "
              onChange={(r) => setSelected(r.target.files[0])}
            />
          </div>
          <div className=" w-[75%]">
            <div className="flex justify-center mt-2">
              <button
                className="mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                onClick={uploadBulkData}
              >
                Upload
              </button>
            </div>
          </div>
        </fieldset>
        <div className="my-auto">
          <a
            class="inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
            href={ads}
          >
            click here to download format
          </a>
        </div>
      </div>
    </>
  );
}

export default Addstu;
