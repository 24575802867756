import React, { useEffect, useState } from "react";
import uni1 from "../../assests/uni1.jpeg";
import uni2 from "../../assests/uni2.jpeg";
import uni3 from "../../assests/uni3.jpeg";
import uni4 from "../../assests/uni4.jpeg";
import uni5 from "../../assests/uni5.jpeg";
import uni6 from "../../assests/uni6.jpeg";
import uni7 from "../../assests/uni7.jpeg";
import uni8 from "../../assests/uni8.jpeg";
import uni9 from "../../assests/uni9.jpeg";
import uni10 from "../../assests/uni10.jpeg";
import uni11 from "../../assests/uni11.jpeg";
import uni12 from "../../assests/uni12.jpeg";
import uni13 from "../../assests/uni13.jpeg";
import uni14 from "../../assests/uni14.jpeg";
import uni15 from "../../assests/uni15.jpeg";
import uni16 from "../../assests/uni16.jpeg";
import uni17 from "../../assests/uni17.jpeg";
import uni18 from "../../assests/uni18.jpeg";
import uni19 from "../../assests/uni19.jpeg";
import uni20 from "../../assests/uni20.jpeg";
import uni21 from "../../assests/uni21.jpeg";
import uni22 from "../../assests/uni22.jpeg";
import uni23 from "../../assests/uni23.jpeg";
import uni24 from "../../assests/uni24.jpeg";
import Navbar from "../../components/Navbar";
import Footer from "../../Footer";
import Topbar from "./Topbar";
import axios from "axios";

function ImgGallery() {
  const allImg = [
    uni1,
    uni2,
    uni3,
    uni4,
    uni5,
    uni6,
    uni7,
    uni8,
    uni9,
    uni10,
    uni11,
    uni12,
    uni13,
    uni14,
    uni15,
    uni16,
    uni17,
    uni18,
    uni19,
    uni20,
    uni21,
    uni22,
    uni23,
    uni24,
  ];
  const campus = [uni1, uni2, uni3, uni4, uni5, uni7, uni8, uni9, uni10, uni13];

  const eventImg = [
    uni6,
    uni14,
    uni15,
    uni16,
    uni17,
    uni18,
    uni19,
    uni20,
    uni21,
    uni22,
    uni23,
    uni24,
  ];
  const [step, setStep] = useState("step1");
  // console.log(useState)
  // console.log(step);
  const stepChanges = (value) => {
    // console.log(value)
    setStep(value);
  };
  const functionTwo = () => {
    // console.log("hello world");
  };
  const [imG ,setImG]= React.useState({});
  const [datas, setData] = React.useState({});
  const [loader, setLoader] = useState(true);
  // console.log(datas);
  const siteData = () => {
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0]);
        setLoader(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  React.useEffect(() => {
    siteData();
  }, []);
  const [dat,setDat] = useState([])
  const getGalleryData = ()=>{
    axios.get('/api/v1/find-file-galley')
  .then(function (response) {
    // handle success
    // console.log(response);
    setDat(response.data)

  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
  useEffect(()=>{
    getGalleryData()
  },[])
  return (
    <>
      <Topbar />
      <Navbar datas={datas} />
      <div className="flex justify-center  ">
        <button
          className="text-xs p-2 md:p-5 border  rounded-lg hover:bg-red-600 hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-5 font-bold"
          onClick={() => {
            stepChanges("step1");
          }}
        >
          All Image
        </button>

        <button
          className="text-xs p-2 md:p-5  border rounded-lg hover:bg-red-600 hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-5 font-bold"
          onClick={() => {
            stepChanges("step2");
          }}
        >
          Campus
        </button>

        <button
          className="text-xs p-2 md:p-5  border rounded-lg hover:bg-red-600 hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-5 font-bold"
          onClick={() => {
            stepChanges("step3");
          }}
        >
          Event
        </button>
      </div>
      {step == "step1" ? (
        <>
          <section className="py-6 col-span-full bg-gray-100">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                {dat.map((i) => {
                  return (
                    <>
                      <img
                        className="object-cover w-full bg-gray-500 aspect-square"
                        src={`/api/v1/gallery/${i.img}`}
                      />
                    </>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      ) : null}
      {step == "step2" ? (
        <>
          <section className="py-6 transition-all delay-1000 ease-in col-span-full bg-gray-100">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                {dat.map((i) => {
                  return (
                    <>
                    {
                      i.category === 'CAMPUS' &&
                      <img
                      className="object-cover w-full bg-gray-500 aspect-square"
                      src={`/api/v1/gallery/${i.img}`}
                      />
                    }
                    </>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      ) : null}
      {step == "step3" ? (
        <>
          <section className="py-6 col-span-full bg-gray-100">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                {dat.map((i) => {
                  return (

                    <>
                    {
                      i.category === 'EVENT' &&
                      <img
                      className="object-cover w-full bg-gray-500 aspect-square"
                      src={`/api/v1/gallery/${i.img}`}
                      />
                    }
                    </>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      ) : null}

      <Footer datas={datas} />
    </>
  );
}

export default ImgGallery;
