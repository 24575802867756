import { message } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import DltModal from "../formGroup/DltModal";
import { Input } from "../formGroup/Index";
function AdministrativePositions(props) {
  const [edustate, setEduState] = useState(false);
  const [status, setStatus] = useState(false);

  const initialState = {
    administrative: "",
    startingYear: "",
    endingYear: "",
  };
  const [administrative, setAdministrative] = useState("");
  const [startingYear, setStartingYear] = useState("");
  const [endingYear, setEndingYear] = useState("");

  const [hdn, setHdn] = useState(true);

  const [k, setK] = useState("hidden");
  const addD = () => {
    setEduState(!edustate);
    setHdn(!hdn);

    if (hdn === false) {
      setK("block");
    } else {
      setK("hidden");
    }
  };
  const [editState, setEditState] = useState(false);
  const [state, setState] = useState(initialState);
  const [tde, setTde] = useState([]);
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const addmore = (e) => {
    setEduState(false);
    setState({ [e.target.name]: "" });
  };
  const removeBatch = (id) => {
    const updateList = tde.filter((elm, ind) => {
      return ind !== id;
    });
    setTde(updateList);
  };
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const editA = (administrative, startingYear, endingYear, id) => {
    setAdministrative(administrative);
    setStartingYear(startingYear);
    setEndingYear(endingYear);
    setId(id);
    setIsEdit(true);
    addD();
  };
  const [dlt, setDlt] = useState(false);
  const cancel = () => {
    setAdministrative("");
    setStartingYear("");
    setEndingYear("");
    isEdit(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if(endingYear.length===0){
      setEndingYear("present")

    };
    axios
      .post("/api/v1/administrative-details", {
        administrative: administrative,
        startingYear: startingYear,
        endingYear: endingYear,
      })
      .then(function (response) {
        // console.log(response);
        // alert(response.data.message);
        // console.log({role:response.data.role});

        // console.log(response.data);
        message.success(response.data.message);
        setAdministrative("");
        setStartingYear("");
        setEndingYear("");
        setEduState(false);
        props.onClick()
        setIsEdit(false);
        // alert('response.data.message')
        // setState({ ...intialValue })
      })
      .catch(function (error) {
        // console.log(error)
        // console.log(error.response.data.errors);

        message.error(error.response.data.errors);
      });
  };
  const checkFillidOrNot = () => {
    if (props.data.expertise.length >= 1) {
      // setHide(true)
      // setEduState(true)
      // setExpertExist(true)
      // setExpert(props.data.expertise[0].specialization)
      // setBrief(props.data.expertise[0].briefIntro)
      // setId(props.data.expertise[0].id)
    }
  };
  const remove = (id) => {
    // alert(id)
    props.loader(true);
    axios
      .delete(`/api/v1/administrative-details/${id}`)
      .then(function (response) {
        props.loader(false);
        message.success(response.data.message);
        props.onClick();
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response.data.errors);
        props.loader(false);
        message.error(error.response.data.errors);

      });
  };
  const update = () => {
    props.loader(true);
    axios
      .patch(`/api/v1/administrative-details/update/${id}`, {
        administrative: administrative,
        startingYear: startingYear,
        endingYear: endingYear,
      })
      .then(function (response) {
        // console.log(response.data);
        props.loader(false);
        setAdministrative("");
        setStartingYear("");
        setEndingYear("");
        setEduState(false);
        message.success(response.data.message);
        props.onClick();
        setIsEdit(false);
      })
      .catch(function (error) {
        props.loader(false);
        console.log(error);
        // console.log(error.response.data.errors);

        message.error(error.response.data.errors);
      });
  };
  useEffect(() => {
    checkFillidOrNot();


  });

  return (
    <div>
      <div className="  " style={{ width: "100%" }}>
        <div className="flex space-x-2  justify-between ">
          <h1 className=" text-[1.7rem] mt-[2rem]" id="edu">
            Administrative Position
          </h1>
          <div className="flex gap-2">
            <button
              className="mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
              onClick={addD}
            >
              Add
            </button>
          </div>
        </div>
        {edustate ? (
          <>
            <div>
              <div className="grid grid-cols-2 justify-center gap-2">
                <div className="col-span-2">
                  <Input
                    placeholder="Postion at Institute"
                    label="Add Administrative Detail"
                    name="administrative"
                    type="text"
                    value={administrative}
                    onChange={(e) => setAdministrative(e.target.value)}
                  />
                </div>
                <div>
                  <Input
                    placeholder="Started at"
                    label="from Year"
                    name="startingYear"
                    type="number"
                    value={startingYear}
                    onChange={(e) => setStartingYear(e.target.value)}
                  />
                </div>
                {status ? (
                  <>
                    <div></div>
                  </>
                ) : (
                  <>
                    <div>
                      <Input
                        placeholder="left at"
                        label="to Year"
                        name="endingYear"
                        type="number"
                        value={endingYear}
                        onChange={(e) => setEndingYear(e.target.value)}
                      />
                    </div>
                  </>
                )}

                <div className="">

                </div>
                <div></div>
                <button
                  type="submit"
                  className="mt-auto px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none"
                  onClick={isEdit ? update : onSubmit}
                >
                  {isEdit ? "Update" : "Submit"}
                </button>
                <button
                  className={`${k} mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`}
                  onClick={isEdit ? cancel : addD}
                >
                  {isEdit ? "Cancel" : "Close"}{" "}
                </button>
              </div>
            </div>
          </>
        ) : null}

        {props.data.adminPosition?.map((elm, ind) => {
            // console.log(elm)
          return (
            <>
              <div className="  " style={{ width: "100%" }}>
                <div className="flex justify-between ">
                  <div
                    className="flex gap-2 items-center justify-between mt-7"
                    style={{ width: "100%" }}
                    key={ind}
                  >
                    <div className="edu1">{elm.administrative}</div>
                    <div className="edu2">
                      {" "}
                      {elm.startingYear} to {elm.endingYear}{" "}
                    </div>
                    <div className="flex space-x-4">
                      <button
                        className="my-auto  px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-emerald-400 hover:text-black border-2 border-green-900 focus:outline-none"
                        onClick={() => {
                          editA(
                            elm.administrative,
                            elm.startingYear,
                            elm.endingYear,
                            elm.id
                          );
                        }}
                      >
                        edit
                      </button>
                      <button
                        className=" px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                        onClick={() => remove(elm.id)}
                      >
                        delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="facultyHr" />
            </>
          );
        })}
      </div>
    </div>
  );
}

export default AdministrativePositions;
