import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Input } from "../formGroup/Index";

function Collab(props) {
  const [edustate, setEduState] = useState(false);
  const initialState = {
    collab: "",
  };
  // console.log(props.data);
  const [collaboration, setCollaboration] = useState("");
  const [editState, setEditState] = useState(false);
  const [state, setState] = useState();
  const [tde, setTde] = useState([]);
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const addmore = (e) => {
    setTde([...tde, { collab: state.collab }]);
    setEduState(false);
    setState({ [e.target.name]: "" });
  };
  const removeBatch = (id) => {
    const updateList = tde.filter((elm, ind) => {
      return ind !== id;
    });
    setTde(updateList);
  };
  const editA = (collaboration, id) => {
    setCollaboration(collaboration);
    setId(id);
    setIsEdit(true);
    setEduState(!edustate)

  };
  const [hdn, setHdn] = useState(false);

  const [k, setK] = useState("hidden");
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const addD = () => {
    setEduState(!edustate);
    setCollaboration("");
    setId("");
    setIsEdit(false);
    setHdn(!hdn);
    if (hdn === false) {
      setK("block");
    } else {
      setK("hidden");
    }
  };
  const cancel = () => {
    setCollaboration("");
    isEdit(false);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/v1/collaboration", {
        collaboration: collaboration,
      })
      .then(function (response) {
        // console.log(response);
        // alert(response.data.message);
        // console.log({role:response.data.role});
        // setTde([...tde, {  "subject": state.subject, "exptr": state.exptr, "brief":state.brief }]);
        setEduState(false);
        setState({ [e.target.name]: "" });
        // console.log(response.data);
        message.success(response.data.message);
        props.onClick()
        // alert('response.data.message')
        // setState({ ...intialValue })
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response.data.errors);
        message.error(error.response.data.errors);
      });
  };
  const checkFillidOrNot = () => {
    if (props.data.expertise.length >= 1) {
      // setHide(true)
      // setEduState(true)
      // setExpertExist(true)
      // setExpert(props.data.expertise[0].specialization)
      // setBrief(props.data.expertise[0].briefIntro)
      // setId(props.data.expertise[0].id)
    }
  };
  const remove = (id) => {
    // alert(id)
    props.loader(true);
    axios
      .delete(`/api/v1/collaboration/${id}`)
      .then(function (response) {
        props.loader(false);
        message.success(response.data.message);
        props.onClick();
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response.data.errors);
        props.loader(false);
        message.error(error.response.data.errors);
      });
  };

  const update = () => {
    props.loader(true);
    axios
      .patch(`/api/v1/collaboration/update/${id}`, {
        collaboration: collaboration,
      })
      .then(function (response) {
        // console.log(response.data);
        props.loader(false);
        setCollaboration("");
        setEduState(false);
        message.success(response.data.message);
        props.onClick();
        setIsEdit(false);
      })
      .catch(function (error) {
        props.loader(false);
        console.log(error);
        console.log(error.response.data.errors);

        message.error(error.response.data.errors);
      });
  };

  useEffect(() => {
    checkFillidOrNot();
  });
  return (
    <>
      <div className=" ">
        <div className="   " style={{ width: "100%" }}>
          <div className="flex space-x-2 justify-between ">
            <h1 className=" text-[1.7rem] mt-[2rem]" id="edu">
              Collaboration
            </h1>
            <div className="flex gap-2">
              <button
                className="mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                onClick={addD}
              >
                Add
              </button>
              {/* <button className={`${k} mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`} onClick={addD}>close</button> */}
            </div>
          </div>

          {edustate ? (
            <>
              <div className="grid grid-cols-3 space-x-2 justify-between gap-2">
                <div className="col-span-2">
                  <Input
                    placeholder="Brief detail about Collaboration"
                    label="Collaboration"
                    name="collab"
                    type="text"
                    value={collaboration}
                    onChange={(e) => {
                      setCollaboration(e.target.value);
                    }}
                  />
                </div>

                <div className="flex gap-x-2">
                  <h1 className="opacity-0"> k</h1>
                  <button
                    type="submit"
                    className="mt-auto px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none"
                    onClick={isEdit ? update : onSubmit}
                  >
                    {isEdit ? "Update" : "Submit"}
                  </button>
                  <button
                    className={`${k} mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`}
                    onClick={isEdit ? cancel : addD}
                  >
                    {isEdit ? "Cancel" : "Close"}{" "}
                  </button>
                </div>
              </div>
            </>
          ) : null}

          {props.data.collaborations.map((elm, ind) => {
            return (
              <>
                <div
                  className="flex gap-2 items-center justify-between mt-7"
                  style={{ width: "100%" }}
                  key={ind}
                >
                  <div className="edu1">{elm.collaboration}</div>
                  <div className="edu2">
                    {elm.author} {elm.year}
                  </div>
                  <div className="flex space-x-4">
                    <button
                      className="my-auto  px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-emerald-400 hover:text-black border-2 border-green-900 focus:outline-none"
                      onClick={() => editA(elm.collaboration, elm.id)}
                    >
                      edit
                    </button>
                    <button
                      className=" px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                   onClick={() => remove(elm.id)}
                    >
                      delete
                    </button>
                  </div>
                </div>
                <hr className="facultyHr" />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Collab;
