import React, { useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import Input from '../../formGroup/Input'
import axios from 'axios'
import Spinner from '../../page/facultydashboard/formGroup/Spinner'

import { message } from 'antd'
function SiteFooter() {

  const [address, setAddress] = useState('')
  const [mobileNo, setmobileNo] = useState('')
  const [emailId, setemailId] = useState('')
  // get data
  const [data, setData] = React.useState({})
  const [loader, setLoader] = React.useState(false)
  // console.log(data)
  const siteData = () => {
    setLoader(true)
    axios.get('/api/v1/api/site/data')
      .then(function (response) {
        // handle success
        // console.log(response);
        setLoader(false)
        setData(response.data[0])
        setAddress(response.data[0].address)
        setmobileNo(response.data[0].mobileNo)
        setemailId(response.data[0].emailId)

      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoader(false)
      })
  }
  //  change site name
  const fotterContentChange = (event) => {
    event.preventDefault()

    const formData = new FormData();
    formData.append('emailId', emailId);
    formData.append('mobileNo', mobileNo);
    formData.append('address', address);
    setLoader(true)
    axios.put(`/api/v1/api/site/footer-content/${data._id}`,{
      emailId,
      mobileNo,address:address
    },)
      .then(function (response) {
        setLoader(false)
        message.success(response.data.message)
        siteData()
      })
      .catch(function (error) {
        setLoader(false)
        message.error(error.response.data.errors)
      });


  }

  // change site title


  React.useEffect(() => {
    siteData()
  },[])
  return (
    <div>
    {
      loader && <Spinner/>
    }
      <Breadcrumbs page={"Site Footer"} pagelink="#" sublink='#' subpage={"Site Setting"} />
      <div className=' rounded-lg  mx-4 shadow-xl  px-4 py-2 grid grid-cols- ml-4 w-5/6 bg-white'>

        <div className="">
            <label

              className={`block mb-2 text-sm font-medium text-gray-900  `}
            >
              Dept Address
              <span className='text-red-500 font-bold text-[1.2rem]'>*</span>
            </label>
            <input
              type='text'

              value={address}
              onChange={(e) => { setAddress(e.target.value) }}
              className={`w-full h-9 mt-2 rounded-sm border focus:border-gray-700 p-3 text-sm focus:outline-none focus:bg-white bg-gray-200 `}
              placeholder=""
            // required='true'
            />
          </div>


        <div className="">
            <label

              className={`block mb-2 text-sm font-medium text-gray-900  `}
            >
              Dept Number
              <span className='text-red-500 font-bold text-[1.2rem]'>*</span>
            </label>
            <input
              type='text'

              value={mobileNo}
              onChange={(e) => { setmobileNo(e.target.value) }}
              className={`w-full h-9 mt-2 rounded-sm border focus:border-gray-700 p-3 text-sm focus:outline-none focus:bg-white bg-gray-200 `}
              placeholder=""
            // required='true'
            />
          </div>


        <div className="">
            <label

              className={`block mb-2 text-sm font-medium text-gray-900 `}
            >
              Dept Email
              <span className='text-red-500 font-bold text-[1.2rem]'>*</span>
            </label>
            <input
              type='text'

              value={emailId}
              onChange={(e) => { setemailId(e.target.value) }}
              className={`w-full h-9 mt-2 rounded-sm border focus:border-gray-700 p-3 text-sm focus:outline-none focus:bg-white bg-gray-200 `}
              placeholder=""
            // required='true'
            />
          </div>

      <center>
        <button onClick={fotterContentChange} className='inline-block mt-3  px-4 py-2 rounded border border-[#273272]   text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500'>update</button>

      </center>
      </div>
    </div>
  )
}

export default SiteFooter