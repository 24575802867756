import React from 'react'
import Loaders from './Loader'
import Logo from '../../../assests/logo.png'
function Spinner() {
  return (
    
        <Loaders>
            <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-800 opacity-75 flex flex-col items-center justify-center">
                <div class="loading-container ">
                    <div class="loading"></div>
                    <div id="loading-text"><img src={Logo} alt="Loading" /></div>
                </div>
            </div>
        </Loaders>
    
  )
}

export default Spinner