import React from 'react'
import { Link } from 'react-router-dom'

function Topbar() {
  return (
    <>
    <div className="px-8  bg-[#273272] text-gray-50">
	<div className="flex items-center mx-auto container gap-x-2 justify-end py-2">
		<div>
			<span>&nbsp;</span>
			<Link to="/live-classes" rel="noopener noreferrer" className="">Live Class</Link>
		</div>
		<Link to="/study-material" rel="noopener noreferrer" className="items-center gap-2  md:flex">

			<span className="hover:underline focus-visible:underline">Study material</span>
		</Link>
        {/* <a href="#" rel="noopener noreferrer" className="items-center gap-2  md:flex">

			<span className="hover:underline focus-visible:underline">Lectures</span>
		</a> */}
        <a href="#" rel="noopener noreferrer" className="items-center gap-2  md:flex">

			<span className="hover:underline focus-visible:underline">Webmail</span>
		</a>
	</div>
</div>
    </>
  )
}

export default Topbar