import React, { useEffect, useState } from 'react'
import {TfiAnnouncement} from 'react-icons/tfi'
import {MdEventAvailable} from 'react-icons/md'
import {GrFormNext} from 'react-icons/gr'
import HeroSection from './HeroSection'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {Badge} from 'antd'
import blink from './assests/download.gif'
function Notice() {
    const [notice, setNotice] = useState([])
    const [data, setData] = useState([])

  const [loader, setLoader] = useState(false)
  // console.log(data)
  const siteData = () => {
    axios.get('/api/v1/find-file-anouncement')
      .then(function (response) {
        setNotice(response.data)
      })
      .catch(function (error) {
        console.log(error);
      })
  }
  useEffect(() => {
    siteData()
  }, [])
  // console.log(data)
  const annData = () => {
    axios.get('/api/v1/find-file-news')
      .then(function (response) {
        setData(response.data)
      })
      .catch(function (error) {
        console.log(error);
      })
  }
  useEffect(() => {
    annData()
  }, [])
  return (
    <div className='bg-[#e7e7e]'>
        <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 m-2 sm:m-[4.3rem]'>
            <div className='border border-black bg-white rounded-md noticeBorder'>

                <center><TfiAnnouncement className='text-[#d12326] text-5xl font-bold m-4'/></center>
               <h1 className='text-center font-bold text-2xl mb-8'>NEWS & EVENTS</h1>
               <div>
               {
                data.toReversed().map((e,i)=>{
                    return(
                       <>
                       {e.publish &&  <div className='flex mt-4 ml-6 mb-2 mr-6 items-start gap-2'>
                    <div className='border rounded-3xl bg-gray-400 p-0 mt-1'><GrFormNext className='font-bold'/></div>
                    <Link to={`/api/v1/download-news/${e.fileName}`} target='_blank' className='text-[#d12326] flex'>{e.title} {i  < 3  &&<><sup><img src={blink}/></sup></> }</Link>
                </div>}
                       </>
                    )
                })
               }
               </div>
               <center><Link to="/notice">  <button  className='bg-[#d12326] pt-2 pb-2 pl-4 pr-4 text-white text-[0.8rem] rounded mt-4 mb-4'>VIEW ALL</button></Link></center>
            </div>
            <div className='border border-black bg-white rounded-md noticeBorder'>
            <center><MdEventAvailable className='text-[#d12326] text-5xl font-bold m-4'/></center>
            <h1 className='text-center font-bold text-2xl mb-8'>ANNOUNCEMENTS</h1>
            <div>
               {
                notice.toReversed().map((e,i)=>{
                    console.log(i)
                    return(
                      <>
                       {e.publish &&  <div className='flex mt-4 ml-6 mb-2 mr-6 items-start gap-2'>
                    <div className='border rounded-3xl bg-gray-400 p-0 mt-1'><GrFormNext className='font-bold'/></div>
                    <Link to={`/api/v1/download-anouncement/${e.fileName}`} target='_blank' className='text-[#d12326] flex'> {e.title} {i  < 3  &&<><sup><img src={blink}/></sup></> }</Link>
                </div>}
                      </>

                    )
                })
               }
               </div>
               <center><Link to="/notice">  <button    className='bg-[#d12326] pt-2 pb-2 pl-4 pr-4 text-white text-[0.8rem] rounded mt-4 mb-4'>VIEW ALL</button></Link></center>

            </div>

        </div>


    </div>
  )
}

export default Notice