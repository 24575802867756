import React, { useEffect, useState } from "react";
import NaacSsrAccordion from "./NaacSsrAccordion";
import axios from "axios";
import { Link } from "react-router-dom";

function NaacSsr() {
  const [step, setStep] = useState("step1");
  // console.log(useState)
  // console.log(step);
  const stepChanges = (value) => {
    // console.log(value)
    setStep(value);
  };
  const [data, setData] = useState([])

  const siteData = () => {
    axios.get('/api/v1/naac-ssr/data')
      .then(function (response) {
        setData(response.data)
      })
      .catch(function (error) {
        console.log(error);
      })
  }
  console.log(data)
  useEffect(() => {
    siteData()
  }, [])
  const button = [
    {
      name: "NAAC SSR Extended Profile",

      step: "step0",
    },
    {
      name: "NAAC SSR Criteria 1",
      step: "step1",
    },
    {
      name: "NAAC SSR Criteria 2",
      step: "step2",
    },

    {
      name: " NAAC SSR Criteria 3",
      step: "step3",
    },
    {
      name: "NAAC SSR Criteria 4",
      step: "step4",
    },
    {
      name: "NAAC SSR Criteria 5",
      step: "step5",
    },
    {
      name: "NAAC SSR Criteria 6",
      step: "step6",
    },
    {
      name: "NAAC SSR Criteria 7",
      step: "step7",
    },
  ];
  return (
    <div>
      <div className="flex  ">
        <div className="grid grid-col-1 justify-items-start  pt-5 px-12">
            {button.map((b,i)=>{
                return(
                    <>
                     <button
            className="text-base p-2 md:p-3  w-full  border rounded-lg hover:bg-red-600 hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-2 font-bold"
            onClick={() => {
              stepChanges(`${b.step}`);
            }}
          >
            {b.name}
          </button>
                    </>
                )
            })}


         </div>
         {data[0] &&
        <div className="w-full flex-1 mx-4 mt-8">
            {step==="step1" ?
<>
<div
  className="bg-red-600 px-4 py-3 text-white sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
>
  <p className="text-center font-medium sm:text-left">
  { data[0].criteria1 ? data[0].criteria1 :<>Data will be upload Soon</>}

  </p>
{ data[0].criteria1  &&
  <a
    className="mt-4 block rounded-lg bg-white px-5 py-3 text-center text-sm font-medium text-indigo-600 transition hover:bg-white/90 focus:outline-none focus:ring active:text-indigo-500 sm:mt-0"
    href={`/api/v1/naac/${data[0].criteria1}`} target="_blank" rel="noreferrer noopener"
  >
    view
  </a>}
</div>



</>:null   }


{step==="step2" ?
<>
<div
  className="bg-red-600 px-4 py-3 text-white sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
>
  <p className="text-center font-medium sm:text-left">
  { data[0].criteria2 ? data[0].criteria2 :<>Data will be upload Soon</>}

  </p>
{ data[0].criteria2 &&
  <a
    className="mt-4 block rounded-lg bg-white px-5 py-3 text-center text-sm font-medium text-indigo-600 transition hover:bg-white/90 focus:outline-none focus:ring active:text-indigo-500 sm:mt-0"
    href={`/api/v1/naac/${data[0].criteria2}`} target="_blank" rel="noreferrer noopener"
  >
    view
  </a>}
</div>



</> :null  }
{step==="step3" ?
<>
<div
  className="bg-red-600 px-4 py-3 text-white sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
>
  <p className="text-center font-medium sm:text-left">
 { data[0].criteria3 ? data[0].criteria3 :<>Data will be upload Soon</>}

  </p>
{ data[0].criteria3  &&
  <a
    className="mt-4 block rounded-lg bg-white px-5 py-3 text-center text-sm font-medium text-indigo-600 transition hover:bg-white/90 focus:outline-none focus:ring active:text-indigo-500 sm:mt-0"
    href={`/api/v1/naac/${data[0].criteria3}`} target="_blank" rel="noreferrer noopener"
  >
    view
  </a>}
</div>



</> :null  }
{step==="step4" ?
<>
<div
  className="bg-red-600 px-4 py-3 text-white sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
>
  <p className="text-center font-medium sm:text-left">
  { data[0].criteria4 ? data[0].criteria4 :<>Data will be upload Soon</>}

  </p>
{ data[0].criteria4 &&
  <a
    className="mt-4 block rounded-lg bg-white px-5 py-3 text-center text-sm font-medium text-indigo-600 transition hover:bg-white/90 focus:outline-none focus:ring active:text-indigo-500 sm:mt-0"
    href={`/api/v1/naac/${data[0].criteria4}`} target="_blank" rel="noreferrer noopener"
  >
    view
  </a>}
</div>



</> :null  }
{step==="step5" ?
<>
<div
  className="bg-red-600 px-4 py-3 text-white sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
>
  <p className="text-center font-medium sm:text-left">
  { data[0].criteria5 ? data[0].criteria5 :<>Data will be upload Soon</>}

  </p>
{data[0].criteria5 &&
  <a
    className="mt-4 block rounded-lg bg-white px-5 py-3 text-center text-sm font-medium text-indigo-600 transition hover:bg-white/90 focus:outline-none focus:ring active:text-indigo-500 sm:mt-0"
    href={`/api/v1/naac/${data[0].criteria5}`} target="_blank" rel="noreferrer noopener"
  >
    view
  </a>}
</div>



</> :null  }
{step==="step6" ?
<>
<div
  className="bg-red-600 px-4 py-3 text-white sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
>
  <p className="text-center font-medium sm:text-left">
  { data[0].criteria6 ? data[0].criteria6 :<>Data will be upload Soon</>}

  </p>
{ data[0].criteria6 &&
  <a
    className="mt-4 block rounded-lg bg-white px-5 py-3 text-center text-sm font-medium text-indigo-600 transition hover:bg-white/90 focus:outline-none focus:ring active:text-indigo-500 sm:mt-0"
    href={`/api/v1/naac/${data[0].criteria6}`} target="_blank" rel="noreferrer noopener"
  >
    view
  </a>}
</div>



</> :null  }
{step==="step7" ?
<>
<div
  className="bg-red-600 px-4 py-3 text-white sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
>
  <p className="text-center font-medium sm:text-left">
  { data[0].criteria7 ? data[0].criteria7 :<>Data will be upload Soon</>}

  </p>
{ data[0].criteria7 &&
  <a
    className="mt-4 block rounded-lg bg-white px-5 py-3 text-center text-sm font-medium text-indigo-600 transition hover:bg-white/90 focus:outline-none focus:ring active:text-indigo-500 sm:mt-0"
    href={`/api/v1/naac/${data[0].criteria7}`} target="_blank" rel="noreferrer noopener"
  >
    view
  </a>}
</div>



</> :null  }
        </div>
      }
      </div>
    </div>
  );
}

export default NaacSsr;
