import React from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'

function About() {
  return (
    <div>
      <Breadcrumbs page='about' pagelink="#" sublink='#' subpage='dashboard' />

      <div class="mx-auto  px-4 py-8">
        <section class="rounded-lg bg-white p-8">
          <div class="grid grid-cols-1 gap-12 sm:grid-cols-3 sm:items-center">


            <blockquote class="sm:col-span-2">
              <div>
                <label for="message" class="block mb-2 text-sm font-medium text-gray-900 ">Content Title</label>
                <input id="message" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write message here..."></input>
              </div>

              <div className='mt-7'>
                <label for="message" class="block mb-2 text-sm font-medium text-gray-900 ">Homepage Content</label>
                <textarea id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write message here..."></textarea>
              </div>
            </blockquote>
            <div>
              <img
                alt="Man"
                src="https://imgv3.fotor.com/images/blog-cover-image/part-blurry-image.jpg"
                class="aspect-square lg:w-1/2 w-full rounded-lg object-cover"
              />

              <input class="block text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none  dark:border-gray-600 dark:placeholder-gray-400 mt-4 lg:w-1/2 w-full p-2 " aria-describedby="user_avatar_help" id="user_avatar" type="file" />

            </div>
          </div>
        </section>
        <div className='flex justify-center mt-4'>
          <button type="button" class="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Update</button>
        </div>

      </div>

    </div>
  )
}

export default About