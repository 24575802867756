import React from 'react'
import NoOfTeacher from './NoOfTeacher'
import PhdThesis from './PhdThesis'
import Publicationsbyfaculty from './Publicationsbyfaculty'
import SeminarAttended from './SeminarAttended'
import SuccessOfStudent from './SuccessOfStudent'

function Statistics() {
  return (
    <div>
          <div className="mx-auto py-5 px-4 w-full max-w-7xl border-b-2 border-gray-200">
      <h3 className="text-5xl text-black font-black">Year of establishment</h3>
      <p className="mt-3  font-black text-sm text-black">Dept was established in the year 1997 12 (B) of UGC act vide Letter No.- 9-3 (929 CPP-1) dated 19th cot- 2004 and followed by B.N. Mandal University notification memo No-GS-1166/04 dated 31st Oct 2004.</p>
    </div>
    <NoOfTeacher/>
    <div className="mx-auto py-5 px-4 w-full max-w-7xl border-b-2 border-gray-200">
      <h3 className="text-5xl text-black font-black">Numbers of books in the Deptt. library</h3>
      <p className="mt-3  font-black text-sm text-black">University Dept. of Chemistry has 178 Text Books & 59 Reference Books till date about 70% of books are purchased in last tenth plan period from Univ. Grant Commission. Due to non availability of library space and non teaching staff in the department most of the books are temporarily shifted to central library of the University for Convenience and usefulness of the students as well as teachers. Where more than thousand books are available.</p>
    </div>
    <SuccessOfStudent/>
    <Publicationsbyfaculty/>
    <SeminarAttended/>
    <PhdThesis/>
    </div>
  )
}

export default Statistics