import React from "react";
import data from "./Data";

function PhdThesis() {
  return (
    <div>
      <div className="mx-auto py-5 px-4 w-full max-w-7xl border-b-2 border-gray-200">
        <h3 className="text-3xl text-black font-black">
          Number of Ph. D Thesis guided during the Last 5 Years.
        </h3>

        <div className="mt-2 relative overflow-x-auto">
          <table className="w-full border border-black  text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs border border-black text-gray-700 uppercase bg-gray-50  dark:text-gray-400">
              <tr className=" border text-base text-black border-black">
                <th scope="col" className="px-6  border border-black py-3">
                  Name of Teachers
                </th>
                <th scope="col" className="px-6 border border-black  py-3">
                  No. of Ph. D Thesis guided / award submitted
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((a, i) => {
                return (
                  <>
                    <tr className="bg-white border border-black   dark:border-gray-700">

                      <td className="px-6 border border-black   font-medium text-gray-900 whitespace-nowrap  py-4">
                        {a.NameofTeachers}
                      </td>
                      <td className="px-6 border border-black  font-medium text-gray-900 whitespace-nowrap   py-4">
                        {a.PhDThesis}
                      </td>

                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PhdThesis;
