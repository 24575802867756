import React from 'react'
import checkMark from '../assests/check-mark-verified.gif'
function Confirm(props) {
  return (
    <div>
          <div className='z-50 absolute inset-0 backdrop-blur'>
      <div className='grid grid-rows-1  justify-items-center h-screen items-center'>
      <div className="relative bg-white flex flex-col items-center max-w-lg gap-4 p-6 rounded-md  sm:py-8 sm:px-12  dark:text-gray-100 backdrop-blur"  style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>

    <img src={checkMark} className="w-1/2"/>
	{/* <BiXCircle className='text-9xl text-red-600'/> */}
	<h2 className="text-2xl font-semibold leading-tight tracking-wide">Ooops...!</h2>
	<p className="flex-1 text-center dark:text-gray-400">{props.message}</p>

</div></div>
    </div>
    </div>
  )
}

export default Confirm