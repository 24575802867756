import React, { useEffect, useState } from 'react'
import Topbar from '../landing/Topbar'
import Navbar from '../../components/Navbar'
import Footer from '../../Footer'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Spinner from '../facultydashboard/formGroup/Spinner'
function LiveClass() {
  const [classData, setClassData]= useState([])
  const [loader,setLoader] = useState(true)

    const [datas ,setData] =useState({})
    const siteData = () => {
        axios.get('/api/v1/api/site/data')
          .then(function (response) {
            // handle success
            // console.log(response);
            setData(response.data[0])
        setLoader(false)

          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
      }
      const addClassData =()=>{
        axios.get('/api/v1/class')
            .then(function (response) {
              // handle success
              setClassData(response.data)

              // console.log(response.data.userList);
            })
            .catch(function (error) {
              // handle error

              console.log(error);
            })

          }

          useEffect(() => {
            addClassData()

            return () => {
              siteData()
            }
          }, [classData])

  return (
    <>
    {
                loader && <Spinner />
            }
     <Topbar/>
    <Navbar datas={datas}/>

    <div class="overflow-x-auto mt-2 h-[12em] rounded-lg border border-gray-200">
          <table class="min-w-full divide-y-2 divide-gray-200 text-sm">
            <thead>
              <tr>
                <th class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Faculty
                </th>
                <th class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Topic of class
                </th>

                <th class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Link
                </th>
                <th class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Time of class
                </th>
              </tr>
            </thead>

{
                classData.data?.map((a, ind) => {

                  return (
                    <>

                       <tbody class="divide-y divide-gray-200" key={ind}>
                          <tr>
                            <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">{a.facultyName}</td>

                            <td class="whitespace-nowrap  border px-4 py-2 font-medium text-gray-900">
                              {a.topic}
                            </td>
                            <td class="whitespace-nowrap  border px-4 py-2 font-medium text-gray-900">
                              <center>

                             <a className='inline-block  rounded bg-[#273272] px-4 py-2 text-xs font-medium text-white hover:bg-red-700' href={a.link} target='_blank'>Click here to enter</a>
                              </center>
                            </td>
                            <td class="whitespace-nowrap  border px-4 py-2 font-medium text-gray-900">
                             on {a.classStart.slice(0, 10)} from {a.classStart.slice(12, (a.classStart.length ))} to {a.classEnd}
                            </td>



                          </tr>
                        </tbody>




                    </>
                  )
                })
              }
            </table>
            </div>

<Footer datas={datas}/>

    </>
  )
}

export default LiveClass