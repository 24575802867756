import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Dropdown, Input } from "../formGroup/Index";

function ResearchProjects(props) {
  const [edustate, setEduState] = useState(false);
  const [status, setStatus] = useState(false);


  const [title, setTitle] = useState("");
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [role, setRole] = useState("");

  const data = ["---select data---", "Ongoing", "Ended"];
  const [editState, setEditState] = useState(false);
  const [state, setState] = useState('');
  const [tde, setTde] = useState([]);
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const addmore = (e) => {
    setTde([
      ...tde,
      {
        title: title,
        startYear: startYear,
        endYear: endYear,
        role: role,
        status: status,
      },
    ]);
    setEduState(false);
    setState({ [e.target.name]: "" });
  };
  const removeBatch = (id) => {
    const updateList = tde.filter((elm, ind) => {
      return ind !== id;
    });
    setTde(updateList);
  };
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const editA = (title, role, status, startYear, endYear, id) => {

    setEduState(true);
    setIsEdit(true);
    setTitle(title);
    setRole(role);
    setStartYear(startYear);
    setEndYear(endYear);
    setStatus(status);
    setId(id)
    alert(id)
    // setImmediate(id);
  };
  const [hdn, setHdn] = useState(false);

  const [k, setK] = useState("hidden");
  const addD = () => {
    setEduState(!edustate);
    setTitle("");
    setRole("");
    setStartYear("");
    setEndYear("");
    setId("");
    setIsEdit(false);
    setHdn(!hdn);
    // if (hdn === false) {
    //   setK("block");
    // } else {
    //   setK("hidden");
    // }
  };
  // console.log(state.startYear);
  // console.log(state.endYear);
  const cancel = () => {
    setTitle("");
    setRole("");
    setStartYear("");
    setEndYear("");
    isEdit(false);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/v1/research-project", {
        title: title,
        role: role,
        status: status,
        startYear: startYear,
        endYear: endYear,
      })
      .then(function (response) {
        // console.log(response);
        // alert(response.data.message);
        // console.log({role:response.data.role});
        // setTde([...tde, {  "subject": state.subject, "exptr": state.exptr, "brief":state.brief }]);
        setEduState(false);
        setState({ [e.target.name]: "" });
        // console.log(response.data);
        message.success(response.data.message);
        props.onClick()
        // alert('response.data.message')
        // setState({ ...intialValue })
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response.data.errors);
        message.error(error.response.data.errors);
      });
  };
  const checkFillidOrNot = () => {
    if (props.data.expertise.length >= 1) {
      // setHide(true)
      // setEduState(true)
      // setExpertExist(true)
      // setExpert(props.data.expertise[0].specialization)
      // setBrief(props.data.expertise[0].briefIntro)
      // setId(props.data.expertise[0].id)
    }
  };

  // remove
  // /profDetails/:id
  const remove = (id) => {
    // alert(id)
    props.loader(true);
    axios
      .delete(`/api/v1/research-project/${id}`)
      .then(function (response) {
        props.loader(false);
        message.success(response.data.message);
        props.onClick();
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response.data.errors);
        props.loader(false);
        message.error(error.response.data.errors);
      });
  };

  const update = () => {
    props.loader(true);
    axios
      .patch(`/api/v1/research-product/update/${id}`, {
        title: title,
        role: role,
        status: status,
        startYear: startYear,
        endYear: endYear,
      })
      .then(function (response) {
        // console.log(response.data);
        props.loader(false);
        setTitle("");
        setRole("");
        setStartYear("");
        setEndYear("");
        setEduState(false);
        message.success(response.data.message);
        props.onClick();
        setIsEdit(false);
      })
      .catch(function (error) {
        props.loader(false);
        console.log(error);
        console.log(error.response.data.errors);

        message.error(error.response.data.errors);
      });
  };

  useEffect(() => {
    checkFillidOrNot();
  });
  return (
    <>
      <>
        <div className=" ">
          <div className="   " style={{ width: "100%" }}>
            <div className="flex space-x-2 justify-between ">
              <h1 className=" text-[1.7rem] mt-[2rem]" id="edu">
                Research Projects
              </h1>
              <div className="flex gap-2">
                <button
                  className="mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                  onClick={addD}
                >
                  Add
                </button>
              </div>
            </div>

            {edustate ? (
              <>
                <div className="grid grid-cols-2  justify-between gap-2">
                  <div className="w-full">
                    <Input
                      placeholder="Title of the project"
                      label="Title of the project"
                      name="title"
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <Input
                    placeholder="Role"
                    label="Role"
                    name="role"
                    type="text"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  />

                  {/* <div className='col-span-full'>

<Dropdown placeholder="" data={data} label="Status" name="status" type="text" value={status} onChange={onChange} />
</div> */}
                  <Input
                    placeholder="Starting year"
                    label="Starting Year"
                    name="startYear"
                    type="number"
                    value={startYear}
                    onChange={(e) => setStartYear(e.target.value)}
                  />

                  {status ? (
                    <>
                      <div></div>
                    </>
                  ) : (
                    <>
                      <Input
                        placeholder="Ending year"
                        label="Ending Year"
                        name="endYear"
                        type="number"
                        value={endYear}
                        onChange={(e) => setEndYear(e.target.value)}
                      />
                    </>
                  )}

                  <div className="">

                  </div>
                  <div></div>

                  <button
                    type="submit"
                    className="mt-auto px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none"
                    onClick={isEdit ? update : onSubmit}
                  >
                    {isEdit ? "Update" : "Submit"}
                  </button>
                  <button
                    className={`${k} mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`}
                    onClick={isEdit ? cancel : addD}
                  >
                    {isEdit ? "Cancel" : "Close"}{" "}
                  </button>
                </div>
              </>
            ) : null}

            {props.data.researchProj.map((elm, ind) => {
              // console.log(elm)
              return (
                <>
                  <div
                    className="flex gap-2 items-center justify-between mt-7"
                    style={{ width: "100%" }}
                    key={ind}
                  >
                    <div className="edu1">
                      {elm.title} {elm.role}
                    </div>
                    <div className="edu2">
                      {elm.status} {elm.startYear} to {elm.endYear}
                    </div>
                    <div className="flex space-x-4">
                      <button
                        className="my-auto  px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-emerald-400 hover:text-black border-2 border-green-900 focus:outline-none"
                        onClick={() =>
                          editA(
                            elm.title,
                            elm.role,
                            elm.status,
                            elm.startYear,
                            elm.endYear,
                            elm.id
                          )
                        }
                      >
                        edit
                      </button>
                      <button
                        className=" px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                        onClick={() => remove(elm.id)}
                      >
                        delete
                      </button>
                    </div>
                  </div>
                  <hr className="facultyHr" />
                </>
              );
            })}
          </div>
        </div>
      </>
    </>
  );
}

export default ResearchProjects;
