
import React, { useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import { Input, Textarea } from '../../formGroup/Index'
import axios from 'axios'
import { message } from 'antd'
import adr from "../../assests/Add_research.xlsx"
import Spinner from '../../page/facultydashboard/formGroup/Spinner';
import { Widget } from "@uploadcare/react-widget";
function AddResearchTopic(props) {
    const [name, setName] = useState("");
    const [areaOfResearch, setAreaOfResearch] = useState("");
    const [aboutResearch, setAboutResearch] = useState("");
    const [articleLink, setArticleLink] = useState("");
    const [otherFaculty, setOtherFaculty] = useState("");
    const [loader, setLoader] = useState(false);
    const [researchSelect, setResearchSelect] = useState()
    const [email, setEmail] = useState('')
    const [mobileNo, setMobileNo] = useState('')
    // image upload
    const [uploadedFile, setUploadedFile] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoader(true)
        axios.post('/api/v1/research/topc/add', {
            // name, thesisTitle, phdRegYear, phdRegNo, guideName, email, mobileNo
            facultyName: name, areaOfResearch: areaOfResearch, about: inputValue, link: articleLink, otherFacultyName: otherFaculty, photos: uploadedFile
        })
            .then(function (response) {
                setLoader(false)
                message.success(response.data.message);
                setName("")
                setAreaOfResearch("")
                setArticleLink("")
                setInputValue("")
                setOtherFaculty("")
                setUploadedFile("")
                props.viewAllStudent()
            })
            .catch(function (error) {
                setLoader(false)
                console.log(error.response.data.errors);
                message.error(error.response.data.errors);

            });


    };


    // hadle worda
    const [inputValue, setInputValue] = useState('');
    const maxWords = 100000000000000000000000000000000000000000000000000000000n;

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const wordsArray = inputValue.trim().split(/\s+/); // Split input by spaces to get words
        if (wordsArray.length <= maxWords) {
            setInputValue(inputValue);
        }
    };
    return (
        <>
            {
                loader && <Spinner />
            }

            <Breadcrumbs page="Add Research Topic" pagelink="/admin/dashboard/add/research" sublink="/admin/dashboard" subpage="Dashboard" />

            <div className='bg-white rounded-lg w-5/6 mx-4 shadow-xl  px-4 py-2 '>
                <form className='w-5/6 grid mx-auto  items-center md:grid-cols-2 gap-x-4 gap-y-2 '>

                    <Input label="Faculty Name" type="text" man="*" value={name} onChange={(e) => setName(e.target.value)} />
                    <Input label="Area of Research" type="text" man="*" value={areaOfResearch} onChange={(e) => setAreaOfResearch(e.target.value)} />
                    <div className='col-span-full'>
                    <label for="message"  class="block mb-2 text-sm font-medium text-gray-900 "> Write about research  <span className='text-red-500 font-bold text-[1.2rem]'></span></label>
                        <textarea
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500'
                            type="text"
                            rows='6'
                            value={inputValue}
                            onChange={handleInputChange}
                            maxLength={100000000000000000000000000000000000000000000000000000000n} // Optional: Limit total characters if needed
                        />

                    </div>
                    <Input label="Link of the article about research" type="text" value={articleLink} onChange={(e) => setArticleLink(e.target.value)} />
                    <Input label="Another Faculty Name (if involved )" type="text" value={otherFaculty} onChange={(e) => setOtherFaculty(e.target.value)} />
                    <div>
                        <span>Upload Photos <span className='text-red-500 font-bold'>*</span></span>
                        <div className='bg-[#e5e7eb] text-black-500'>
                            <Widget
                                publicKey="1e63758e5c94313da33d"
                                onChange={info => setUploadedFile(info.cdnUrl)}
                            />
                        </div></div>
                    {uploadedFile && (
                        <img src={uploadedFile} alt="Uploaded file" width="100" />
                    )}


                    <div className='col-span-full'>
                        <div className='flex justify-center mt-2'><button onClick={handleSubmit} className='mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2'>Submit</button></div>
                    </div>
                </form>
            </div>

        </>
    )
}

export default AddResearchTopic