import React, { useState, useEffect } from 'react'
import { Input } from '../formGroup/Index'
import DltModal from '../formGroup/DltModal'
import { message } from 'antd'
import axios from 'axios'

function ProfessionalBackground(props) {
    const [edustate, setEduState] = useState(false)
    const [status, setStatus] = useState(false)
    // console.log(props.data)

    const [editState, setEditState] = useState(false)
    const [state, setState] = useState("")
    const [designation, setDesignation] = useState('')
    const [institute, setInstitute] = useState('')
    const [endingYear, setEndingYear] = useState('')
    const [startingYear, setStartingYear] = useState('')
    const [tde, setTde] = useState([])
    const onChange = (e) => {

        setState({ ...state, [e.target.name]: e.target.value })
    }
    const addmore = (e) => {
        setTde([...tde, { "designation": state.designation, "institute": state.institute, "startingYear": state.startingYear, "endingYear": state.endingYear }]);
        setEduState(false)
        setState({ [e.target.name]: "" })
    }
    const removeBatch = (id) => {
        const updateList = tde.filter((elm, ind) => {
            return ind !== id;
        });
        setTde(updateList)
    }

    const [id, setId] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const editA = (designation, institute, startingYear, endingYear, id) => {
        // alert(designation,institute,startingYear,endingYear)
        // console.log(designation, institute, startingYear, endingYear)
        setDesignation(designation)
        setInstitute(institute)
        setStartingYear(startingYear)
        setEndingYear(endingYear)
        setId(id)
        setIsEdit(true)
        setEduState(!edustate)



    }
    const [hdn, setHdn] = useState(false)

    const [k, setK] = useState("hidden")
    const addD = () => {
        setEduState(!edustate)
        setDesignation("")
        setInstitute("")
        setStartingYear("")
        setEndingYear("")
        setId("")
        setIsEdit(false)
        setHdn(!hdn)
        if (hdn === false) {
            setK("block")

        }
        else {
            setK("hidden")
        }
    }
    const cancel = () => {
        setDesignation('')
        setInstitute('')
        setStartingYear('')
        setEndingYear('')
        isEdit(false)
    }
    const [dlt, setDlt] = useState(false)
    const onSubmit = (e) => {
        e.preventDefault()
        if(endingYear.length===0){
            setEndingYear("present")

        };
        axios.post(' /api/v1/profDetails', {
            designation: designation,
            Institute: institute,
            endingYear: endingYear,
            startingYear: startingYear,
        })
            .then(function (response) {
                // console.log(response.data)
                message.success(response.data.message)
                setEduState(false)
                setState({ [e.target.name]: "" })
                props.onClick()
            })
            .catch(function (error) {
                console.log(error)
                console.log(error.response.data.errors);

                message.error(error.response.data.errors)

            });
    }




    const checkFillidOrNot = () => {
        if (props.data.expertise.length >= 1) {
            // setHide(true)
            // setEduState(true)
            // setExpertExist(true)
            // setExpert(props.data.expertise[0].specialization)
            // setBrief(props.data.expertise[0].briefIntro)
            // setId(props.data.expertise[0].id)
        }
    }

    // remove
    // /profDetails/:id
    const remove = (id) => {
        // alert(id)
        props.loader(true)
        axios.delete(`/api/v1/profDetails/${id}`)
            .then(function (response) {
                props.loader(false)
                message.success(response.data.message)
                props.onClick()
            })
            .catch(function (error) {
                console.log(error)
                console.log(error.response.data.errors);
                props.loader(false)
                message.error(error.response.data.errors)

            });
    }

    const update = () => {
        props.loader(true)
        axios.patch(`/api/v1/profDetails/update/${id}`, {
            designation: designation,
            Institute: institute,
            endingYear: endingYear,
            startingYear: startingYear,
        })
            .then(function (response) {
                // console.log(response.data)
                props.loader(false)
                setDesignation('')
                setInstitute('')
                setEndingYear('')
                setStartingYear('')
                setEduState(false);
                message.success(response.data.message)
                props.onClick()
                setIsEdit(false)
            })
            .catch(function (error) {
                props.loader(false)
                console.log(error)
                console.log(error.response.data.errors);

                message.error(error.response.data.errors)

            });
    }

    useEffect(() => {
        checkFillidOrNot()
    })

    return (
        <>
            <div>
                <div className='  ' style={{ width: '100%' }}>
                    <div className='flex    justify-between '>

                        <h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Professional Background</h1>
                        <div className='flex gap-2'>

                            <button className='mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none' onClick={addD} >Add</button>
                        </div>
                    </div>
                    {edustate ? <>
                        <div className='grid grid-cols-2 justify-center gap-2'>
                            <div className=''>

                                <Input placeholder="Designation/Position" label="Designation" name="designation" type="text" value={designation}
                                    onChange={(e) => { setDesignation(e.target.value) }} />

                            </div>
                            <Input placeholder="Institute" label="Institute" name="institute" type="text" value={institute}
                                onChange={(e) => { setInstitute(e.target.value) }} />
                            <Input placeholder="Starting In" label="Starting Year" name="startingYear" type="number" value={startingYear}
                                onChange={(e) => { setStartingYear(e.target.value) }} />
                            {
                                status ? <>

                                    <div></div>
                                </> :
                                    <>
                                        <Input placeholder="Left at" label="Ending Year" name="endingYear" type="number" value={endingYear}
                                            onChange={(e) => { setEndingYear(e.target.value) }} />
                                    </>
                            }

                            <div className=''>

                            </div>
                            <div></div>

                            <button type="submit" className='mt-auto px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none' onClick={isEdit ? update : onSubmit} >{isEdit ? "Update" : "Submit"}</button>
                            <button className={`${k} mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`} onClick={isEdit ? cancel : addD}>{isEdit ? "Cancel" : "Close"} </button>


                        </div>



                    </> : null

                    }
                    {/* {
                        dlt ? <>
                            <div className='flex   h-screen'>
                                <div className='mx-auto '>
                                    <DltModal />

                                </div>
                            </div>
                        </> : null
                    } */}
                    {
                        props.data.profBackground.length >= 1 &&
                        <>
                            {props.data.profBackground.map((elm, ind) => {
                                // console.log(elm)
                                return (
                                    <>
                                        <div className='flex gap-2 items-center justify-between mt-7' style={{ width: '100%' }} key={ind}>
                                            <div className='edu1'>{elm.designation} at {elm.Institute}
                                            </div>
                                            <div className='edu2 flex space-x-2'> <span>{elm.startingYear} </span> to <span>{elm.endingYear} </span> </div>
                                            <div className='flex space-x-4'>

                                                <button className=' my-auto  px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-emerald-400 hover:text-black border-2 border-green-900 focus:outline-none' onClick={() => { editA(elm.designation, elm.Institute, elm.startingYear, elm.endingYear, elm.id) }}>edit</button>
                                                <button className=' px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none' onClick={() => remove(elm.id)}>delete</button>
                                            </div>
                                        </div>
                                        <hr className='facultyHr' />
                                    </>
                                )
                            })}
                        </>
                    }



                </div>
            </div>
        </>
    )
}

export default ProfessionalBackground