import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Navbar from "../../components/Navbar";
import Spinner from "../facultydashboard/formGroup/Spinner";
import Topbar from "../landing/Topbar";

function DownloadMisc() {
  const [download, setDownload] = React.useState([])
// console.log(data)
const downloadData = () => {
  axios.get('/api/v1/find-download-file')
    .then(function (response) {
      setDownload(response.data)
    })
    .catch(function (error) {
      console.log(error);
    })
}
// console.log(download,'kv')
React.useEffect(() => {
  downloadData()
}, [])
  const [datas, setData] = React.useState({});
  const [loader, setLoader] = useState(true);
  // console.log(datas);
  const siteData = () => {
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0]);
        setLoader(false)
        // .
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  React.useEffect(() => {
    siteData();
  }, []);
  return (
    <div>
      {
        loader && <Spinner/>
      }
      <Topbar />
      <Navbar datas={datas} />
      <Breadcrumbs
        page={"Download"}
        pagelink="#"
        sublink="#"
        subpage={"More"}
      />

      <div className="h-[30rem]  mt-4  px-4 mx-auto">
        <div class="overflow-x-auto   px-4">
          <table class="w-[30rem] border  divide-y-2 divide-gray-200 text-sm">
            <thead></thead>
            <tbody class="divide-y border  divide-gray-200">
              { download.toReversed().map((d)=>{
              console.log(d)
                return(
                  <>
                 {
                  d.publish &&

              <tr>

                <td class="whitespace-nowrap border  px-4 py-2 font-medium text-gray-900">
                  {d.title}
                </td>
                <td class="whitespace-nowrap border  px-4 py-2">
                <Link
                    to={`/api/v1/download/${d.fileName}`} target="_blank"
                    class="inline-block  rounded bg-red-600 px-4 py-2 text-xs font-medium text-white hover:bg-red-700"
                    >
                    View
                  </Link>

                </td>
                </tr>}
                 </>)})}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DownloadMisc;
