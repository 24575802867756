import React from 'react'

function NoOfTeacher() {
  return (
    <div>
           <div className="mx-auto py-5 px-4 w-full max-w-7xl border-b-2 border-gray-200">
      <h3 className="text-3xl text-black font-black">Number of Teachers Sanctioned and Present position.</h3>
      <p className="mt-3 max-w-4xl font-black text-sm text-black">(A) Post Sanctioned – Post Sanctioned by Bihar Govt. vide letter No. <span className='text-pink-500'> 15/G 1-08/98-210 dated 31-01-2000.</span></p>
    <div className="mt-2 relative overflow-x-auto">
  <table className="w-full border border-black  text-sm text-left text-gray-500 dark:text-gray-400">
    <thead className="text-xs border border-black text-gray-700 uppercase bg-gray-50 d dark:text-gray-400">
      <tr className=' border text-base text-black border-black'>
        <th scope="col" className="px-6 border border-black  py-3">
        Professor
        </th>
        <th scope="col" className="px-6  border border-black py-3">
        Reader (Associate Prof.)
        </th>
        <th scope="col" className="px-6 border border-black  py-3">
        Lecturer
        </th>
        </tr>
    </thead>
    <tbody>
      <tr className="bg-white border border-black   dark:border-gray-700">
        <td

          className="px-6 py-4 border border-black font-medium text-gray-900 whitespace-nowrap "
        >
          01
        </td>
        <td className="px-6 border border-black   font-medium text-gray-900 whitespace-nowrap  py-4">02	</td>
        <td className="px-6 border border-black  font-medium text-gray-900 whitespace-nowrap   py-4">04</td>

      </tr>

    </tbody>
  </table>
</div>
</div>
<div className="mx-auto py-5 px-4 w-full max-w-7xl border-b-2 border-gray-200">
      <p className="mt-3 max-w-4xl font-black text-sm text-black">(B) Present Position of Teachers- Promotion of all teachers are under CAS/MPS/TBP & are personal promotion.
</p>
    <div className="mt-2 relative overflow-x-auto">
  <table className="w-full border border-black  text-sm text-left text-gray-500 dark:text-gray-400">
    <thead className="text-xs border border-black text-gray-700 uppercase bg-gray-50 d dark:text-gray-400">
      <tr className=' border text-base text-black border-black'>
        <th scope="col" className="px-6 border border-black  py-3">
        Professor
        </th>
        <th scope="col" className="px-6  border border-black py-3">
        Associate Prof.
        </th>
        <th scope="col" className="px-6 border border-black  py-3">
        Assistant Prof.
        </th>
        </tr>
    </thead>
    <tbody>
      <tr className="bg-white border border-black   dark:border-gray-700">
        <td

          className="px-6 py-4 border border-black font-medium text-gray-900 whitespace-nowrap "
        >
          03
        </td>
        <td className="px-6 border border-black   font-medium text-gray-900 whitespace-nowrap  py-4">03	</td>
        <td className="px-6 border border-black  font-medium text-gray-900 whitespace-nowrap   py-4">00</td>

      </tr>

    </tbody>
  </table>
</div>
</div>
    </div>
  )
}

export default NoOfTeacher