import React from 'react'

function PersonalDetails(props) {
  return (
    <div>
      <div className='  ' style={{ width: '100%' }}>

        <h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Personal Details</h1>
        {props.data.personalDetails?.map((a, i) => {
          return (
            <>
              <div className='flex  items-center justify-between mt-7' style={{ width: '100%' }}>
                <div className='edu1'>D.O.B :- {a.dob}

                </div>
                <div className='edu2'>Gender:- {a.gender}</div>
              </div>
              <hr className='facultyHr' />
            </>
          )
        })}

      </div>
    </div>
  )
}

export default PersonalDetails