import axios from 'axios'
import React from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import Download from './Download'
import DownloadTable from './DownloadTable'

function DownloadPreview() {
  const [download, setDownload] = React.useState([])
  // console.log(data)
  const downloadData = () => {
    axios.get('/api/v1/find-download-file')
      .then(function (response) {
        setDownload(response.data)
      })
      .catch(function (error) {
        console.log(error);
      })
  }
  React.useEffect(() => {
    downloadData()
  }, [])
  return (
    <div>
        <div style={{ width: '100%',  }}>
    <div>
        <div style={{ width: '100%', height:'100vh', overflow:'scroll'}}>
            <Navbar />
        <div className='flex bg-[#F4F4F5]' style={{ width: '100%' }}>
          <Sidebar />
        <div className='' style={{ width: '100%' }}>
        <Download  onClick={downloadData}/>
        <DownloadTable download={download} onClick={downloadData}/>
        </div>
        </div>
        </div>
    </div>
    </div>

    </div>
  )
}

export default DownloadPreview