import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import Navbar from '../../components/Navbar'
import Footer from '../../Footer'
import Topbar from '../landing/Topbar'
import mscStudent from '../../assests/mscStudent.json'
import axios from 'axios'
import Spinner from '../facultydashboard/formGroup/Spinner'
function Student() {
  const [datas, setData] = React.useState({})
  const [loader,setLoader] = useState(true)
  // console.log(datas)
  const siteData = () => {
    axios.get('/api/v1/api/site/data')
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0])
        setLoader(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }
  React.useEffect(() => {
    siteData()
  }, [])
  const [stdata, setStdata] = useState([]);

  const student = () => {
    axios
      .get("/api/v1/get-all-student")
      .then(function (response) {
        // handle success
        //console.log(response.data);
        setStdata(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  useEffect(() => {
    student();
  }, [])

    // console.log(mscStudent)
  return (

    <div>
      {
        loader && <Spinner/>
      }
        <Topbar/>
        <Navbar datas={datas}/>
        <Breadcrumbs pagelink="#" sublink='#' page={"M.Sc Student"}/>
        <div className='  md:mx-16 my-4 md:grid '>
            <div className=''>
        <div class="overflow-x-auto rounded-lg border border-gray-200">
  <table class="min-w-full divide-y-2 divide-gray-200 text-sm">
    <thead>
      <tr>
        <th
          class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
        >
          Name
        </th>
        <th
          class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
        >
          Session

        </th>
        <th
          class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
        >
          Registration Year
        </th>
        <th
          class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
        >
          University Roll No.
        </th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200">
    {
        stdata?.map((e)=>{
            return(



                <>
                                        <tr className="bg-white border-b  dark:border-gray-700">

                                            <td className="px-6 py-4">{e.name}</td>
                                            <td className="px-6 py-4">{e.session}</td>
                                            <td className="px-6 py-4">{e.registrationYear}</td>
                                            <td className="px-6 py-4">{e.univRollno}</td>


                                        </tr>
                                    </>

)
})
}

</tbody>
  </table>
</div>
</div>
</div>
<Footer datas={datas}/>

    </div>
  )
}

export default Student