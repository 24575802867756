import axios from 'axios'
import React, { useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import Navbar from '../../components/Navbar'
import Footer from '../../Footer'
import Spinner from '../facultydashboard/formGroup/Spinner'
import Topbar from '../landing/Topbar'
import Hod from './Hod'
function About() {
  const [datas, setData] = React.useState({})
  const [loader,setLoader] = useState(false)
  // console.log(datas)
  const siteData = () => {
    axios.get('/api/v1/api/site/data')
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0])
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }
  React.useEffect(() => {
    siteData()
  }, [])
  return (
    <>
     {
        loader && <Spinner/>
      }
        <Topbar/>
    <Navbar datas={datas}/>
<Breadcrumbs page={"About Dept"} pagelink="#" sublink='#'/>
        <div className="py-16 bg-white ">
    <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
      <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">

        <div className="w-full">
          <h2 className="text-2xl text-red-600 font-bold md:text-4xl">
          </h2>
          <p className="mt-6 text-justify text-gray-600">
         {datas.aboutContentTitle}
          </p>

        </div>
      </div>
    </div>
    <Hod/>
  </div>
  <Footer datas={datas}/>
    </>
  )
}

export default About