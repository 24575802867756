import React, { useState, useEffect } from 'react';
// import Sidebar from './Sidebar'
//Bootstrap and jQuery libraries
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
// //Datatable Modules
// import EditUserModal from './EditUserModal';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
//For API Requests
import axios from 'axios';
import Breadcrumbs from '../../components/Breadcrumbs';
import Edit from './Edit';
// import Delete from './Delete';
// import PasswordChange from './PasswordChange';
const Table = () => {
    const [data, setState] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [showModal, setShowModal] = React.useState(false);
    const [deleteShowModal, setDeleteShowModal] = React.useState(false);
    const [passwordChangesModal, setPasswordChangesModal] = React.useState(false);
    const [editFaculty, setEditFaculty] = useState({})

    console.log("state", data.almuniFeedback)
    // State array variable to save and show data
    const fetchData = () => {
        axios.get('/api/v1/almuni-feedback-data').then(res => {
            //Storing users detail in state array object
            console.log("testing", res)
            setState({ data: res.data.almuniFeedback });
            console.log("dataAPI", res)
        });
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable(
                    {
                        pagingType: 'full_numbers',
                        pageLength: 5,
                        processing: true,
                        dom: 'Bfrtip',
                        buttons: ['copy', 'csv', 'print'
                        ]
                    }
                );
            },
                1000
            );
        });
    }
    useEffect(() => {
        fetchData()
    }, [])
console.log("new", data)


    const handleEditChange = (result) => {
        setShowModal(true)
        setEditFaculty(result)
    }

    // .
    //Datatable HTML
    return (
        <>
            <Breadcrumbs page={"Alumni Feedback"} pagelink="#" sublink='#' subpage={"feedback"} />
            <div className="flex flex-col" style={{ width: '100%' }}>
                {/* <h1 className='flex justify-center pt-4 text-black  text-2xl  mb-0'>User list</h1> */}
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className=" overflow-x-auto">
                            {/* <DataTable columns={columns} data={student} pagination
                                                fixedHeader
                                                fixedHeaderScrollHeight='400px'
                                                highlightOnHover
                                            // actions={<button className='bg-blue-900 text-white pl-4 pr-4' style={{}}>{actionsMemo}</button>}
                                            /> */}
                            <div className="MainDiv">
                                <div className="container p-5">
                                    {/* <div className='flex justify-end mr-8 '>
<button className='inline-block rounded border border-[#273272] px-12 py-2 text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500'>Add user</button>
           </div> */}
                                    <table id="example" class="table table-hover table-bordered ">
                                        <thead>
                                            <tr class="text-black bg-neutral-200">
                                                <th>Sr No.</th>
                                                <th>Name</th>
                                                <th>Gender</th>
                                                <th>Email</th>
                                                <th>Mobile No</th>
                                                <th>Course</th>
                                                <th>Registration No</th>
                                                <th>Passing Year</th>
                                                <th>View</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.data && data.data.map((result, index) => {
                                                return (
                                                    <tr class="text-black ">
                                                        <td>{index + 1}</td>
                                                        <td>{result.almuniName}</td>
                                                        <td>{result.gender}</td>
                                                        <td>{result.email}</td>
                                                        <td>{result.mobileNo}</td>
                                                        <td>{result.programName}</td>
                                                        <td>{result.regNo}</td>
                                                        <td>{result.YearOfPass}</td>

                                                        <td><button onClick={() => { handleEditChange(result) }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                            View
                                                        </button></td>

                                                    </tr>

                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {showModal === true && <>
                                        <Edit showModal={showModal} setShowModal={setShowModal} editFaculty={editFaculty} updateData={fetchData} />
                                    </>}


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );

}
export default Table;