import React from 'react'

function Textarea({placeholder,  label, row,  name,  value, onChange , disabled}) {
  return (
    <div>

<label for="message"  class="block mb-2 text-sm font-medium text-gray-900 ">{label} <span className='text-red-500 font-bold text-[1.2rem]'>*</span></label>
<textarea disabled={disabled} id="message"  rows={row} class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={placeholder} value={value} name={name}  onChange={onChange} />


    </div>
  )
}

export default Textarea