import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Crousal = (props) => {
  return (
    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} interval={3000} dynamicHeight={false}>
    <div>
        <img src={`/api/v1/media/${props.datas.slider1}`}/>

    </div>
    <div>
        <img src={`/api/v1/media/${props.datas.slider2}`} />

    </div>
    <div>
        <img src={`/api/v1/media/${props.datas.slider3}`} />

    </div>
</Carousel>

  );
};

export default Crousal;
