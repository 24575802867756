import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Input from "../../formGroup/Input";
import Spinner from "../../page/facultydashboard/formGroup/Spinner";
import { message } from "antd";
import axios from "axios";

function Download(props) {
  const [pdf, setPdf] = useState("");
  const [title, setTitle] = useState("");
  const [loader, setLoader] = useState(false);
  const [data, setData] = React.useState({});
  // console.log(title)
  const addDownload = (event) => {
    event.preventDefault();
    if (!pdf || !title) {
      message.error("All Field are required");
    } else {
      const formData = new FormData();
      formData.append("pdf", pdf);
      formData.append("title", title);
      setLoader(true);

      axios
        .post(`/api/v1/download/upload`, formData)
        .then(function (response) {
          setLoader(false);
          message.success(response.data.message);
          setPdf();
          setTitle();
          props.onClick();
        })
        .catch(function (error) {
          console.log(error);
          setLoader(false);
          message.error(error.response.data.errors);
        });
    }
  };
  return (
    <div>
      {loader && <Spinner />}
      <Breadcrumbs
        page={"Download"}
        pagelink="#"
        sublink="#"
        subpage={"Content Page "}
      />
      <div className="grid gap-2 rounded-md mx-4  px-8 py-4 bg-white grid-cols-1 w-2/4 my-auto">
        <Input
          label={"Title of file"}
          inputCss={" h-12"}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <Input
          type="file"
          label={"upload pdf (20mb max)"}
          inputCss={" h-12"}
          onChange={(e) => {
            setPdf(e.target.files[0]);
          }}
        />
        <div className="">
          <h1 className="opacity-0">k</h1>
          <button
            onClick={addDownload}
            className="mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
}

export default Download;
