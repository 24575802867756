import React from 'react'
import data from './Data';

function SeminarAttended() {
  return (
    <div>
        <div className="mx-auto py-5 px-4 w-full max-w-7xl border-b-2 border-gray-200">
        <h3 className="text-3xl text-black font-black">
        Faculty who have attended National & International Seminar (Last five Years)
        </h3>

        <div className="mt-2 relative overflow-x-auto">
          <table className="w-full border border-black  text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs border border-black text-gray-700 uppercase bg-gray-50  dark:text-gray-400">
              <tr className=" border text-base text-black border-black">
              <th scope="col" className="px-6 border border-black  py-3">
                  Sl. No.
                </th>
                <th scope="col" className="px-6  border border-black py-3">
                  Name of Teachers
                </th>
                <th scope="col" className="px-6 border border-black  py-3">

No. of Seminar Attended/ paper presented


                </th>
                <th scope="col" className="px-6 border border-black  py-3">
                Scheduled Attached (Page No.)
                </th>
                <th scope="col" className="px-6 border border-black  py-3">

                </th>
              </tr>
            </thead>
            <tbody>
            {data.map((a, i) => {
                return (
                  <>
                    <tr className="bg-white border border-black   dark:border-gray-700">
                      <td className="px-6 py-4 border border-black font-medium text-gray-900 whitespace-nowrap ">
                        {i+1}
                      </td>
                      <td className="px-6 border border-black   font-medium text-gray-900 whitespace-nowrap  py-4">
                       {a.NameofTeachers}
                      </td>
                      <td className="px-6 border border-black  font-medium text-gray-900 whitespace-nowrap   py-4">
                        {a.SeminarAttended}
                      </td>
                      <td className="px-6 border border-black  font-medium text-gray-900 whitespace-nowrap   py-4">
                        {a.ScheduledAttached ===""?null:a.ScheduledAttached-1}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default SeminarAttended