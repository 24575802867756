import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Navbar from "../../components/Navbar";
import Footer from "../../Footer";
import Topbar from "../landing/Topbar";
import atmosphere from "../../assests/ATMOSPHERIC.jpg";
import CONDENSE from "../../assests/CONDENSE.JPG";
import HIGH from "../../assests/HIGH.jpg";
import LASER from "../../assests/LASER.jpg";
import RENEWABLE from "../../assests/RENEWABLE.jpg";
import ELECTRONICS from "../../assests/ELECTRONICS.jpg";
import axios from "axios";
import Spinner from "../facultydashboard/formGroup/Spinner";

function Research() {
  const [data, setData] = useState([]);
  // console.log(data);
  const student = () => {
    axios
      .get("/api/v1/research/get-all-research-topic")
      .then(function (response) {
        // handle success
        //console.log(response.data);
        setData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  const [wi, setWi] = useState(true);
  const [datas, setDatas] = React.useState({});
  const [loader, setLoader] = useState(true);
  // console.log(datas)
  const siteData = () => {
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setDatas(response.data[0]);
        setLoader(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  React.useEffect(() => {
    siteData();
    student();
  }, []);

  return (
    <div>
      {loader && <Spinner />}
      <Topbar />
      <Navbar datas={datas} />
      <Breadcrumbs page={"Research"} pagelink="#" sublink="#" />
      <div className="grid grid-cols-1 justify-items-center m-4 gap-4 lg:grid-cols-1 lg:gap-8 ">
        {data &&
          data.map((a) => {
            return (
              <article className="rounded-xl border-2 w-10/12 border-gray-100 bg-white">
                <div className="flex items-start gap-4 pt-4 px-4 sm:px-6 lg:px-8">
                  <a href="#" className="block shrink-0">
                    <img
                      alt="Speaker"
                      src={a.photos}
                      className=" rounded-lg object-cover  "
                    />
                  </a>

                  <div className="w-1/3">
                    <h3 className="font-bold sm:text-xl">
                      <a href="#" className="hover:underline">
                        {a.areaOfResearch}
                      </a>
                    </h3>
                    <h3 className="font-medium  sm:-text-xl">
                      <p href="#" className="hover:underline">
                        by  {a.facultyName}{" "}
                        {a.otherFacultyName ? (
                          <>, {a.otherFacultyName}</>
                        ) : null}
                      </p>
                    </h3>
                  </div>
                </div>

                <div className="flex pb-4 px-4 sm:px-6 lg:px-8">
                  {a.about ? (
                    <>
                      <p className=" text-sm text-justify text-gray-700">{a.about}</p>
                    </>
                  ) : null}
                </div>
                {
                  a.link &&

                <div className="flex justify-end">
    <strong
      className="-mb-[2px] -me-[2px] inline-flex items-center gap-1 rounded-ee-xl rounded-ss-xl bg-green-600 px-3 py-1.5 text-white"
    >


      <a href={a.link} target="_blank" className="text-[10px] font-medium sm:text-xs">Know more</a>
    </strong>
  </div>}
              </article>

              //          <div class="overflow-hidden  w-10/12 rounded-lg  border shadow-md border-gray-100 ">

              //           <img className='w-full' src={a.photos}/>

              //   <div class="p-4   sm:p-6">
              //     <a href="#">
              //       <h3 class="text-lg w-full  font-bold text-gray-900">
              //         {a.areaOfResearch}.
              //       </h3>
              //     </a>

              //     <p class="mt-2 text-sm font-semibold leading-relaxed text-gray-500 line-clamp-3">
              //       {a.facultyName} {a.otherFacultyName ?<>, {a.otherFacultyName}</>:null}
              //     </p>

              //     {a.about ? <><p class="mt-2 text-sm leading-relaxed text-gray-500 ">
              //       {a.about}
              //     </p></>:null}
              //     {
              //       a.link ? <>
              //       <a
              //       href={a.link}
              //       target='_blank'
              //       class="group mt-4 inline-flex items-center gap-1 text-sm font-medium text-blue-600"
              //       >
              //       know more

              //       <span
              //         aria-hidden="true"
              //         class="block transition group-hover:translate-x-0.5"
              //         >
              //         &rarr;
              //       </span>
              //     </a>
              //       </>:null
              //     }

              //   </div>
              // </div>
            );
          })}
      </div>

      <Footer datas={datas} />
    </div>
  );
}

export default Research;
