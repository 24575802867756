import { message } from 'antd'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DltModal from '../formGroup/DltModal'

import { Input, Textarea } from '../formGroup/Index'
function Expertise(props) {
    const [edustate, setEduState] = useState(false)
    const [exptr, setExpert] = useState('')
    const [brief, setBrief] = useState('')
    const [editState, setEditState] = useState(false)
    const [state, setState] = useState("")
    const [tde, setTde] = useState([])
    const [hdn, setHdn] = useState("hidden")
    const navigate = useNavigate()
    const addmore = (e) => {
        setTde([...tde, { "subject": state.subject, "exptr": state.exptr, "brief": state.brief }]);
        setEduState(false)
        setState({ [e.target.name]: "" })
    }
    const removeBatch = (id) => {
        const updateList = tde.filter((elm, ind) => {
            return ind !== id;
        });
        setTde(updateList)
    }
    const editA = () => {
        setEditState(true)

    }
    const [k, setK] = useState("hidden")

    const addD = () => {
        setEduState(!edustate)
        setHdn(!hdn)
        if (hdn === false) {
            setK("block")

        }
        else {
            setK("hidden")
        }
    }
    const [dlt, setDlt] = useState(false)

    const [hide, setHide] = useState(false)
    const [expertExist, setExpertExist] = useState(false)
    const [id, setId] = useState('')
    const checkFillidOrNot = () => {
        if (props.data.expertise.length >= 1) {
            setHide(true)
            setEduState(true)
            setExpertExist(true)
            setExpert(props.data.expertise[0].specialization)
            setBrief(props.data.expertise[0].briefIntro)
            setId(props.data.expertise[0].id)
        }
    }


    const onSubmit = (e) => {
        e.preventDefault()
        props.loader(true)
        axios.post('/api/v1/expertise', {
            specialization: exptr,
            briefIntro: brief
        })
            .then(function (response) {
                props.loader(false)
                message.success(response.data.message)
                props.onClick()
                setHide(true)
                setEduState(true)
                setExpertExist(true)
            })
            .catch(function (error) {
                props.loader(false)
                message.error(error.response.data.errors)

            });
    }

    const editExpertise = () => {
        // /expertise/update/:id
        // alert(id)
        props.loader(true)
        axios.patch(`/api/v1/expertise/update/${id}`, {
            specialization: exptr,
            briefIntro: brief
        })
            .then(function (response) {
                props.loader(false)
                message.success(response.data.message)
                props.onClick()
                setHide(true)
                setEduState(true)
                setExpertExist(true)
            })
            .catch(function (error) {
                props.loader(false)
                message.error(error.response.data.errors)

            });
    }

    useEffect(() => {
        checkFillidOrNot()
    }, [])

    return (
        <>
            <div>
                <div className='  ' style={{ width: '100%' }}>
                    <div className='flex space-x-2  justify-between '>

                        <h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Expertise</h1>
                        <div>
                            <button className='mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none' onClick={() => { hide ? setHide(false) : setEduState(!edustate) }} >{expertExist ? "Edit" : "ADD"}</button>
                        </div>
                    </div>
                    {edustate ? <>
                        <div className='grid grid-cols-2 justify-center gap-2 '>
                            <div className='col-span-full'>

                                <Input disabled={hide} placeholder="Major Area/Specialization separated by ," label="Major Area/Specialization" name="exptr" type="text" value={exptr} onChange={(e) => { setExpert(e.target.value) }} />
                            </div>
                            <div className='col-span-full'>
                                <Textarea placeholder='Brief Info separated' disabled={hide} label="Brief Info" name="brief" value={brief} onChange={(e) => { setBrief(e.target.value) }} />
                            </div>
                            {/* <button className={` mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`} onClick={addD}>close</button> */}
                        </div>
                        {!hide && <center><button type='submit' className='mt-4 px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none' onClick={expertExist ? editExpertise : onSubmit} >{expertExist ? "Update" : "Submit"}</button></center>}



                    </> : null

                    }

                    {
                        tde.map((elm, ind) => {
                            return (
                                <>
                                    <div className='flex gap-2 items-center justify-between mt-7' style={{ width: '100%' }} key={ind}>
                                        <div className='edu1 '  >{elm.subject} {elm.exptr}
                                        </div>
                                        <div className='edu2'>{elm.brief} </div>
                                        <div className='flex space-x-4'>

                                            <button className='my-auto  px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-emerald-400 hover:text-black border-2 border-green-900 focus:outline-none' onClick={editA}>edit</button>
                                            <button className=' px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none' onClick={() => removeBatch(ind)}>delete</button>
                                        </div>
                                    </div>
                                    <hr className='facultyHr' />
                                </>
                            )
                        })
                    }



                </div>
            </div>
        </>
    )
}

export default Expertise