import React from "react";
export default function ViewPlaced(props) {
  console.log(props.editFaculty);

  console.log("props ", props);

  return (
    <>
      {props.showModal ? (
        <>
          <div className="justify-center  items-center flex  overflow-y-auto overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-10/12  my-6 mx-auto   ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start gap-2   justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="flow-root">
                  <dl className="m-3 divide-y grid grid-cols-3 gap-4 divide-gray-100 text-sm">
                    <div className="pl-12">
                      <div className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        Student Name{" "}
                        <dd className="text-gray-700 text-center sm:col-span-2">
                          {props.editFaculty.studentName}
                        </dd>
                      </div>

                      <div className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium text-gray-900">
                          Mobile Number
                        </dt>
                        <dd className="text-gray-700 text-center sm:col-span-2">
                          {props.editFaculty.mobileNo}
                        </dd>
                      </div>

                      <div className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium  text-gray-900">Program</dt>
                        <dd className="text-gray-700 text-center sm:col-span-2">
                          {props.editFaculty.program}
                        </dd>
                      </div>
                      <div className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium  text-gray-900">
                          Session/Admission Year
                        </dt>
                        <dd className="text-gray-700 text-center sm:col-span-2">
                          {props.editFaculty.session}
                        </dd>
                      </div>
                      <div className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium  text-gray-900">
                          Pass out Year*
                        </dt>
                        <dd className="text-gray-700 text-center sm:col-span-2">
                          {props.editFaculty.passOutYear}
                        </dd>
                      </div>
                    </div>
                    <div>
                      <div className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium text-gray-900">
                          Registration No
                        </dt>
                        <dd className="text-gray-700 sm:col-span-2">
                          {props.editFaculty.registrationNo}
                        </dd>
                      </div>
                      <div className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium text-gray-900">
                          University Roll No
                        </dt>
                        <dd className="text-gray-700  sm:col-span-2">
                          {props.editFaculty.universityRollNo}
                        </dd>
                      </div>
                      <div className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium text-gray-900">
                          Registration No
                        </dt>
                        <dd className="text-gray-700 sm:col-span-2">
                          {props.editFaculty.registrationNo}
                        </dd>
                      </div>
                      <div className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium   text-gray-900">
                          Currently Placed
                        </dt>
                        <dd className="text-gray-700 sm:col-span-2">
                          {props.editFaculty.currentlyPlaced}
                        </dd>
                      </div>

                      <div className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium text-gray-900">
                          Designation
                        </dt>
                        <dd className="text-gray-700 sm:col-span-2">
                          {props.editFaculty.designation}
                        </dd>
                      </div>
                      <div className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-4 sm:gap-4">
                        <dt className="font-medium sm:col-span-2 text-gray-900">
                          Salary per year
                        </dt>
                        <dd className="text-gray-700 sm:col-span-2">
                          {props.editFaculty.salaryPerYear}
                        </dd>
                      </div>
                    </div>
                  </dl>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end gap-x-4 p-6 border-t border-solid border-slate-200 rounded-b">
                  <div className="flex-1">
                    {/* <button
                    className="text-red-500  background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => props.setShowModal(false)}
                  >
                    Close
                  </button> */}
                  </div>
                  <div className="">
                    <button
                      className="bg-red-500 mx-4 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => props.setShowModal(false)}
                    >
                      Close
                    </button>
                    {/*
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => editUser()}
                    // onClick={() => props.setShowModal(false)}
                  >
                    Accept
                  </button>
                   */}
                    '
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
