import React from 'react'
import dalle from "./assests/dalle.png"
import {GrFormNextLink} from 'react-icons/gr'
import { Link } from 'react-router-dom'
function HeroSection(props) {
    // const imh = props.datas.aboutContentImg
    return (
        <div className='bg-[#f7f7f7]'>
            <div className='pt-4 pb-4 bg-[#f7f7f7]'>
            <div className='bg-[#f7f7f7] grid grid-cols-1  gap-4 sm:grid-cols-2 m-2 sm:m-[4.3rem] items-start'>
                <div className=' bg-white mx-auto sm:w-3/4  rounded-md '>
                    <img src={`/api/v1/media/${props.datas.aboutContentImg}`} />

                </div>
                <div className=' rounded-md bg-[#f7f7f7]'>

                    <h1 className='text-3xl mb-12 font-semibold bg-[#f7f7f7]'>{props.datas.siteName}</h1>
                    <span className='text-gray-500 text-justify line-clamp-5 bg-[#f7f7f7]'>
                    {props.datas.aboutContentTitle}
                    </span>
                    <br />
                    <Link to='/about'>
                    <button  className='hover:bg-[#d12326] bg-gray-300 text-black hover:text-white pt-2 pb-2 pl-4 pr-4 text-[0.8rem] rounded mt-4 mb-4 flex gap-2 items-center'>KNOW MORE <GrFormNextLink className='hover:text-white'/></button></Link>
                </div>

            </div>
            </div>

        </div>
    )
}

export default HeroSection