import React, { useState } from 'react'

function TeacherFeedBackQues({handleChange,value}) {

        const questions = {

            q1:"",
            q2:"",
            q3:"",
            q4:"",
            q5:"",
            q6:"",
            q7:"",
            q8:"",
            q9:"",
            q10:"",
        }
        const [question , setQuestion] = useState(questions)
        const [achievement , setAchievement] = useState('')
  return (
    <div>
        <div className=" grid grid-cols-1 justify-items-center w-1/2 gap-x-4 gap-y-2 ">

        <div>
          <label for="question">
          1. Aims, objectives and outcomes of the syllabus are well defined and clear to teachers.
पाठ्यक्रम के उद्देश्य और परिणाम से शिक्षक भली भांति परिचित   हैं।
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
             value={value.q1}
             onChange={
               handleChange('q1')
             }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          2. Syllabus is sufficient to bridge the gap between industry standards /current global scenarios and academia.
यह पाठ्यक्रम/कोर्स वैश्विक मानकों के अनुरूप उद्योग और अकादमिक जगत को जोड़ने में सहायक है/
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
                value={value.q2}
                onChange={
                  handleChange('q2')
                }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          3. Learning value (in terms of skills, concepts, knowledge, analytical abilities or broadening perspectives).
अधिगम/सीखने के मूल्य (कौशल, अवधारणाओं, ज्ञान, विश्लेषणात्मक क्षमताओं या व्यापक दृष्टिकोण के संदर्भ में) हैं।
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
               value={value.q3}
               onChange={
                 handleChange('q3')
               }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          4. The curriculum is applicable/relevant to real life situations.
पाठ्यक्रम वास्तविक जीवन स्थितियों के लिए प्रयोज्य/प्रासंगिक है।
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
                value={value.q4}
                onChange={
                  handleChange('q4')
                }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          5. Present syllabus ensures active engagement of students & other stake holders.
वर्तमान पाठ्यक्रम छात्रों/ विद्यार्थी और अन्य संबंधितों की सक्रिय भागीदारी/सहभागिता सुनिश्चित करता है।
*
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
                value={value.q5}
                onChange={
                  handleChange('q5')
                }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          6. Syllabus has flexibility to adopt new techniques/strategies of teaching such as presentations, group discussions and learners’ participations.
पाठ्यक्रम में शिक्षण की नई तकनीकों/रणनीतियों जैसे प्रस्तुतिकरण, समूह चर्चा आदि में शिक्षार्थियों को शामिल करने के पर्याप्त अवसर है/
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
            value={value.q6}
            onChange={
              handleChange('q6')
            }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          7. Timely coverage of syllabus is possible in the mentioned number of hours.
शिक्षण के निर्धारित घंटों के अनुसार पाठ्यक्रम को  ससमय में पूरा किया जा सकता है।
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
             value={value.q7}
             onChange={
               handleChange('q7')
             }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          8. The evaluation methods mentioned in the syllabus are sufficient for providing proper assessment.
 पाठ्यक्रम में वर्णित पद्धतियाँ समुचित मूल्यांकन के लिए पर्याप्त हैं/
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
             value={value.q8}
             onChange={
               handleChange('q8')
             }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          9. The books prescribed/listed as reference materials are relevant, updated and appropriate.
संदर्भ सामग्री के रूप में निर्धारित/सूचीबद्ध पुस्तकें प्रासंगिक, अद्यतन और उपयुक्त हैं।
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
             value={value.q9}
             onChange={
               handleChange('q9')
             }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
          10. Overall Rating for curriculum
पाठ्यक्रम के लिए समग्र रेटिंग
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
             value={value.q10}
             onChange={
               handleChange('q10')
             }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
      </div>
    </div>
  )
}

export default TeacherFeedBackQues