import React from 'react'

function Expertise(props) {
  return (
    <div>
        <div className='  ' style={{ width: '100%' }}>

<h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Expertise</h1>
{
  props.data.expertise?.map((a,i)=>{
    return (
      <>
      <div className='flex  items-center justify-between mt-7' style={{ width: '100%' }}>
    <div className='edu1'>{a.specialization}

    </div>
    <div className='edu2'></div>
</div>
<hr className='facultyHr' />
      </>
    )
  })
}

</div>
    </div>
  )
}

export default Expertise