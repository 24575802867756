import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assests/NewLogo.png";
import axios from "axios";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { message } from "antd";
import Spinner from "../facultydashboard/formGroup/Spinner";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [loader, setLoader] = useState(false);
  let { id } = useParams();
  const [showNewPasswordvisible, setShowNewPasswordvisible] = useState(false);
  const [showCnfPasswordvisible, setShowCnfPasswordvisible] = useState(false);
  console.log(id);
  const router = useNavigate();
  const updatePassword = () => {
    setLoader(true);
    if (newPassword !== "" && newPassword === cnfPassword) {
      axios
        .post(`/api/v1/reset-password/${id}`, { newPassword })
        .then((response) => {
          message.success(response.data.message);
          setLoader(false);
          router("/login");
        })
        .catch((error) => {
          console.log(error.message);
          setLoader(false);
        });
    } else {
      message.error("New password and Confirm password should be same");
      setLoader(false);
    }
  };
  console.log(newPassword);
  return (
    <div>
      {loader && <Spinner />}
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
            <img className="w-20 h-20 mr-2" src={logo} alt="logo" />
          </div>
          <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Change Password
            </h2>
            <div className="mt-4 space-y-4 lg:mt-5 md:space-y-5">
              <div className="flex flex-1 flex-col">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  New Password
                </label>
                <input
                  type={showNewPasswordvisible ? "text" : "password"}
                  name="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
                <button
                  onClick={() =>
                    setShowNewPasswordvisible(!showNewPasswordvisible)
                  }
                  className="relative text-xl "
                >
                  <span className="absolute right-2 bottom-3">
                    {showNewPasswordvisible ? (
                      <MdOutlineVisibility />
                    ) : (
                      <MdOutlineVisibilityOff />
                    )}
                  </span>
                </button>
              </div>
              <div className="flex flex-1 flex-col ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Confirm password
                </label>
                <input
                  type={showCnfPasswordvisible ? "text" : "password"}
                  name="confirm-password"
                  placeholder="••••••••"
                  value={cnfPassword}
                  onChange={(e) => setCnfPassword(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                />
                <button
                  onClick={() =>
                    setShowCnfPasswordvisible(!showCnfPasswordvisible)
                  }
                  className="relative text-xl "
                >
                  <span className="absolute right-2 bottom-3">
                    {showCnfPasswordvisible ? (
                      <MdOutlineVisibility />
                    ) : (
                      <MdOutlineVisibilityOff />
                    )}
                  </span>
                </button>
              </div>
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="newsletter"
                    aria-describedby="newsletter"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                    required
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="newsletter"
                    className="font-light text-gray-500 dark:text-gray-300"
                  >
                    I accept the{" "}
                    <a
                      className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                      href="#"
                    >
                      Terms and Conditions
                    </a>
                  </label>
                </div>
              </div>
              <button
                type="submit"
                onClick={updatePassword}
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Reset passwod
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ResetPassword;
