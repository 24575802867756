import { message } from 'antd'
import axios from 'axios'
import React,{useEffect, useState} from 'react'
import {Input} from '../formGroup/Index'
function Publication(props) {

    const [edustate, setEduState]=useState(false)
    const initialState={
        workType:"",
        year:"",
        title:"",
        journal:"",
        volume:"",
        page:"",
        author:"",
    }
    const [workType, setWorkType]=useState("")
    const [year , setYear]=useState("")
    const [title, setTitle]=useState("")
    const [journal, setJournal]=useState("")
    const [volume, setVolume]=useState("")
    const [page, setPage]=useState("")
    const [author, setAuthor]=useState("")

    const [editState,setEditState]=useState(false)
    const [state, setState] = useState()
    const [tde, setTde]=useState([])
    const onChange = (e) => {

        setState({ ...state, [e.target.name]: e.target.value })
    }
    const addmore = (e)=>{
        setTde([...tde, {  "workType": workType, "year": year, "journal":journal, "volume":volume,"page":page , "author":author }]);
        setEduState(false)
        setState({  [e.target.name]:"" })
    }
    const removeBatch = (id) => {
        const updateList = tde.filter((elm, ind) => {
            return ind !== id;
        });
        setTde(updateList)
    }
    const [id, setId] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const editA=(workType,journal,title,volume,page,author,year,id)=>{
        setWorkType(workType)
        setYear(year)
        setTitle(title)
        setJournal(journal)
        setVolume(volume)
        setPage(page)
        setAuthor(author)
        setId(id)
        setIsEdit(true)
        setEduState(!edustate)


    }
    const [hdn, setHdn]=useState(false)

    const [k, setK]=useState("hidden")
    const addD=()=>{
        setEduState(!edustate)
        setWorkType("")
        setYear("")
        setTitle("")
        setJournal("")
        setVolume("")
        setPage("")
        setAuthor("")
        setId("")
        setIsEdit(false)
        setHdn(!hdn)
        if(hdn===false){
            setK("block")

        }
        else{
            setK("hidden")
        }
    }
    const cancel = () => {
        setWorkType("")
        setYear("")
        setTitle("")
        setJournal("")
        setVolume("")
        setPage("")
        setAuthor("")
        isEdit(false)
    }
    const onSubmit = (e) => {
		e.preventDefault()
		axios.post('/api/v1/publication', {
			workType: workType,
			title: title,
            journal:journal,
            volume:volume,
            page:page,
            author:author,
            year: year
		})
			.then(function (response) {
				// console.log(response);
				// alert(response.data.message);
				// console.log({role:response.data.role});
				// setTde([...tde, {  "subject": subject, "exptr": exptr, "brief":brief }]);
        setEduState(false)
        setState({  [e.target.name]:"" })
        // console.log(response.data)
				message.success(response.data.message)
                props.onClick()
				// alert('response.data.message')
				// setState({ ...intialValue })
			})
			.catch(function (error) {
				console.log(error)
				console.log(error.response.data.errors);
				message.error(error.response.data.errors)

			});
	}
    const checkFillidOrNot = () => {
        if (props.data.expertise.length >= 1) {
            // setHide(true)
            // setEduState(true)
            // setExpertExist(true)
            // setExpert(props.data.expertise[0].specialization)
            // setBrief(props.data.expertise[0].briefIntro)
            // setId(props.data.expertise[0].id)
        }
    }

    // remove
    // /profDetails/:id
    const remove = (id) => {
        // alert(id)
        props.loader(true)
        axios.delete(`/api/v1/publication/${id}`)
            .then(function (response) {
                props.loader(false)
                message.success(response.data.message)
                props.onClick()
            })
            .catch(function (error) {
                console.log(error)
                console.log(error.response.data.errors);
                props.loader(false)
                message.error(error.response.data.errors)

            });
    }

    const update = () => {
        // alert(id)
        props.loader(true)
        axios.patch(`/api/v1/publication/update/${id}`, {
            workType: workType,
			title: title,
            journal:journal,
            volume:volume,
            page:page,
            author:author,
            year: year
        })
            .then(function (response) {
                // console.log(response.data)
                props.loader(false)
                setWorkType("")
        setYear("")
        setTitle("")
        setJournal("")
        setVolume("")
        setPage("")
        setAuthor("")
        setEduState(false);
                message.success(response.data.message)
                props.onClick()
                setIsEdit(false)
            })
            .catch(function (error) {
                props.loader(false)
                console.log(error)
                console.log(error.response.data.errors);

                message.error(error.response.data.errors)

            });
    }

    useEffect(() => {
        checkFillidOrNot()
    })

  return (
    <>
    <div className=' '>
       <div className='   ' style={{ width: '100%' }}>
                            <div className='flex justify-between '>

                            <h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Publications</h1>
                            <div className='flex gap-2'>

<button className='mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none'onClick={addD} >Add</button>

</div>
</div>

                            {edustate ? <>
                            <div className='grid grid-cols-2  justify-between gap-2'>
                            <div className='w-full'>

                            <Input placeholder="Type of work"  label="Work Type" name="workType" type="text" value={workType} onChange={(e)=>{setWorkType(e.target.value)}} />
                            </div>
                            <Input placeholder="Title/Chapter"  label="Title/Chapter" name="title" type="text" value={title} onChange={(e)=>{setTitle(e.target.value)}} />
                            <Input placeholder="Journal/Book Title"  label=" Journal/Book Title" name="journal" type="text" value={journal} onChange={(e)=>{setJournal(e.target.value)}} />
                            <div className=''>

                            <Input placeholder="Year of publication"  label="Year" name="year" type="number" value={year} onChange={(e)=>{setYear(e.target.value)}} />
                            </div>
                            <Input placeholder="Volume no."  label="Volume" name="volume" type="text" value={volume} onChange={(e)=>{setVolume(e.target.value)}} />
                            <Input placeholder="Page no."  label="Page" name="page" type="text" value={page} onChange={(e)=>{setPage(e.target.value)}} />

                            <div className='col-span-full'>

                            <Input placeholder="Author name"  label="Author" name="author" type="text" value={author} onChange={(e)=>{setAuthor(e.target.value)}} />
                            </div>
                            <div></div>
                            <h1 className="opacity-0"> k</h1>
                  <button
                    type="submit"
                    className="mt-auto px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none"
                    onClick={isEdit ? update : onSubmit}
                  >
                    {isEdit ? "Update" : "Submit"}
                  </button>
                  <button
                    className={`${k}   px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`}
                    onClick={isEdit ? cancel : addD}
                  >
                    {isEdit ? "Cancel" : "Close"}{" "}
                  </button>
                            </div>



                            </>:null

                            }

                                {
                                    props.data.publication.map((elm,ind)=>{
                                        return(
                                            <>
                                            <article className="rounded-xl bg-white p-4 ring ring-indigo-50 sm:p-6 lg:p-8" key={ind}>
  <div className="flex items-start sm:gap-8">


    <div>


      <h3 className="mt-4 text-lg font-medium sm:text-xl">
        <a href="" className="hover:underline">{elm.title} </a>
      </h3>

      <p className="mt-1 text-sm text-gray-700">

      </p>

      <div className="mt-4 sm:flex text-end sm:items-center sm:gap-2">
        <div className="flex items-center gap-1 text-gray-500">


          <p className="text-xs font-medium"></p>
        </div>

        <span className="hidden sm:block" aria-hidden="true">&middot;</span>

        <p className="mt-2 text-xs font-medium text-gray-500 sm:mt-0">
           <a href="#" className="underline hover:text-gray-700">{elm.author}</a>,
          <a href="#" className="underline hover:text-gray-700">{elm.workType} </a>
          <a href="#" className="underline hover:text-gray-700">{elm.year}</a>
        </p>
      </div>
    </div>
  </div>
  <div className='flex justify-end space-x-4'>

                                        <button className='my-auto  px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-emerald-400 hover:text-black border-2 border-green-900 focus:outline-none' onClick={()=>editA(elm.workType,elm.journal,elm.title,elm.volume,elm.page,elm.author,elm.year,elm.id)}>edit</button>
                                        <button className=' px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none'  onClick={() => remove(elm.id)}>delete</button>
                                        </div>
</article>
                                            {/* <div className='flex gap-2 items-center justify-between mt-7' style={{ width: '100%' }} key={ind}>
                                            <div className='edu1'>{elm.workType}  {elm.journal} {elm.title} {elm.volume} {elm.page}
                                        </div>
                                        <div className='edu2'>{elm.author} {elm.year}</div>
                                        <div className='flex space-x-4'>

                                        <button className='my-auto  px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-emerald-400 hover:text-black border-2 border-green-900 focus:outline-none' onClick={()=>editA(elm.workType,elm.journal,elm.title,elm.volume,elm.page,elm.author,elm.year,elm.id)}>edit</button>
                                        <button className=' px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none'  onClick={() => remove(elm.id)}>delete</button>
                                        </div>
                            </div> */}
                            <hr className='facultyHr' />
                                        </>
                                        )
                                    })
                                }



                        </div>
    </div>

    </>
  )
}

export default Publication
