import React from 'react'

function ProfessionalBackground(props) {
  return (
    <div>
        <div className='  ' style={{ width: '100%' }}>

<h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Professional Background </h1>
{props.data.profBackground?.map((r,t)=>{
  return(
<>
<div className='flex  items-center justify-between mt-7' style={{ width: '100%' }} >
    <div className='edu1'>{r.designation} at {r.Institute}

    </div>
    <div className='edu2'>{r.startingYear} to {r.endingYear}</div>
</div>
<hr className='facultyHr' />





</>

  )
})}

</div>
    </div>
  )
}

export default ProfessionalBackground