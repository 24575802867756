import React, { useState } from "react";
import { Alert, Dropdown, Input } from '../../formGroup/Index'
import axios from 'axios'
export default function Delete(props) {
    console.log(props.editFaculty)
    const [name, setName] = useState(props.editFaculty.name)
    const [email, setEmail] = useState(props.editFaculty.email)
    const [mobileNo, setMobileNo] = useState(props.editFaculty.mobileNo)
    const [profileLink, setprofileLink] = useState(props.editFaculty.profileLink)
    const [orcidId, setOrcidId] = useState(props.editFaculty.orcidId)
    const [scopusId, setScopusId] = useState(props.editFaculty.scopusId)

    const deleteUser = () => {
        axios.delete(`/api/v1/users/${props.editFaculty.id}`)
            .then(function (response) {
                // console.log(response);
                alert(response.data.message)
                props.setDeleteShowModal(false)
                props.updateData()

            })
            .catch(function (error) {
                console.log(error);
                alert(error.response.data.errors)
            });
    }

    return (
        <>
            {props.deleteShowModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        {props.editFaculty.name}
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => props.setDeleteShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <img width={400} src="https://ucarecdn.com/caca3cbe-9862-411c-96f3-6bf9a8e341dc/Screenshot20230628000912.png" alt="" />
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => props.setDeleteShowModal(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => deleteUser()}
                                    // onClick={() => props.setShowModal(false)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}