import React from 'react'

function Input({ placeholder,size, label, name, man , type, value, onChange, defaultValue,disabled  }) {
    return (
        <div className="">
            <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 "
            >
                {label}<span className='font-bold text-[1.2rem] text-red-500'>{man}</span>
            </label>
            <input
                type={type}
                id="email"
                disabled={disabled}
                name={name}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder={placeholder}
                // required='true'
            />
        </div>
    )
}

export default Input