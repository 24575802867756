import axios from 'axios'
import React, { useEffect, useState } from 'react'

function Visit(props) {
  const [data,setData] = useState([])
  // console.log(data)
  const miscData = () => {
    props.loader(true)
    axios.get('/api/v1/miscellaneous/visit')
        .then(function (response) {
            // handle success
            setData(response.data.data)
            props.loader(false)
            // console.log(response.data.data);
            // console.log(response)
        })
        .catch(function (error) {
            // handle error
            props.loader(false)
            console.log(error);
        })
}


// console.log(data)
useEffect(() => {
    miscData()
}, [])
  return (
    <div>
      <div className="  " style={{ width: "100%" }}>
        <h1 className=" text-[1.7rem] mt-[2rem]" id="edu">
        Visit
        </h1>
        {data?.map((elm, ind) => {
          // console.log(elm);
          return (
            <>
              {props.data._id === elm.authorId && (
                <>
                  <div
                    className="flex gap-2 items-center justify-between mt-7"
                    style={{ width: "100%" }}
                    key={ind}
                  >
                    <div className="edu1">

                    {elm.visit}
                    </div>
                    <div className="edu2">
                      {elm.authorName}
                    </div>
                    <div className="flex space-x-4">
                    </div>
                  </div>
                  <hr className="facultyHr" />
                </>
              )}
            </>
          );
        })}
      </div>
    </div>
  )
}

export default Visit