import React, { useState, useRef, useEffect } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import Logo from "../assests/NewLogo.png";
import { Link, useNavigate } from "react-router-dom";
function Navbar(props) {
  // console.log(props);
  const [nav, setNav] = useState(false);
  const fNav = () => {
    setNav(!nav);
    // console.log("true");
  };
  const [subMenu, setSubMenu] = useState(false);
  const onClick = {};
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  const handleMenuClick = (event) => {
    event.preventDefault();
    setMenuOpen(!isMenuOpen);
  };


  return (
    <>
      <div className="flex space-x-1 justify-items-center items-center   h-fit my-auto sm:px-8 bg-gray-50 font-extrabold text-black  ">
        <Link to="https://bnmu.ac.in/" target="_blank" className="sm:m-2 px-2 w-16 sm:w-20 sm:px-0 items-center py-4 cursor-pointer ">
          <img
            className=" "
            src={`/api/v1/media/${props.datas.siteLogo}`}



          />{" "}

        </Link>

        <span className=" sm:mt-2  text-black grid text-sm md:text-3xl"
        > {props.datas.siteName} <br />  <span className="text-[0.60rem] md:text-sm uppercase ">      Bhupendra Narayan Mandal University
          </span></span>
      </div>
      {/* <div className="flex mb-2 space-x-2 justify-center items-center   h-fit my-auto sm:px-8 bg-gray-50 font-extrabold text-black  ">
        <Link
          to="https://bnmu.ac.in/"
          target="_blank"
          className="mt-2 px-2 w-16 sm:px-0 items-center  cursor-pointer "
        >
          <img className=" " src={`/api/v1/media/${props.datas.siteLogo}`} />{" "}
        </Link>
        <div>
          <p className=" underline uppercase text-black  text-sm md:text-2xl">

            {props.datas.siteName}
          </p>
          <p className="text-xs pt-1 text-center md:text-[1.4rem] uppercase">
            {" "}
            Bhupendra Narayan Mandal University
          </p>
        </div>
      </div>{" "}
        */}
      <div
        class="hidden font-light md:grid justify-center bg-[#9C060C] "
        id="main_menu"
      >
        <ul class="flex  p-2 space-x-8   sm:  text-sm md:text-lg" id="menu_nav">
          <li
            class="relative mx-1 px-2 py-2 group    mb-1 md:mb-0"
            id="button_home"
          >
            <Link
              class="font-semibold whitespace-no-wrap text-white hover:text-[#1896FE]"
              to="/"
            >
              <span class="last firstlevel">Home</span>
            </Link>
          </li>

          <li
            class="relative mx-1 px-1 py-2 group    mb-1 md:mb-0"
            id="button_moderate"
          >
            <Link
              class="font-semibold whitespace-no-wrap text-white hover:text-[#1896FE]"
              to="/about"
            >
              <span class="firstlevel">About Dept</span>
            </Link>
          </li>
          {/* <li
            class="relative mx-1 px-1 py-2 group    mb-1 md:mb-0"
            id="button_moderate"
          >
            <Link
              class="font-semibold whitespace-no-wrap text-white hover:text-[#1896FE]"
              to="/faculty"
            >
              <span class="firstlevel">Faculty</span>
            </Link>
          </li> */}

          <li
            class="relative mx-1 px-1 py-2 group   mb-1 md:mb-0"
            id="button_profile"
          >
            <Link
              class="font-semibold whitespace-no-wrap text-white hover:text-[#1896FE]"
              to=" #"
            >
              <span class="firstlevel">People</span>
            </Link>
            <ul class="absolute left-0 w-40 top-0 mt-10 p-2 rounded-lg shadow-lg  bg-white z-10 hidden group-hover:block">
              <svg
                class="block fill-current text-white w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
              </svg>
              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                <Link class="px-2 py-1" to="/faculty">
                  <span class="">Faculty</span>
                </Link>
              </li>
              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                <Link class="px-2 py-1" to="/staff">
                  <span class="">Staff</span>
                </Link>
              </li>
              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                <Link class="px-2 py-1" to="/research-scholar">
                  <span class="">Research Scholar</span>
                </Link>
              </li>
              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                <Link class="px-2 py-1" to="/student">
                  <span class="">Students</span>
                </Link>
              </li>
            </ul>
          </li>
          <li
            class="relative mx-1 px-1 py-2 group  mb-1 md:mb-0"
            id="button_pm"
          >
            <Link
              class="font-semibold whitespace-no-wrap text-white hover:text-[#1896FE]"
              to=" #"
            >
              <span class="firstlevel">Courses & Syllabi</span>
            </Link>
            <ul class="absolute w-max left-0 top-0 mt-10 p-2 rounded-lg shadow-lg bg-white z-10 hidden group-hover:block">
              <svg
                class="block fill-current text-white w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
              </svg>
              <li class="p-1 whitespace-no-wrap  text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                <Link class="px-2 py-1" to="/msc-syllabus">
                  <span class=""> M.Sc.</span>
                </Link>
              </li>

              <li class="p-1 whitespace-no-wrap  text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                <Link class="px-2 py-1" to="/phd-syllabus">
                  <span class="">Ph.D.(Course Work)</span>
                </Link>
              </li>
            </ul>
          </li>
          <li
            class="relative mx-1 px-1 py-2 group    mb-1 md:mb-0"
            id="button_like"
          >
            <Link
              class="font-semibold whitespace-no-wrap text-white hover:text-[#1896FE]"
              to="/research"
            >
              <span class="firstlevel">Research</span>
            </Link>
          </li>
          <li
            class="relative mx-1 px-1 py-2 group    mb-1 md:mb-0"
            id="button_like"
          >
            <Link
              class="font-semibold whitespace-no-wrap text-white hover:text-[#1896FE]"
              to="/notice"
            >
              <span class="firstlevel">Notice</span>
            </Link>
          </li>
          <li
            class="relative mx-1 px-1 py-2 group    mb-1 md:mb-0"
            id="button_like"
          >
            <Link
              class="font-semibold whitespace-no-wrap text-white hover:text-[#1896FE]"
              to="/alumni"
            >
              <span class="firstlevel">Alumni</span>
            </Link>
          </li>

          <li>
            <details class="group [&_summary::-webkit-details-marker]:hidden">
              <summary
                class="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2"
              >
                <span class="font-semibold whitespace-no-wrap text-white hover:text-[#1896FE]">Activities</span>



              </summary>

              <ul class="absolute w-max z-10 p-2 rounded-lg shadow-lg bg-white ">


                <details class="group [&_summary::-webkit-details-marker]:hidden">
                  <summary
                    class="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2"
                  >
                    <span class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"> Academic Activities </span>





                  </summary>
                  <li>
                    <a
                      href=""
                      class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"
                    >
                      Seminar
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"
                    >
                      Conference
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"
                    >
                     Symposiom
                    </a>
                  </li>
                </details>
                <details class="group [&_summary::-webkit-details-marker]:hidden">
                  <summary
                    class="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2"
                  >
                    <span class="block rounded-lg px-4  py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"> Co-curriculum Activities </span>

                  </summary>
                  <li>
                    <a
                      href=""
                      class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"
                    >
                     NSS
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"
                    >
                     Sports
                    </a>
                  </li>
                </details>
              </ul>
            </details>
          </li>
          <li
            class="relative mx-1 px-1 py-2 group    mb-1 md:mb-0"
            id="button_mlist"
          >
            <Link
              class="font-semibold whitespace-no-wrap text-white hover:text-[#1896FE]"
              to=" #"
            >
              <span class="firstlevel">Feedbacks</span>
            </Link>
            <ul class="absolute left-0 top-0 bg-white w-40 mt-10 p-2 rounded-lg shadow-lg z-10 hidden group-hover:block">
              <svg
                class="block fill-current text-white w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
              </svg>


              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                <Link class="px-2 py-1" target="_blank" to="/student-feedback">
                  <span class="">Student Feedback</span>
                </Link>
              </li>
              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                <Link class="px-2 py-1" target="_blank" to="/alumni-feedback">
                  <span class="">Alumni Feedback</span>
                </Link>
              </li>
              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-900">
                <Link class="px-2 py-1"  to="/teacher-feedback" target="_blank">
                  <span class="">Teacher Feedback</span>
                </Link>
              </li>
            </ul>
          </li>
          <li
            class="relative mx-1 px-1 py-2 group    mb-1 md:mb-0"
            id="button_mlist"
          >
            <Link
              class="font-semibold whitespace-no-wrap text-white hover:text-[#1896FE]"
              to=" #"
            >
              <span class="firstlevel">More</span>
            </Link>
            <ul class="absolute left-0 top-0 bg-white w-40 mt-10 p-2 rounded-lg shadow-lg z-10 hidden group-hover:block">
              <svg
                class="block fill-current text-white w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
              </svg>

              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                <Link class="px-2 py-1" to="">
                  <span class="">Reach us</span>
                </Link>
              </li>
              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                <Link class="px-2 py-1" to="/naacssr">
                  <span class="">SSR</span>
                </Link>
              </li>
              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                <Link class="px-2 py-1" to="#">
                  <span class="">Statistics</span>
                </Link>
              </li>
              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-900">
                <Link class="px-2 py-1" to="/download">
                  <span class="">Download</span>
                </Link>
              </li>

            </ul>
          </li>

        </ul>
      </div>
      {/* mobile menu */}
      <>
        <div className="z-10 flex px-2  bg-gray-50 md:hidden py-1 ">
          {showSidebar ? (
            <button
              className="flex text-4xl text-black items-center cursor-pointer fixed right-10 top-6 z-50"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              x
            </button>
          ) : (
            <svg
              onClick={() => setShowSidebar(!showSidebar)}
              className="  z-30 flex justify-start items-center cursor-pointer right-10 top-20"
              fill="#000000"
              viewBox="0 0 100 80"
              width="40"
              height="40"
            >
              <rect width="100" height="10"></rect>
              <rect y="30" width="100" height="10"></rect>
              <rect y="60" width="100" height="10"></rect>
            </svg>
          )}
        </div>
        <div
          className={`top-0  right-0 w-4/5 bg-white  p-10 pr-20 text-white fixed h-full z-40  ease-in-out duration-300 ${showSidebar ? "translate-x-0 " : "translate-x-full"
            }`}
        >
          <div class="grid mt-8 " id="main_menu">
            <ul
              class="grid p-1 md:p-2  sm:  text-sm md:text-base"
              id="menu_nav"
            >
              <li
                class="relative mx-1 px-1 py-2 group    mb-1 md:mb-0"
                id="button_home"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-red-600 hover:text-red-600"
                  to="/"
                >
                  <span class="last firstlevel">Home</span>
                </Link>
              </li>

              <li
                class="relative mx-1 px-1 py-2 group    mb-1 md:mb-0"
                id="button_moderate"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to="/about"
                >
                  <span class="firstlevel">About Us</span>
                </Link>
              </li>
              <li
                class="relative mx-1 px-1 py-2 group mb-1 md:mb-0"
                id="button_moderate"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to="/faculty"
                >
                  <span class="firstlevel">Faculty</span>
                </Link>
              </li>
              <li
                class="relative mx-1 px-1 py-2 group   mb-1 md:mb-0"
                id="button_profile"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to=""
                >
                  <span class="firstlevel">People</span>
                </Link>
                <ul class="=  w-max mx-auto mt-2 p-2 rounded-lg shadow-lg bg-white z-10 hidden group-hover:block">
                  <svg
                    class="block fill-current text-white w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                  </svg>

                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="">
                      <span class="">Research Scholar</span>
                    </Link>
                  </li>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="/student">
                      <span class="">Students</span>
                    </Link>
                  </li>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="/staff">
                      <span class="">Staff</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                class="relative mx-1 px-1 py-2 group bg-white   mb-1 md:mb-0"
                id="button_pm"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to=" #"
                >
                  <span class="firstlevel">Courses & Syllabi</span>
                </Link>
                <ul class="w-max mx-auto mt-2 p-2 rounded-lg shadow-lg bg-white z-10 hidden group-hover:block">
                  <svg
                    class="block fill-current text-white w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                  </svg>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="/msc-syllabus">
                      <span class=""> M.Sc.</span>
                    </Link>
                  </li>

                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="/phd-syllabus">
                      <span class="">Ph.D.(course work)</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                class="relative mx-1 px-1 py-2 group bg-white   mb-1 md:mb-0"
                id="button_like"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to="/research"
                >
                  <span class="firstlevel">Research</span>
                </Link>
              </li>
              <li
                class="relative mx-1 px-1 py-2 group bg-white   mb-1 md:mb-0"
                id="button_like"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to="/notice"
                >
                  <span class="firstlevel">Notice</span>
                </Link>
              </li>
              <li>
            <details class="group [&_summary::-webkit-details-marker]:hidden">
              <summary
                class="flex cursor-pointer items-center justify-between rounded-lg px-2 py-1"
              >
                <span class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600">Activities</span>



              </summary>

              <ul class="absolute w-max z-10 p-2 rounded-lg shadow-lg bg-white ">


                <details class="group [&_summary::-webkit-details-marker]:hidden">
                  <summary
                    class="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2"
                  >
                    <span class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"> Academic Activities </span>





                  </summary>
                  <li>
                    <a
                      href=""
                      class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"
                    >
                      Seminar
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"
                    >
                      Conference
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"
                    >
                     Symposiom
                    </a>
                  </li>
                </details>
                <details class="group [&_summary::-webkit-details-marker]:hidden">
                  <summary
                    class="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2"
                  >
                    <span class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"> Co-curriculum Activities </span>





                  </summary>
                  <li>
                    <a
                      href=""
                      class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"
                    >
                     NSS
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-red-600"
                    >
                     Sports
                    </a>
                  </li>
                </details>

              </ul>
            </details>
          </li>
          <li
            class="relative mx-1 px-1 py-2 group    mb-1 md:mb-0"
            id="button_mlist"
          >
            <Link
              class="font-semibold whitespace-no-wrap text-gray-600 hover:text-[#1896FE]"
              to=" #"
            >
              <span class="firstlevel">Feedbacks</span>
            </Link>
            <ul class="absolute left-0 top-0 bg-white w-40 mt-10 p-2 rounded-lg shadow-lg z-10 hidden group-hover:block">
              <svg
                class="block fill-current text-white w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
              </svg>


              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                <Link class="px-2 py-1" target="_blank" to="/student-feedback">
                  <span class="">Student Feedback</span>
                </Link>
              </li>
              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                <Link class="px-2 py-1" target="_blank" to="/student-feedback">
                  <span class="">Alumni Feedback</span>
                </Link>
              </li>
              <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-900">
                <Link class="px-2 py-1"  to="/teacher-feedback" target="_blank">
                  <span class="">Teacher Feedback</span>
                </Link>
              </li>
            </ul>
          </li>

              <li
                class="relative mx-1 px-1 py-2 group bg-white   mb-1 md:mb-0"
                id="button_mlist"
              >
                <li
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to=""
                >
                  <span class="firstlevel">More</span>
                </li>
                <ul class="  w-max mx-auto mt-2 p-2 rounded-lg shadow-lg bg-white z-10 hidden group-hover:block">
                  <svg
                    class="block fill-current text-white w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                  </svg>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="/alumni">
                      <span class="">Alumni</span>
                    </Link>
                  </li>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="/naacssr">
                      <span class="">SSR</span>
                    </Link>
                  </li>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="">
                      <span class="">Reach us</span>
                    </Link>
                  </li>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="#">
                      <span class="">Statistics</span>
                    </Link>
                  </li>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="/download">
                      <span class="">Download</span>
                    </Link>
                  </li>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-900">
                <Link class="px-2 py-1"  to="/teacher-feedback" target="_blank">
                  <span class="">Teacher Feedback</span>
                </Link>
              </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </>
      {/* <div
          onClick={() => {
            setNav(!nav);
          }}
          className="z-10 flex  bg-gray-50 flex-row-reverse py-1  md:hidden"
        >
          {!nav ? (
            <AiOutlineMenu className="text-black   text-4xl" />
          ) : (
            <AiOutlineClose className="text-black  text-4xl" />
          )}
        </div>

        <div
          className={
            !nav
              ? " fixed left-[-100%]"
              : "z-10 fixed inset-0 shadow-lg top-44 left-20 right-20 h-max w-90 text-center font-extrabold font-popin  border-round rounded border-r-gray-900 text-black   bg-white transition duration-250 ease-in   md:hidden"
          }
        >
          <div class="grid " id="main_menu">
            <ul
              class="grid p-1 md:p-2  sm:  text-sm md:text-base"
              id="menu_nav"
            >
              <li
                class="relative mx-1 px-1 py-2 group    mb-1 md:mb-0"
                id="button_home"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-red-600 hover:text-red-600"
                  to="/"
                >
                  <span class="last firstlevel">Home</span>
                </Link>
              </li>

              <li
                class="relative mx-1 px-1 py-2 group    mb-1 md:mb-0"
                id="button_moderate"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to="/about"
                >
                  <span class="firstlevel">About Us</span>
                </Link>
              </li>
              <li
                class="relative mx-1 px-1 py-2 group   mb-1 md:mb-0"
                id="button_profile"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to=""
                >
                  <span class="firstlevel">People</span>
                </Link>
                <ul class="=  w-max mx-auto mt-2 p-2 rounded-lg shadow-lg bg-white z-10 hidden group-hover:block">
                  <svg
                    class="block fill-current text-white w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                  </svg>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 ">
                    <Link class="px-2 py-1" to="/faculty">
                      <span class="">Faculty</span>
                    </Link>
                  </li>

                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="">
                      <span class="">Research Scholar</span>
                    </Link>
                  </li>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="/student">
                      <span class="">Students</span>
                    </Link>
                  </li>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to=" \">
                      <span class="">Staff</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                class="relative mx-1 px-1 py-2 group bg-white   mb-1 md:mb-0"
                id="button_pm"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to=" #"
                >
                  <span class="firstlevel">Courses & Syllabi</span>
                </Link>
                <ul class="w-max mx-auto mt-2 p-2 rounded-lg shadow-lg bg-white z-10 hidden group-hover:block">
                  <svg
                    class="block fill-current text-white w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                  </svg>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="msc-syllubs">
                      <span class=""> M.Sc.</span>
                    </Link>
                  </li>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to=" #">
                      <span class="">Ph.D.</span>
                    </Link>
                  </li>

                </ul>
              </li>
              <li
                class="relative mx-1 px-1 py-2 group bg-white   mb-1 md:mb-0"
                id="button_like"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to="/research"
                >
                  <span class="firstlevel">Research</span>
                </Link>
              </li>
              <li
                class="relative mx-1 px-1 py-2 group bg-white   mb-1 md:mb-0"
                id="button_like"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to="  #"
                >
                  <span class="firstlevel">Notice</span>
                </Link>
              </li>
              <li
                class="relative mx-1 px-1 py-2 group bg-white   mb-1 md:mb-0"
                id="button_like"
              >
                <Link
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to="  #"
                >
                  <span class="firstlevel">Activities</span>
                </Link>
              </li>
              <li
                class="relative mx-1 px-1 py-2 group bg-white   mb-1 md:mb-0"
                id="button_mlist"
              >
                <li
                  class="font-semibold whitespace-no-wrap text-gray-600 hover:text-red-600"
                  to=""
                >
                  <span class="firstlevel">More</span>
                </li>
                <ul class="  w-max mx-auto mt-2 p-2 rounded-lg shadow-lg bg-white z-10 hidden group-hover:block">
                  <svg
                    class="block fill-current text-white w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                  </svg>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="">
                      <span class="">Alumni</span>
                    </Link>
                  </li>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="">
                      <span class="">Reach us</span>
                    </Link>
                  </li>
                  <li class="p-1 whitespace-no-wrap   text-sm md:text-base text-gray-600 hover:text-red-600 hover:bg-gray-100">
                    <Link class="px-2 py-1" to="">
                      <span class="">Download</span>
                    </Link>
                  </li>

                      </ul>
              </li>
            </ul>
          </div>
        </div> */}
    </>
  );
}

export default Navbar;
