import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Navbar from "../../components/Navbar";
import Footer from "../../Footer";
import Topbar from "../landing/Topbar";
import StaffCard from "./StaffCard";
import { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "../../page/facultydashboard/formGroup/Spinner";

function StaffPreview() {
  const [data, setData] = useState([]);
  // console.log(data);
  const staff = () => {
    axios
      .get("/api/v1/get/all/staff")
      .then(function (response) {
        // handle success
        //console.log(response.data);
        setData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  useEffect(() => {
    staff();
  }, []);
  const [datas, setDatas] = React.useState({});
  const [loader, setLoader] = useState(true);
  // console.log(datas);
  const siteData = () => {
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setDatas(response.data[0]);
        setLoader(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  React.useEffect(() => {
    siteData();
  }, []);

  return (
    <>
      {loader && <Spinner />}
      <Topbar />
      <Navbar datas={datas} />
      <Breadcrumbs page={"Staff"} pagelink="#" sublink="#" subpage={"People"} />
      <div className="grid justify-items-center my-6 w-full">
        <div className=" grid sm:grid-cols-3 grid-cols-1 gap-4">
          {data?.map((e) => {
            return (
              <>
                <StaffCard
                  name={e.name}
                  email={e.emailId}
                  mob={e.mobileNo}
                  pos={e.position}
                  profile={e.profilePic}
                />
              </>
            );
          })}
        </div>
      </div>
      <Footer datas={datas} />
    </>
  );
}

export default StaffPreview;
