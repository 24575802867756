import React from 'react'

function Input({ placeholder, accept, label, name,multiple , man, type, value, onChange, defaultValue, inputCss , labelCss }) {
    return (
        <div className="">
            <label

                className={ `block mb-2 text-sm font-medium text-gray-900  ${labelCss} `}
            >
                {label}
                <span className='text-red-500 font-bold text-[1.2rem]'>{man}</span>
            </label>
            <input
            accept={accept}
                type={type}
                multiple={multiple}
                name={name}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                className={`w-full h-9 mt-2 rounded-sm border focus:border-gray-700 p-3 text-sm focus:outline-none focus:bg-white bg-gray-200 ${inputCss}`}
                placeholder={placeholder}
                // required='true'
            />
        </div>
    )
}

export default Input