import React, { useState, useEffect } from 'react'
import Footer from '../Footer'
import Card from './Card'
import Navbar from '../components/Navbar'
import Ashim from '../assests/AshimPhoto.png'
import Arvind from '../assests/ArvindKumar2.jpeg'
import DPSingh from '../assests/DPSingh.jpg'
import DRVIMALSAGAR from '../assests/DRVIMALSAGAR.jpg'
import AKumar from '../assests/AKumar.jpg'
import Breadcrumbs from '../components/Breadcrumbs'
import Topbar from './landing/Topbar'
import axios from 'axios'
import Spinner from './facultydashboard/formGroup/Spinner'
function Data() {

  const [state, setState] = useState([])
  const [loader, setLoader] = useState(true)

  // console.log(state)









  // // /api/v1/user-list

  const getFacultyData = () => {
    axios.get('/api/v1/user-list')
      .then(function (response) {
        // handle success
        setState(response.data.userList)
        setLoader(false)
        // console.log(response.data.userList);
      })
      .catch(function (error) {
        // handle error
        setLoader(false)
        console.log(error);
      })
  }

  useEffect(() => {
    getFacultyData()
  }, [])
  const [datas, setData] = React.useState({})
  // console.log(datas)
  const siteData = () => {
    axios.get('/api/v1/api/site/data')
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0])
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }
  React.useEffect(() => {
    siteData()
  }, [])

  return (
    <div>
      {
        loader && <Spinner />
      }

      <Topbar />
      <Navbar datas={datas} />
      {/* <Card /> */}
      <Breadcrumbs page={"Faculty"} pagelink="#" sublink="#" subpage={"People"} />
      <div className='grid justify-items-center w-full  p-10'>
        <div className=' grid sm:grid-cols-4 grid-cols-1  w-full'>
          {
            state.map((p, i) => {
              {/* console.log(p.img) */ }
              return (
                <>
                  {
                    p.role === 0 &&
                    <div className='' key={i + 1}>
                      <Card img={p.profilePic} title={p.title} name={p.name} profession={p.designation} email={p.email} id={p._id} mobile={p.mobileNo} about={p.about} />
                    </div>
                  }

                </>
              )

            })
          }
        </div></div>
      <Footer datas={datas} />
    </div>
  )
}

export default Data