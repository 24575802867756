import axios from 'axios'
import React, { useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import Navbar from '../../components/Navbar'
import Footer from '../../Footer'
import Spinner from '../facultydashboard/formGroup/Spinner'
import Topbar from '../landing/Topbar'
import NaacSsr from './NaacSsr'

function NaacSsrPreview() {
    const [datas, setData] = React.useState({})
    const [loader,setLoader] = useState(true)
    // console.log(datas)
    const siteData = () => {
      axios.get('/api/v1/api/site/data')
        .then(function (response) {

          // handle success
          // console.log(response);
          setData(response.data[0])
          setLoader(false)

        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
    }
    React.useEffect(() => {
      siteData()
    }, [])
  return (
    <div>
<>
      {
        loader && <Spinner/>
      }
        <Topbar datas={datas}/>
        <Navbar datas={datas}/>
        <Breadcrumbs page={"SSR"} pagelink="#" sublink='#' subpage='More' />
        <NaacSsr/>
        <Footer datas={datas}/>
    </>
    </div>
  )
}

export default NaacSsrPreview