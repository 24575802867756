import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Input from "../../formGroup/Input";
import Spinner from "../../page/facultydashboard/formGroup/Spinner";

function Annoucement(props) {
  const [data, setData] = React.useState({});
  const [loader, setLoader] = useState(false);
  const [titleOfFile, settitleOfFile] = useState("");
  const [pdfUpload, setpdfUpload] = useState("");

  // console.log(courses,titleOfFile,pdfUpload)

  const announcement = (event) => {
    event.preventDefault();
    if (!titleOfFile || !pdfUpload) {
      message.error("All Field are required");
    } else {
      const formData = new FormData();
      formData.append("pdf", pdfUpload);
      formData.append("title", titleOfFile);

      setLoader(true);

      axios
        .post(`/api/v1/anouncement/upload `, formData)
        .then(function (response) {
          setLoader(false);

          message.success(response.data.message);
          settitleOfFile();
          setpdfUpload();
          props.onClick();
        })
        .catch(function (error) {
          console.log(error);
          setLoader(false);
          message.error(error.response.data.errors);
        });
    }
  };

  // get data
  const siteData = () => {
    setLoader(true);
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setLoader(false);
        setData(response.data[0]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoader(false);
      });
  };

  React.useEffect(() => {
    siteData();
  }, []);
  return (
    <>
      {loader && <Spinner />}
      <Breadcrumbs
        page="Announcement & Notice"
        pagelink="#"
        sublink="#"
        subpage="Content Page"
      />
      <div className="lg:w-1/2 w-full ">
        <div className="grid gap-4 mx-8 grid-cols-1 my-auto p-5 bg-white">
          <Input
            label={"Title"}
            inputCss={" h-12"}
            value={titleOfFile}
            onChange={(e) => {
              settitleOfFile(e.target.value);
            }}
          />
          <Input
            type="file"
            label={"upload pdf (20mb max)"}
            inputCss={" h-12"}
            onChange={(e) => {
              setpdfUpload(e.target.files[0]);
            }}
            accept=".pdf"
          />
          {/* <div className="my-auto">
            <div className="flex gap-2">
              <label
                for="AcceptConditions"
                class="relative h-6 w-12 cursor-pointer"
              >
                <input
                  type="checkbox"
                  id="AcceptConditions"
                  class="peer sr-only"
                />

                <span class="absolute inset-0 rounded-full bg-gray-300 transition peer-checked:bg-green-500"></span>

                <div></div>
                <span class="absolute inset-0 m-1 h-4 w-4 rounded-full bg-white transition peer-checked:translate-x-6"></span>
              </label>
              <h1>Publish</h1>
            </div>
          </div> */}
          <div className="my-auto">
            <h1 className="opacity-0">k</h1>
            <button
              className="inline-block mt-1  px-4 py-2 rounded border border-[#273272]   text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
              onClick={announcement}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Annoucement;
