import React, { useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import Confirm from '../../formGroup/Confirm'
import { Alert, Dropdown, Input } from '../../formGroup/Index'
import { Widget } from "@uploadcare/react-widget";
import axios from 'axios'
function AddFaculty() {
    const intialValue = {
        name: "",
        email: "",
        designation: "",
        mobileNo: "",
        userRole: "",
        setPass: "",
        cnfPass: "",
        profileLink: "",
        pic: "",
        orcidId: "",
        scopusId: "",

    }
    const [title, setTitle] = useState('')
    const [profilePic, setProfilePic] = useState()
    const [role, setRole] = useState()
    // console.log(profilePic)
    const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const onSubmit = (e) => {
        // setState({ [i.target.name]: "" })
        e.preventDefault()
        if (state.cnfPass === state.setPass) {
            addUser()
        }
        else {
            alert('SetPassword and Confirm Password does not match')
        }
    }
    const [state, setState] = useState(intialValue)
    // console.log(state)
    const roleData = ["---Select Role---", "Admin", "Faculty", "Operator"]

    // console.log(state.addrss,profilePic)
    const addUser = () => {

        axios.post('/api/v1/signup', {
            name: state.name,
            email: state.email,
            password: state.setPass,
            mobileNo: state.mobileNo,
            designation: state.designation,
            role: role,
            profileLink: state.profileLink,
            profilePic: uploadedFile,
            orcidId: state.orcidId,
            scopusId: state.scopusId,
            title
        })
            .then(function (response) {
                // console.log(response);
                alert(response.data.message)
                setState({ ...intialValue })
                setUploadedFile('')
                setTitle('')
            })
            .catch(function (error) {
                console.log(error);
                alert(error.response.data.errors)
            });
    }


    // image upload
    const [uploadedFile, setUploadedFile] = useState("");

    return (
        <>
            {/* <Confirm/> */}
            <Breadcrumbs page="Add User" pagelink="/admin/dashboard/adduser" sublink="/admin/dashboard" subpage="Dashboard" />

            <div className='bg-white rounded-lg w-5/6 mx-4 shadow-xl  px-4 py-2 '>
                <form onSubmit={onSubmit} className='w-5/6 grid mx-auto  items-center md:grid-cols-2 gap-x-4 gap-y-2 '>




                    <div className='col-span-full'>

                        <div>
                            <label for="category" >User Role<span className='text-red-500 font-bold text-[1.2rem]'>*</span></label>
                            <select value={role} onChange={(e) => { setRole(e.target.value) }} id="category" class={`w-full h-9 mt-2 rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}>
                                <option className="" value=''>-----Select-----</option>
                                <option className="" value='0'>Faculty</option>
                                {/* <option className="" value='1'>Admin</option> */}
                                <option className="" value='2'>Operator</option>
                            </select>
                        </div>
                    </div>


                    {
                        role === "0" || role === "" ? <>
                            <div className='col-span-full' >
                                <div className='grid md:grid-cols-3 gap-x-4 gap-y-2 '>
                                    <div>
                                        <label className='block mb-2 text-sm font-medium text-gray-900 '>Tittle<span className='text-red-500 font-bold text-[1.2rem]'>*</span></label>
                                        <select value={title} onChange={(e) => { setTitle(e.target.value) }} id="category" class={`w-full mt-2 h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200`}>
                                            <option className="" value=''>-----Select-----</option>
                                            <option value="Mr.">Mr.</option>
                                            <option value="Mrs.">Mrs.</option>
                                            <option value="Miss.">Miss.</option>
                                            <option value="Prof.">Prof.</option>
                                            {/* <option className="" value='1'>Admin</option> */}
                                            <option className="" value='Dr.'>Dr.</option>
                                        </select>
                                    </div>
                                    <Input label="Name" name="name" man="*" type="text" value={state.name} onChange={onChange} placeholder="" />
                                    <Input label="Email" name="email" man="*" type="email" value={state.email} onChange={onChange} placeholder="" />
                                </div>
                            </div>
                            <Input label="Designation" man="*" name="designation" type="text" value={state.designation} onChange={onChange} placeholder="" />
                            <Input label="Mobile No." man="*" name="mobileNo" type="number" value={state.mobileNo} onChange={onChange} placeholder="" />
                            <div className='col-span-full'>
                                <div className='grid md:grid-cols-3 gap-x-4 gap-y-2'>
                                    <Input label="Vidwan ID" name="profileLink" man="" type="text" value={state.profileLink} onChange={onChange} placeholder="" />
                                    <Input label="Orcid ID" name="orcidId" man="" type="text" value={state.orcidId} onChange={onChange} placeholder="" />
                                    <Input label="Scopus Id" name="scopusId" man="" type="text" value={state.scopusId} onChange={onChange} placeholder="" />
                                </div>
                            </div>

                            <Input label="Set password" man="*" name="setPass" type="password" value={state.setPass} onChange={onChange} placeholder="" />
                            <Input label="Confirm password" man="*" name="cnfPass" type="password" value={state.cnfPass} onChange={onChange} placeholder="" />
                            <div>
                                <span>Upload your Profile picture</span>
                                <div className='bg-[#e5e7eb] text-black-500'>
                                    <Widget
                                        publicKey="1e63758e5c94313da33d"
                                        onChange={info => setUploadedFile(info.cdnUrl)}
                                    />
                                </div></div>
                            {uploadedFile && (
                                <img src={uploadedFile} alt="Uploaded file" width="100" />
                            )}
                        </> : <>
                            <Input label="Name" name="name" man="*" type="text" value={state.name} onChange={onChange} placeholder="" />
                            <Input label="Email" name="email" man="*" type="email" value={state.email} onChange={onChange} placeholder="" />
                            <Input label="Designation" man="*" name="designation" type="text" value={state.designation} onChange={onChange} placeholder="" />
                            <Input label="Mobile No." man="*" name="mobileNo" type="number" value={state.mobileNo} onChange={onChange} placeholder="" />
                            <Input label="Set password" man="*" name="setPass" type="password" value={state.setPass} onChange={onChange} placeholder="" />
                            <Input label="Confirm password" man="*" name="cnfPass" type="password" value={state.cnfPass} onChange={onChange} placeholder="" />
                        </>
                    }

                    {/* <Input label="Upload Photo" man="*"  inputCss={"h-12"} name="pic" type="file" onChange={(e) => { setProfilePic(e.target.files[0]) }} placeholder="" /> */}


                    <div className='col-span-full'>
                        <div className='flex justify-center mt-2'><button className='mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2'>Submit</button></div>
                    </div>




                </form>
            </div>
        </>
    )
}

export default AddFaculty
