import axios from 'axios'
import React, { useState,useEffect } from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import Notice from './Notice'
import NoticeTable from './NoticeTable'
function NoticePreview() {
  const [data, setData] = React.useState([])
  const [loader, setLoader] = useState(false)
  // console.log(data)
  const siteData = () => {
    axios.get('/api/v1/find-file-news')
      .then(function (response) {
        setData(response.data)
      })
      .catch(function (error) {
        console.log(error);
      })
  }
  React.useEffect(() => {
    siteData()
  }, [])
  // console.log(data)
  return (
    <div>
          <div style={{ width: '100%',  }}>
    <div>
        <div style={{ width: '100%', height:'100vh', overflow:'scroll'}}>
            <Navbar />
        <div className='flex bg-[#F4F4F5]' style={{ width: '100%' }}>
          <Sidebar />
        <div className='' style={{ width: '100%' }}>
        <Notice onClick={siteData}/>
        <NoticeTable onClick={siteData} data={data}/>
        </div>
        </div>



        </div>
    </div>
</div>
    </div>
  )
}

export default NoticePreview