import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Input } from "../formGroup/Index";

function Event(props) {
  // console.log(props)
  const [edustate, setEduState] = useState(false);
  const initialState = {
    event: "",
  };
  const [editState, setEditState] = useState(false);
  const [state, setState] = useState(initialState);

  const removeBatch = (id) => {
    props.loader(true);
    axios
      .delete(`/api/v1/event/delete/${id}`)
      .then(function (response) {
        props.loader(false);
        message.success(response.data.message);
        miscData();
      })
      .catch(function (error) {
        message.error(error.response.data.message);
        console.log(error);
        console.log(error.response.data.message);
        props.loader(false);
      });
  };

  const [hdn, setHdn] = useState(false);

  const [k, setK] = useState("hidden");
  const addD = () => {
    setEduState(!edustate);
    setHdn(!hdn);
    if (hdn === false) {
      setK("block");
    } else {
      setK("hidden");
    }
  };
  const [event, setEvent] = useState("");
  const [authorName, setAuthorName] = useState(props.data.name);
  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/v1/miscellaneous/event", {
        event: event,
        authorName: authorName,
      })
      .then(function (response) {
        // console.log(response);
        // alert(response.data.message);
        // console.log({role:response.data.role});
        // setTde([...tde, {  "subject": subject, "exptr": exptr, "brief":brief }]);
        setEduState(false);
        // setState({ [e.target.name]: "" });
        // console.log(response.data);
        message.success(response.data.message);
        miscData();
        // alert('response.data.message')
        // setState({ ...intialValue })
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response.data.errors);
        message.error(error.response.data.errors);
      });
  };

  const [data, setData] = useState([]);
  // console.log(data)
  const miscData = () => {
    props.loader(true);
    axios
      .get("/api/v1/miscellaneous/event")
      .then(function (response) {
        // handle success
        setData(response.data.data);
        props.loader(false);
        // console.log(response.data.data);
        // console.log(response)
      })
      .catch(function (error) {
        // handle error
        props.loader(false);
        console.log(error);
      });
  };
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const editA = (id, event) => {
    console.log(id, event);
    setId(id);
    setEvent(event);
    setIsEdit(true);
    setEduState(!edustate);
  };

  const updateEdit = () => {
    axios
      .patch(`/api/v1/miscellaneous/event/${id}`, {
        event: event,
        authorName: authorName,
      })
      .then(function (response) {
        props.loader(false);
        setEvent("");
        setEduState(false);
        setIsEdit(false);
        message.success(response.data.message);
        miscData();
        // setHide(true)
      })
      .catch(function (error) {
        props.loader(false);
        message.error(error.response.data.message);
      });
  };

  // console.log(data)
  useEffect(() => {
    miscData();
  }, []);
  return (
    <>
      <div className=" ">
        <div className="   " style={{ width: "100%" }}>
          <div className="flex space-x-2 justify-between ">
            <h1 className=" text-[1.7rem] mt-[2rem]" id="edu">
              Event
            </h1>
            <div className="flex gap-2">
              <button
                className="mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                onClick={addD}
              >
                Add
              </button>
              <button
                className={`${k} mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`}
                onClick={addD}
              >
                close
              </button>
            </div>
          </div>

          {edustate ? (
            <>
              <div className="grid grid-cols-3 space-x-2 justify-between gap-2">
                <div className="col-span-2">
                  <Input
                    placeholder=""
                    label="Events"
                    name="event"
                    type="text"
                    value={event}
                    onChange={(e) => setEvent(e.target.value)}
                  />
                </div>

                <div className="my-auto">
                  <h1 className="opacity-0"> k</h1>
                  <button
                    type="submit"
                    className="mt-auto px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none"
                    onClick={isEdit ? updateEdit : onSubmit}
                  >
                    {isEdit ? "Update" : "submit"}
                  </button>
                </div>
              </div>
            </>
          ) : null}

          {data?.map((elm, ind) => {
            // console.log(elm)
            return (
              <>
                {props.data._id === elm.authorId && (
                  <>
                    <div
                      className="flex gap-2 items-center justify-between mt-7"
                      style={{ width: "100%" }}
                      key={ind}
                    >
                      <div className="edu1">{elm.events}</div>
                      <div className="edu2">
                        {elm.authorName} {elm.year}
                      </div>
                      <div className="flex space-x-4">
                        <button
                          className=" px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                          onClick={() => editA(elm._id, elm.events)}
                        >
                          Edit
                        </button>
                        <button
                          className=" px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                          onClick={() => removeBatch(elm._id)}
                        >
                          delete
                        </button>
                      </div>
                    </div>
                    <hr className="facultyHr" />
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Event;
