import React, { useEffect, useState } from "react";
import Input from "../../../formGroup/Input";
import axios from "axios";
import { message } from "antd";

function AddClass(props) {
  const [topic, setTopic] = useState("");
  const [link, setLink] = useState("");
  const [classStart, setClassStart] = useState("");
  const [classEnd, setClassEnd] = useState("");
  const [facultyName, setFacultyName] = useState("");
  const [classData, setClassData] = useState([]);
  console.log(props, "add class");
  const onSubmit = (e) => {
    props.loader(true);
    axios
      .post("/api/v1/class", {
        topic: topic,
        link: link,
        classStart: classStart,
        classEnd: classEnd,
        facultyName: props.data.name,
      })
      .then(function (response) {
        // console.log(response.data);
        props.loader(false);
        if (response.data.success) {
          setTopic("");
          setLink("");
          setClassStart("");
          setClassEnd("");
          addClassData();
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch(function (error) {
        props.loader(false);
        console.log(error);
        console.log(error.response.data.message);

        message.error(error.response.data.message);
      });
  };
  const addClassData = () => {
    axios
      .get("/api/v1/class")
      .then(function (response) {
        // handle success

        setClassData(response.data);

        // console.log(response.data.userList);
      })
      .catch(function (error) {
        // handle error

        console.log(error);
      });
  };
  const addClassDataDelete = (id) => {
    props.loader(true);
    axios
      .delete(`/api/v1/class/${id}`)
      .then(function (response) {
        // handle success

        props.loader(false);
        message.success(response.data.message);
        addClassData();
        // console.log(response.data.userList);
      })
      .catch(function (error) {
        // handle error
        props.loader(false);
        message.error(error.response.data.message);
        console.log(error);
      });
  };

  useEffect(() => {
    addClassData();
  }, [classData]);

  return (
    <div>
      <div className=" w-3/4 ">
        <div className="grid gap-4 mx-8 grid-cols-1 my-auto p-5 bg-white">
          <Input
            label={"Topic of class"}
            inputCss={" h-12"}
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
          />
          <Input
            label={"Paste google meet/zoom link here"}
            inputCss={" h-12"}
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          <div className="col-span-full grid grid-cols-2 gap-2">
            <Input
              type={"datetime-local"}
              label={"class start at"}
              inputCss={" h-12"}
              value={classStart}
              onChange={(e) => setClassStart(e.target.value)}
            />
            <Input
              type={"time"}
              label={"class ends  at"}
              inputCss={" h-12"}
              value={classEnd}
              onChange={(e) => setClassEnd(e.target.value)}
            />
          </div>

          <div className="my-auto">
            <button
              className="px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
              onClick={onSubmit}
            >
              Submit
            </button>
          </div>

          <div class="overflow-x-auto  mt-4 rounded-lg border border-gray-200">
            <table class="min-w-full divide-y-2 divide-gray-200 text-sm">
              <thead>
                <tr>
                  <th class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Faculty
                  </th>
                  <th class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Topic of class
                  </th>

                  <th class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Link
                  </th>
                  <th class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Time of class
                  </th>
                </tr>
              </thead>
              {classData.data?.map((a, ind) => {
                return (
                  <>
                    {props.data.name === a.facultyName && (
                      <tbody class="divide-y divide-gray-200" key={ind}>
                        <tr>
                          <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">
                            {a.facultyName}
                          </td>

                          <td class="whitespace-nowrap  border px-4 py-2 font-medium text-gray-900">
                            {a.topic}
                          </td>
                          <td class="whitespace-nowrap  border px-4 py-2 font-medium text-gray-900">
                            <a
                              className="inline-block  rounded bg-[#273272] px-4 py-2 text-xs font-medium text-white hover:bg-red-700"
                              href={a.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Click here to enter
                            </a>
                          </td>
                          <td class="whitespace-nowrap  border px-4 py-2 font-medium text-gray-900">
                            on {a.classStart.slice(0, 10)} from{" "}
                            {a.classStart.slice(12, 16)} to {a.classEnd}
                          </td>

                          <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">
                            <button
                              className=" px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                              onClick={() => addClassDataDelete(a._id)}
                            >
                              delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddClass;
