import React, { useState } from "react";
import axios from "axios";
import Input from "../formGroup/Input";
import Spinner from "../formGroup/Spinner";

function ProfileId(props) {
  console.log(props, "ind");

  const [profileLink, setprofileLink] = useState(props.data.profileLink);
  const [orcidId, setOrcidId] = useState(props.data.orcidId);
  const [scopusId, setScopusId] = useState(props.data.scopusId);
  const [loader, setLoader] = useState(false);
  const editUser = () => {
    setLoader(true);
    axios
      .put(`/api/v1/users/${props.data._id}`, {
        profileLink,
        orcidId,
        scopusId,
      })
      .then(function (response) {
        // console.log(response);
        alert(response.data.message);
        // props.setShowModal(false);
        props.onClick();
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.errors);
        setLoader(false);
      });
  };

  return (
    <div>
      {loader && <Spinner />}
      <div className="">
        <h1 className=" text-[1.7rem] mt-[2rem]" id="edu">
          Profile Links
        </h1>

        <div className="grid md:grid-cols-3 gap-x-4 gap-y-2">
          <Input
            label="Profile link"
            name="profileLink"
            type="text"
            value={profileLink}
            onChange={(e) => {
              setprofileLink(e.target.value);
            }}
            placeholder=""
          />
          <Input
            label="Orcid ID"
            name="orcidId"
            type="text"
            value={orcidId}
            onChange={(e) => {
              setOrcidId(e.target.value);
            }}
            placeholder=""
          />
          <Input
            label="Scopus Id"
            name="scopusId"
            type="text"
            value={scopusId}
            onChange={(e) => {
              setScopusId(e.target.value);
            }}
            placeholder=""
          />
        </div>
        <div className="flex justify-end mt-2">
          <button
            className="px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none"
            type="button"
            onClick={() => editUser()}
            // onClick={() => props.setShowModal(false)}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileId;
