import React from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import SiteTittle from './SiteTittle'

function SiteTitlePreview() {
  return (
    <div>
        <div style={{ width: '100%',  }}>
    <div>
        <div style={{ width: '100%', height:'100vh', overflow:'scroll'}}>
            <Navbar />
        <div className='flex bg-[#F4F4F5]' style={{ width: '100%' }}>
          <Sidebar />
        <div className='' style={{ width: '100%' }}>
        <SiteTittle/>
        </div>
        </div>
      
            
            
        </div>
    </div>
</div>
    </div>
  )
}

export default SiteTitlePreview