import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../page/facultydashboard/formGroup/Spinner";

function DownloadTable(props) {
  const [loader, setLoader] = useState(false);
  const setAction = (action, id) => {
    axios
      .patch(`/api/v1/download/update/${action ? "inactive" : "active"}/${id}`)
      .then(function (response) {
        message.success(response.data.message);
        props.onClick();
      })
      .catch(function (error) {
        console.log(error);
        message.error("Something went wrong");
      });
  };

  return (
    <>
      {loader && <Spinner />}
      <div className="mx-4  ">
        <div className="relative overflow-x-auto mt-2">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Title Of File
                </th>
                <th scope="col" className="px-6 py-3">
                  Download
                </th>
                <th scope="col" className="px-6 py-3">
                  State
                </th>
                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {props.download &&
                props.download.toReversed()?.map((e, i) => {
                  return (
                    <>
                      <tr className="bg-white border-b ">
                        <td className="px-6 py-4">{e.title}</td>
                        <td className="px-6 py-4">
                          <Link
                            to={`/api/v1/download-news/${e.fileName}`}
                            target="_blank"
                          >
                            Download
                          </Link>
                        </td>
                        <td className="px-6 py-4">
                          <div className="my-auto">
                            <select
                              id="category"
                              value={e.publish}
                              onChange={() => {
                                setAction(e.publish, e._id);
                              }}
                              class={`w-max h-9 mt-2 rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-gray-100`}
                            >
                              <option className="" value="">
                                -----Status-----
                              </option>
                              <option className="" value={true}>
                                Published
                              </option>
                              <option className="" value={false}>
                                Unpublished
                              </option>
                            </select>
                          </div>
                        </td>
                        <td className="px-6 py-4"></td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default DownloadTable;
