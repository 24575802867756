import React from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { ImBullhorn } from 'react-icons/im'
import { BsFillGearFill } from 'react-icons/bs'
import { GrDocumentUpdate } from 'react-icons/gr'
import { BiNews } from 'react-icons/bi'
import { AiOutlineStock } from 'react-icons/ai'
import { Link } from 'react-router-dom'


function Card() {
    const data = [
        {
            name: "User",
            icn: <FaUserAlt className='h-8 w-8 m-2' />,
            subtlt:"Add/View User",
            url:"/admin/dashboard/adduser"
        },
        {
            name: "Announcement",
            icn: <ImBullhorn className='h-8 w-8 m-2' />,
            subtlt:"Add/View Announcement",
            url:"/admin/dashboard/addannoucement"

        }, {
            name: "News & Event",
            icn: <BiNews className='h-8 w-8 m-2 ' />,
            subtlt:"Add/View News & Event",
            url:"/admin/dashboard/addnotice"

        }, {
            name: "Site Update",
            icn:<AiOutlineStock className='h-8 w-8 m-2'/>,

            subtlt:"Footer Update",
            url:"/admin/dashboard/sitefooter"
        },
    

    ]
    
    
    return (
        <>
        <div className='grid md:grid-cols-4 gap-2 mx-2 my-4'>
            {
                data.map((e) => {
                    return (
                        <>
                            <Link to={e.url} class="p-6 cursor-pointer hover:animate-pulse  w-full mx-auto bg-white rounded-xl shadow-lg flex items-center space-x-4">
                                <div class="shrink-0">
                                    <div class=" text-white p-2 flex justify-center items-center rounded-full bg-[#273272]" >
                                        {e.icn}
                                    </div>

                                </div>
                                <div>
                                    <div class="text-xl font-medium text-black">{e.name}</div>
                                    <p class="text-slate-500">{e.subtlt}</p>
                                </div>
                            </Link>
                            
                        </>
                    )
                })
            }

            

        </div>
           

        </>


    )
}

export default Card