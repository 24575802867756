import React from "react";
import { useState } from "react";

function ReportError() {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };
  return (
    <div>
      <section className="p-6  dark:text-gray-50">
        <h1 className="text-red-500 font- text-3xl">Report Your Error</h1>
        <form
          novalidate=""
          action=""
          className="container flex flex-col mx-auto space-y-12 ng-untouched ng-pristine ng-valid"
        >
          <fieldset className="grid grid-cols-4 gap-6 p-6 rounded-md shadow-sm ">
            <div className="grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
              <div className="col-span-full sm:col-span-3">
                <label for="name" className="text-sm">
                  Name
                </label>
                <input
                  id="name"
                  type="text"
                  placeholder="Name"
                  className="w-full rounded-md focus:ring-1 focus:ring-opacity-75  dark:border-gray-700 dark:text-gray-900"
                  fdprocessedid="d42ki4"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label for=" Email" className="text-sm">
                  Email
                </label>
                <input
                  id=" Email"
                  type="text"
                  placeholder="abc@gmail.com"
                  className="w-full rounded-md focus:ring-1 focus:ring-opacity-75   dark:border-gray-700 dark:text-gray-900"
                  fdprocessedid="7biofm"
                />
              </div>
              <div className="flex w-full col-span-full gap-6">
              <div className=" sm:col-span-3 w-4/5">
                <label for=" number" className="text-sm">
                  Mobile No.
                </label>
                <input
                  id=" number"
                  type="number"
                  placeholder="+91"
                  className="w-full rounded-md focus:ring-1 focus:ring-opacity-75   dark:border-gray-700 dark:text-gray-900"
                  fdprocessedid="7biofm"
                />
              </div>
              <div className=" sm:col-span-3 w-4/5">
                <label for=" number" className="text-sm">
                  Url of site
                </label>
                <input
                  id=""
                  type="url"
                  placeholder=" Url of site"
                  className="w-full rounded-md focus:ring-1 focus:ring-opacity-75   dark:border-gray-700 dark:text-gray-900"
                  fdprocessedid="7biofm"
                />
              </div>
              <div className="col-span-full sm:col-span-3 w-full">
                {/* <label for=" number" className="text-sm">
                  Choose Dept.
                </label> */}
                {/* <select
                  className="w-full    text-sm rounded"
                  id="Dept"
                  name="ChhoseDept"
                >
                  <option value="">Choose Dept</option>
                  <option value="Dept1">Dept 1</option>
                  <option value="Dept2">Dept 2</option>
                  <option value="Dept3">Dept 3</option>
                </select> */}



<div className="col-span-full">
					<label for="queries" className="text-sm">Describe Queries</label>
					<textarea id="queries" placeholder="Enter your Queries Here.." className="w-full rounded-md focus:ring-1 focus:ring-opacity-75  dark:border-gray-700 dark:text-gray-900" spellcheck="false"></textarea>
				</div>
              </div>




              </div>

              <div className="col-span-full">
                <div className="flex items-center space-x-2">
                {selectedImage && (
        <img src={URL.createObjectURL(selectedImage)} alt="Preview"  className="w-40 h-28 rounded "/>
      )}

                  <div className="flex flex-col gap-5">
                    <label for="bio" className="text-lg">
                      Upload Screenshot
                    </label>
                    {/* <input
                      type="file"
                      id="myFile"
                      name="filename"
                      className=""
                      onChange={handleImageChange}
                    /> */}
                    <input type="file" onChange={handleImageChange} placeholder=""/>

                  </div>
                  <div className="flex justify-end w-full">
                    <button
                      type="button"
                      className="px-6 py-2 border rounded-md dark:border-gray-100 float-righ bg-green-900 text-white"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </section>
    </div>
  );
}

export default ReportError;
