import React, { useState } from 'react'
import Topbar from '../landing/Topbar'
import Navbar from '../../components/Navbar'
import Footer from '../../Footer'
import { Link } from 'react-router-dom'
import axios from 'axios'
function StudyMaterial() {
    const semster=[
        {
        sem:"Semester 1",
        lnk:"/pg-notes/Semester 1"
    },
    {
        sem:"Semester 2",
        lnk:"/pg-notes/Semester 2"
    },     {
        sem:"Semester 3",
        lnk:"/pg-notes/Semester 3"
    },     {
        sem:"Semester 4",
        lnk:"/pg-notes/Semester 4"
    },
]
const [datas, setData] = React.useState({})
  const [loader,setLoader] = useState(false)
  // console.log(datas)
  const siteData = () => {
    axios.get('/api/v1/api/site/data')
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0])
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }
  React.useEffect(() => {
    siteData()
  }, [])
  return (
    <>
    <Topbar/>
    <Navbar datas={datas}/>
    <div class="overflow-x-auto py-4 flex justify-center  px-4">
  <table class=" sm:w-[50rem] border  divide-y-2 divide-gray-200 text-sm">
    <thead>

    </thead>
    <tbody class="divide-y  divide-gray-200">
    <tr className=''>

                <td class="whitespace-nowrap border  w-3/4 px-4 py-2 font-medium text-gray-900">
                  Misc
                </td>
                <td class="whitespace-nowrap border  w-1/4  px-4 py-2">
                  <Link
                    to="/misc-notes"
                    class="inline-block  rounded bg-[#273272] px-4 py-2 text-xs font-medium text-white hover:bg-red-700"
                    >
                    View
                  </Link>
                </td>
              </tr>
      </tbody>
</table>
</div>
<div className='  mt-4   '>
            <>

<div class="overflow-x-auto py-2 flex justify-center px-4">
  <table class="sm:w-[50rem] border  divide-y-2 divide-gray-200 text-sm">
    <thead>
      <tr>
        <th
          class="whitespace-nowrap px-4 border  py-2 text-left font-medium text-gray-900"
          >
         P.G Semesters wise
        </th>

        <th class="px-4 border  py-2"></th>
      </tr>
    </thead>

    <tbody class="divide-y border  divide-gray-200">

    {semster.map((a)=>{
            return(
                <tr>
                <td class="whitespace-nowrap border  w-3/4 px-4 py-2 font-medium text-gray-900">
                  {a.sem}
                </td>
                <td class="whitespace-nowrap border  w-1/4 px-4 py-2">
                  <Link
                    to={a.lnk}
                    class="inline-block  rounded bg-[#273272] px-4 py-2 text-xs font-medium text-white hover:bg-red-700"
                    >
                    View
                  </Link>
                </td>
              </tr>
            )
        })}


    </tbody>
  </table>
</div>
</>
</div>

                      <div class="overflow-x-auto px-4  flex justify-center py-4 ">
  <table class="sm:w-[50rem] border  divide-y-2 divide-gray-200 text-sm">
    <thead>
      <tr>
        <th
          class="whitespace-nowrap px-4  border  py-2 text-left font-medium text-gray-900"
          >
         Ph.D
        </th>

        <th class="px-4 border  py-2"></th>
      </tr>
    </thead>

    <tbody class="divide-y border  divide-gray-200">


       <tr>
                <td class="whitespace-nowrap border  w-3/4 px-4 py-2 font-medium text-gray-900">
                 Ph.D Coursework
                </td>
                <td class="whitespace-nowrap border w-1/4 px-4 py-2">
                  <Link
                    to="/phd-notes"
                    class="inline-block  rounded bg-[#273272] px-4 py-2 text-xs font-medium text-white hover:bg-red-700"
                    >
                    View
                  </Link>
                </td>
              </tr>
    </tbody>
  </table>
</div>

<Footer datas={datas}/>
    </>
  )
}

export default StudyMaterial