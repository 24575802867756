import React, { useEffect, useState } from "react";
import { Dropdown, Input } from "../formGroup/Index";
import DltModal from "../formGroup/DltModal";
import { message } from "antd";
import axios from "axios";

function PersonalDetails(props) {
  // console.log(props.data.personalDetails)
  const [edustate, setEduState] = useState(false);
  const initialState = {
    dob: "",
    year: "",
    gender: "",
    dtail: "",
  };
  const gnder = ["Select", "Male", "Female", "Other"];
  const dataDob = ["DOB"];
  const dataGender = ["Gender"];
  const [editState, setEditState] = useState(false);
  const [state, setState] = useState(initialState);
  const [tde, setTde] = useState([]);
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const addmore = (e) => {
    setTde([
      ...tde,
      {
        dtail: state.dtail,
        dob: state.dob,
        year: state.year,
        gender: state.gender,
      },
    ]);
    setEduState(false);
    setState({ [e.target.name]: "" });
  };
  const removeBatch = (id) => {
    const updateList = tde.filter((elm, ind) => {
      return ind !== id;
    });
    setTde(updateList);
  };
  const editA = (dob, gender, id) => {
    // alert(designation,institute,startingYear,endingYear)

    setDob(dob);
    setGender(gender);
    setId(id);
    setIsEdit(true);
    setEduState(!edustate);
    
  };
  const [hdn, setHdn] = useState(false);
  const cancel = () => {
    setDob("");
    setGender("");

    isEdit(false);
  };
  const [k, setK] = useState("hidden");
  const addD = () => {
    setEduState(!edustate);
    setDob("");
    setGender("");
    setId("");
    setIsEdit(false);
    setHdn(!hdn);

    if (hdn === false) {
      setK("block");
    } else {
      setK("hidden ");
    }
  };

  const [dlt, setDlt] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/v1/personal-details", {
        gender: gender,
        dob: dob,
      })
      .then(function (response) {
        // console.log(response.data);
        message.success(response.data.message);
        setEduState(false);
        setState({ [e.target.name]: "" });
        props.onClick();
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response.data.errors);

        message.error(error.response.data.errors);
      });
  };
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  //   const checkFillidOrNot = () => {
  //     if (props.data.expertise.length >= 1) {
  //       // setHide(true)
  //       // setEduState(true)
  //       // setExpertExist(true)
  //       // setExpert(props.data.expertise[0].specialization)
  //       // setBrief(props.data.expertise[0].briefIntro)
  //       // setId(props.data.expertise[0].id)
  //     }
  //   };
  const remove = (id) => {
   
    props.loader(true);
    axios
      .delete(`/api/v1/personal-details/${id}`)
      .then(function (response) {
        props.loader(false);
        message.success(response.data.message);
        props.onClick();
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response.data.errors);
        props.loader(false);
        message.error(error.response.data.errors);
      });
  };

  const updates = () => {
    props.loader(true);
    axios
      .patch(`/api/v1/personal-details/update/${id}`, {
        dob: dob,
        gender: gender,
      })
      .then(function (response) {
        // console.log(response.data);
        props.loader(false);
        setDob("");
        setGender("");
        message.success(response.data.message);
        props.onClick();
        setIsEdit(false);
      })
      .catch(function (error) {
        props.loader(false);
        console.log(error);
        console.log(error.response.data.errors);

        message.error(error.response.data.errors);
      });
  };

  useEffect(() => {
    // checkFillidOrNot();
  });

  return (
    <>
      <div>
        <div className="  " style={{ width: "100%" }}>
          <div className="flex space-x-2  justify-between ">
            <h1 className=" text-[1.7rem] mt-[2rem]" id="edu">
              Personal Details
            </h1>
            <div className="flex gap-2">
              <button
                className="mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                onClick={addD} >
                  Add
              </button>

              <button
                className={`${k} mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`}
                onClick={addD} > 
                close
              </button>

            </div>
          </div>
          {edustate ? (
            <>
              <div className="grid grid-cols-2 justify-center gap-2">
                {/* <div className='w-4/5'> */}

                <Input
                  placeholder="DD/MM/YY"
                  label="Enter DOB"
                  name="dob"
                  type="date"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />

                <Dropdown
                  placeholder=""
                  data={gnder}
                  label="Gender"
                  name="gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                />
                {/* </div> */}
                {/* {
                                state.dtail == "DOB"?<>

                                <Input placeholder="DD/MM/YY"  label="Add Year" name="year" type="date" value={state.year} onChange={onChange} />
                                </>:<Dropdown placeholder=""  data={gnder} label="Gender" name="gender"   value={state.gender}
                             onChange={onChange} />
                            } */}

                <button
                  type="submit"
                  className="mt-auto px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none"
                  onClick={onSubmit}
                >
                  submit
                </button>
              </div>
            </>
          ) : null}

          {props.data.personalDetails?.map((elm, ind) => {
            return (
              <>
                <div
                  className="flex gap-2 items-center justify-between mt-7"
                  style={{ width: "100%" }}
                  key={ind}
                >
                  <div className="edu1">D.O.B:- {elm.dob}</div>
                  <div className="edu2">Gender:- {elm.gender} </div>
                  <div className="flex space-x-4">
                    {/* <button
                      className="flex my-auto  px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-emerald-400 hover:text-black border-2 border-green-900 focus:outline-none"
                      onClick={() => editA(elm.dob, elm.gender, elm.id)}
                    >
                      edit
                    </button> */}
                    <button
                      className=" px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                      onClick={() => remove(elm.id)}
                    >
                      delete
                    </button>
                  </div>
                </div>
                <hr className="facultyHr" />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default PersonalDetails;
