import React, { useState } from "react";
import Input from "../facultydashboard/formGroup/Input";
import Textarea from "../facultydashboard/formGroup/Textarea";
import AlumniFeedBackQues from "./AlumniFeedBackQues";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import Spinner from "../facultydashboard/formGroup/Spinner";

function AlumniFeedBack() {
  const intialValue = {
    name: "",
    email: "",
    dob: "",
    mobileNo: "",
    session: "",
    registrationNo: "",
    yearOfRegistration: "",
    degreeYear: "",
    yearOfAdmission: "",
    presentDesignation: "",
  };
  const [state, setState] = useState(intialValue);
  const [course, setCourse] = useState("");
  const [gender, setGender] = useState("");
  const [presentStatus, setPresentStatus] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [loader, setLoader] = useState(false);
  const [mailingAddress, setMailingAddress] = useState("");
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();
  const [questionState, setQuestionState] = useState({
    q0: "",
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
    q8: "",
  });
  const {  q0,q1, q2, q3, q4, q5, q6, q7, q8 } = questionState;
  const {
    name,
    email,
    mobileNo,
    session,
    pic,
    registrationNo,
    yearOfRegistration,
    degreeYear,
    presentDesignation,
  } = state;
  const handleChange = (name) => (event) => {
    setQuestionState({ ...questionState, [name]: event.target.value });
  };
  const feedbackStudent = () => {
    setLoader(true);
    axios
      .post("/api/v1/almuni/feedback-form", {
        almuniName: name,
        gender: gender,
        mobileNo: mobileNo,
        email: email,
        programName: course,
        regNo: registrationNo,
        YearOfReg: yearOfRegistration,
        YearOfPass: degreeYear,
        currOrg: presentDesignation,
        addressPresOrg: presentStatus,
        addressResOrg: mailingAddress,
        specAchievement: q0,
        rateCurrSyllabus: q1,
        rateQualityEdu: q2,
        rateCourContent: q3,
        rateInnovativeTeaching: q4,
        rateAcademicInitiatives: q5,
        rateRelevanceDegree: q6,
        rateMotivationSyllabus: q7,
        rateDevelopmentactivities: q8,
      })
      .then(function (response) {
        // console.log(response);
        setLoader(false)
        message.success(response.data.message)
        navigate("/thank-you");
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false)
        message.error(error.response.data.errors)
    });
  };
  const onSubmit = (e) => {
    // setState({ [i.target.name]: "" })
    e.preventDefault()

    feedbackStudent()

}
  return (
    <>
   {
    loader && <Spinner/>
   }
    <div>
      <div className="grid z-50 grid-cols-1 mt-8 gap-y-2 justify-items-center my-auto">
        <div className="bg-white  font-bold  rounded-lg w-5/6 mx-4 shadow  px-4 py-2 ">
          <h1 className="text-4xl  text-center mb-2">
            B. N. Mandal University Madhepura बी. एन. मंडल विश्वविद्यालय,
            मधेपुरा
          </h1>
          <h1 className="text-3xl  text-center mb-2">
            Alumni Feedback Form – Syllabus/ पुरातन छात्र प्रतिपुष्टी फ़ार्म –
            पाठ्यक्रम
          </h1>
          <p c>
            Directions: A score for each statement/item between 1 and 5 as:
          </p>
          (A) Excellent-5, (B) Very Good – 4 (C) Good - 3 (D) Average – 2 (E)
          Poor – 1
          <p>
            Please indicate your level of satisfaction with the following
            statement by choosing between 1 and 5.
          </p>
          <p className="font-bold">
            दिशा-निर्देश: 1 और 5 के बीच प्रत्येक कथन/मद के लिए एक अंक:
          </p>
          * उत्कृष्ट - 5, * बहुत अच्छा - 4 * अच्छा - 3 * औसत - 2 * खराब/ निम्न -
          1 कृपया 1 और 5 के बीच चयन करके निम्नलिखित कथन के साथ अपनी संतुष्टि का
          स्तर बताएं।
        </div>
        <div></div>
        <div className="bg-white tst rounded-lg w-5/6 mx-4 shadow-xl  px-4 py-2 ">
          <form
            onSubmit={onSubmit}
            className="w-5/6 grid mx-auto my-auto items-center md:grid-cols-3 gap-x-4 gap-y-2 "
          >
            <Input
              label="Alumni Name
              (पुरातन छात्र का नाम)"
              name="name"
              man="*"
              type="text"
              value={state.name}
              onChange={onChange}
              placeholder=""
            />
            <div>
              <label for="gender">
                Gender
                <span className="text-red-500 font-bold text-[1.2rem]">*</span>
              </label>
              <select
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                id="category"
                class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
              >
                <option className="" value="">
                  -----Select-----
                </option>
                <option className="" value="Male">
                  Male
                </option>
                {/* <option className="" value='1'>Admin</option> */}
                <option className="" value="Female">
                  Female
                </option>
              </select>
            </div>

            <Input
              label="Mobile No."
              man="*"
              name="mobileNo"
              type="number"
              value={state.mobileNo}
              onChange={onChange}
              placeholder=""
            />
            <Input
              label="Email"
              name="email"
              man="*"
              type="email"
              value={state.email}
              onChange={onChange}
              placeholder=""
            />

            <div>
              <label for="category">
                Course/ Program Name (पाठ्यक्रम / कार्यक्रम का नाम)
                <span className="text-red-500 font-bold text-[1.2rem]">*</span>
              </label>
              <select
                value={course}
                onChange={(e) => {
                  setCourse(e.target.value);
                }}
                id="category"
                class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
              >
                <option className="" value="">
                  -----Select-----
                </option>
                <option className="" value="PG">
                  PG
                </option>
                {/* <option className="" value='1'>Admin</option> */}
                <option className="" value="Ph.D">
                  Ph.D
                </option>
              </select>
            </div>

            {/* <Input
              label="Year of Admission(if pg is chosen)"
              name="yearOfAdmission"
              man=""
              placeholder="2016"
              type="text"
              value={state.yearOfAdmission}
              onChange={onChange}
            />
            <Input
              label="Year of Degree Awarded(if pg is chosen)"
              name="degreeYear"
              man=""
              placeholder="2018"
              type="text"
              value={state.degreeYear}
              onChange={onChange}
            /> */}
            <Input
              label="Registration No."
              name="registrationNo"
              man="*"
              type="text"
              value={state.registrationNo}
              onChange={onChange}
              placeholder=""
            />

            <Input
              label="Year of Registration"
              name="yearOfRegistration"
              man="*"
              type="text"
              value={state.yearOfRegistration}
              onChange={onChange}
              placeholder=""
            />
            <Input
              label="Year of Passing
              (उत्तीर्ण  वर्ष)"
              name="degreeYear"
              man=""
              placeholder="2018"
              type="text"
              value={state.degreeYear}
              onChange={onChange}
            />
            <Input
              label="Current Orgganization/Institution name with Designation
             (पदनाम के साथ वर्तमान संगठन/संस्था का नाम)"
              name="presentDesignation"
              man="*"
              type="text"
              value={state.presentDesignation}
              onChange={onChange}
              placeholder=""
            />

            <div className="col-span-full">
              <Textarea
                label="Full address of the present organization"
                placeholder=""
                row="4"
                value={presentStatus}
                onChange={(e) => setPresentStatus(e.target.value)}
              />
            </div>
            <div className="col-span-full">
              <Textarea
                label="Mailing Address or Residential  Address "
                placeholder=""
                row="4"
                value={mailingAddress}
                onChange={(e) => setMailingAddress(e.target.value)}
              />
            </div>

            <div className="col-span-full">
              <AlumniFeedBackQues
                handleChange={handleChange}
                value={questionState}
              />
            </div>
            <div className="col-span-full">
              <Textarea
                label="Full address of the present organization"
                placeholder=""
                row="4"
                value={suggestion}
                onChange={(e) => setSuggestion(e.target.value)}
              />
            </div>
            <div className="col-span-full">
              <div className="flex justify-center mt-2">
                <button className="mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  );
}

export default AlumniFeedBack;
