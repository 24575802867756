const data = [
    {
      NameofTeachers: "Dr. C.K. Yadav",
      PapersPublished: "3",
      ScheduledAttached: "29",
      Awarded: "4",
      SeminarAttended: "5",
      PhDThesis:"3"
    },
    {
      NameofTeachers: "Dr. Kaushalendra Kr.",
      PapersPublished: "2",
      ScheduledAttached: "",
      Awarded: "2",
      SeminarAttended: "2",
      PhDThesis:"2"
    },
    {
      NameofTeachers: "Dr. M.K. Ghosh",
      PapersPublished: "0",
      ScheduledAttached: "24",
      Awarded: "3",
      SeminarAttended: "3",
      PhDThesis:"0"
    },
    {
      NameofTeachers: "Dr. Naresh Kr",
      PapersPublished: "9",
      ScheduledAttached: "18",
      Awarded: "6",
      SeminarAttended: "13",
      PhDThesis:"3"
    },
    {
      NameofTeachers: "Dr. Arun Kr. Yadav",
      PapersPublished: "3",
      ScheduledAttached: "14",
      Awarded: "5",
      SeminarAttended: "2",
      PhDThesis:"2"
    },
    {
      NameofTeachers: "Dr. Ashok Kr. Yadav",
      PapersPublished: "3",
      ScheduledAttached: "9",
      Awarded: "",
      SeminarAttended: "3",
      PhDThesis:"3"
    },
    {
      NameofTeachers: "Dr. Anil Kr.",
      PapersPublished: "3",
      ScheduledAttached: "5",
      Awarded: "4",
      SeminarAttended: "5",
      PhDThesis:"1"
    },]

   export default data