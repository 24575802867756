import React, { useState, useEffect } from 'react'
import Dropdown from '../formGroup/Dropdown'
import Input from '../formGroup/Input'
import { message } from 'antd'
import axios from 'axios'
import { Link } from 'react-router-dom'
function StudyMaterials(props) {
  const [edustate, setEduState] = useState(false)
  const initialState = {
    topicname: "",
    semester: "",
    uRNo: "",
    session: "",
    status: "",
  }
  const sms = ["---Select Semester----", "Semester 1", "Semester 2", "Semester 3", "Semester 4","Phd", "Misc"]
  const [editState, setEditState] = useState(false)
  const [state, setState] = useState(initialState)
  const [tde, setTde] = useState([])
  const onChange = (e) => {

    setState({ ...state, [e.target.name]: e.target.value })
  }
  const addmore = (e) => {
    setTde([...tde, { "topicname": state.topicname, "semester": state.semester, "uRNo": state.uRNo, "session": state.session, "journal": state.journal, "status": state.status }]);
    setEduState(false)
    setState({ [e.target.name]: "" })
  }
  const removeBatch = (id) => {
    const updateList = tde.filter((elm, ind) => {
      return ind !== id;
    });
    setTde(updateList)
  }
  const editA = () => {
    setEditState(true)

  }
  const [hdn, setHdn] = useState(false)

  const [k, setK] = useState("hidden")
  const addD = () => {
    setEduState(!edustate)
    setHdn(!hdn)
    if (hdn === false) {
      setK("block")

    }
    else {
      setK("hidden")
    }
  }
  const [semester, setSemester] = useState('')
  const [topic, setTopic] = useState('')
  const [pdf, setPdf] = useState({})
  // console.log(pdf)
  const [author, setAuthor] = useState(props.data.name)
  // console.log(author)
  const onSubmit = () => {
    // e.preventDefault()
    const formData = new FormData();
    formData.append('pdf', pdf);
    formData.append('semester', semester);
    formData.append('topic', topic);
    formData.append('author', author);
    props.loader(true)
    axios.post('/api/v1/upload', formData, {
      semester,
      topic,
      pdf: pdf,
      author
    })
      .then(function (response) {
        props.loader(false)
        message.success(response.data.message)
        props.onClick()
        studyMaterials()
        // setHide(true)
        // setEduState(true)
        // setExpertExist(true)
      })
      .catch(function (error) {
        props.loader(false)
        message.error(error.response.data.errors)

      });
  }

  const [study, setStudy] = useState([])
  // console.log(study)
  const studyMaterials = () => {
    props.loader(true)
    axios.get('/api/v1/find-file')
      .then(function (response) {
        // handle success
        setStudy(response.data)
        props.loader(false)
        // console.log(response.data.userList);
      })
      .catch(function (error) {
        // handle error
        props.loader(false)
        console.log(error);
      })
  }
  const studyMaterialsDownload = (fileName) => {

    props.loader(true)
    axios.get(`/api/v1/download-study-material/${fileName}`)
      .then(function (response) {
        // handle success

        props.loader(false)
        // console.log(response.data.userList);
      })
      .catch(function (error) {
        // handle error
        props.loader(false)
        console.log(error);
      })
  }
  const studyMaterialsDelete = (id) => {

    props.loader(true)
    axios.delete(`/api/v1/studymaterial/delete/${id}`)
      .then(function (response) {
        // handle success

        props.loader(false)
        message.success(response.data.message)
        studyMaterials()
        // console.log(response.data.userList);
      })
      .catch(function (error) {
        // handle error
        props.loader(false)
        message.error(error.response.data.message)
        console.log(error);
      })
  }


  useEffect(() => {
    studyMaterials()
  }, [])
  return (
    <div>
      <div className=' '>
        <div className='   ' style={{ width: '100%' }}>
          <div className='flex space-x-2 justify-between '>

            <h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Study Materials</h1>
            <div className='gap-2'>


              <button className='mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none' onClick={addD} >Add </button>


            </div>
          </div>

          {edustate ? <>
            <div className='grid grid-cols-2   space-x-2'>
              <div className='w-full my-auto'>

                <Dropdown data={sms} label="semester" name="semester" value={semester} onChange={(e) => { setSemester(e.target.value) }} />
              </div>
              <Input label="Topic name" type="text" name="topicname" value={topic} onChange={(e) => { setTopic(e.target.value) }} />
              <div className=' col-span-full w-full my-auto'>
                <input accept='.pdf' onChange={(e) => { setPdf(e.target.files[0]) }} type="file" label="PDF only(20mb max)" />

              </div>




              <button className='mt-auto px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none' onClick={onSubmit} >Upload</button>
              <button className={`${k} mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`} onClick={addD}>close</button>
            </div>



          </> : null

          }
          <div class="overflow-x-auto mt-2 rounded-lg border border-gray-200">
            <table class="min-w-full divide-y-2 divide-gray-200 text-sm">
              <thead>
                <tr>
                  <th
                    class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
                  >
                    Semester
                  </th>
                  <th
                    class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
                  >
                    Name of topic
                  </th>

                  <th
                    class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
                  >
                    File

                  </th>
                  <th
                    class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
                  >
                    Date of upload
                  </th>

                </tr>
              </thead>


              {
                study.toReversed().map((a, ind) => {

                  return (
                    <>
                      {
                        props.data._id === a.authorId &&
                        <tbody class="divide-y divide-gray-200" key={ind}>
                          <tr>
                            <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">{a.semester}</td>
                            <td class="whitespace-nowrap  border px-4 py-2 font-medium text-gray-900">
                              {a.topicName}
                            </td>
                            <td>
                              <center>
                                <Link to={`/api/v1/download-study-material/${a.fileName}`} target="_blank" class="whitespace-nowrap border px-4 py-2 text-gray-700"
                                  onClick={() => { studyMaterialsDownload(a.fileName) }}

                                >Download</Link>
                              </center>
                            </td>

                            <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">{new Date(a.Date).getDate() + " / " + new Date(a.Date).getMonth() + " / " + new Date(a.Date).getFullYear()}</td>

                            <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">
                              <button className=' px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none' onClick={() => studyMaterialsDelete(a._id)}>delete</button>
                            </td>
                          </tr>
                        </tbody>
                      }
                    </>
                  )
                })
              }


            </table>



          </div>
        </div>

      </div>

    </div>



  )
}

export default StudyMaterials