import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  MdOutlineDashboard,
  MdOutlineSmsFailed,
  MdAddCircleOutline,
} from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { AiOutlineUser, AiOutlineDownload } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GrCompliance, GrDocumentVerified } from "react-icons/gr";
import { BiCommentEdit } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import { CgWebsite } from "react-icons/cg";
import { MdOutlineReportGmailerrorred } from "react-icons/md";
import { TfiGallery } from "react-icons/tfi";
import { LuVerified } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import { VscFeedback } from "react-icons/vsc";
import { RiFeedbackLine } from "react-icons/ri";

function Sidebar() {
  const navigate = useNavigate();
  const [user, setuser] = useState(false);
  const [contentUpdate, setcontentUpdate] = useState(false);
  const [siteSetting, setsiteSetting] = useState(false);
  const [alumniSubMenu, setAlumniSubMenu] = useState(false);
  const [feedbackSubmenu, setFeedbackSubMenu] = useState(false);
  // console.log(studentData)

  useEffect(() => {}, []);
  const userMenu = [
    {
      name: "Add user",
      link: "/admin/dashboard/adduser",
      icon: MdAddCircleOutline,
    },
    {
      name: "Edit/View user",
      link: "/admin/dashboard/viewuser",
      icon: BiCommentEdit,
    },
  ];
  const contentUpdateMenu = [
    {
      name: "Slider Images",
      link: "/admin/dashboard/sliderchange",
      icon: GrCompliance,
    },
    {
      name: "About dept Content",
      link: "/admin/dashboard/changeabout-landing",
      icon: MdOutlineSmsFailed,
    },
    {
      name: "Add Course & Syllabus",
      link: "/admin/dashboard/add-syllabus",
      icon: MdOutlineSmsFailed,
    },
    {
      name: "Add Announcement & Notice",
      link: "/admin/dashboard/addannoucement",
      icon: MdOutlineSmsFailed,
    },
    {
      name: "Add News & Events",
      link: "/admin/dashboard/addnotice",
      icon: MdOutlineSmsFailed,
    },
    {
      name: "Add SSR",
      link: "/admin/dashboard/upload-ssr",
      icon: MdOutlineSmsFailed,
    },
  ];
  const siteSettingMenu = [
    {
      name: "Site Title & Logo",
      link: "/admin/dashboard/sitetitle",
      icon: MdOutlineSmsFailed,
    },
    {
      name: "Site Footer Data",
      link: "/admin/dashboard/sitefooter",
      icon: MdOutlineSmsFailed,
    },
  ];
  const alumniMenu = [
    {
      name: "New Request",
      link: "/admin/dashboard/new-request",
      icon: MdAddCircleOutline,
    },
    {
      name: "Verified Alumni",
      link: "/admin/dashboard/accepted-list",
      icon: LuVerified,
    },
    {
      name: "Rejected Alumni",
      link: "/admin/dashboard/rejected-list",
      icon: RxCrossCircled,
    },
    {
      name: "Placed Alumni",
      link: "/admin/dashboard/placement-list",
      icon: LuVerified,
    },
  ];
  const feedbackMenu = [
    {
      name: "Alumni Feedback",
      link: "/admin/dashboard/alumni-feedback",
      icon: RiFeedbackLine,
    },
    {
      name: "Student Feedback",
      link: "/admin/dashboard/student-feedback",
      icon: RiFeedbackLine,
    },
    {
      name: "Teacher Feedback",
      link: "/admin/dashboard/teacher-feedback",
      icon: RiFeedbackLine,
    },
  ];
  const menus = [
    { name: "Home", link: "/admin/dashboard", icon: MdOutlineDashboard },
    {
      name: "User",
      link: "",
      icon: AiOutlineUser,
      submenu: true,
      submenues: userMenu,
      state: user,
      setState: setuser,
    },
    {
      name: "Add Student",
      link: "/dashboard/addstudents",
      icon: AiOutlineUser,
    },
    {
      name: "Add Research Scholar",
      link: "/add/research",
      icon: AiOutlineUser,
    },
    {
      name: "Add Research Topic",
      link: "/admin/dashboard/add-research-topic",
      icon: AiOutlineUser,
    },
    { name: "Add Staff", link: "/addstaff", icon: AiOutlineUser },
    {
      name: "Content Update",
      link: "#",
      icon: TbReportAnalytics,
      submenu: true,
      submenues: contentUpdateMenu,
      state: contentUpdate,
      setState: setcontentUpdate,
    },
    {
      name: "Site Setting",
      link: "#",
      icon: CgWebsite,
      submenu: true,
      submenues: siteSettingMenu,
      state: siteSetting,
      setState: setsiteSetting,
    },
    {
      name: "Alumni",
      link: "",
      icon: AiOutlineUser,
      submenu: true,
      submenues: alumniMenu,
      state: alumniSubMenu,
      setState: setAlumniSubMenu,
    },
    {
      name: "Download",
      link: "/admin/dashboard/download",
      icon: AiOutlineDownload,
    },
    { name: "Gallery", link: "/admin/dashboard/addgallery", icon: TfiGallery },
    {
      name: "Feedback",
      link: "",
      icon: VscFeedback,
      submenu: true,
      submenues: feedbackMenu,
      state: feedbackSubmenu,
      setState: setFeedbackSubMenu,
    },
    {
      name: "Report an Error",
      link: "/admin/dashboard/report-error",
      icon: MdOutlineReportGmailerrorred,
    },
  ];
  const [open, setOpen] = useState(true);
  return (
    <div
      className={`bg-white min-h-screen ${
        open ? "w-80" : "w-16"
      } duration-500 text-gray-900 px-4`}
    >
      <div className="py-5 flex justify-end">
        <GiHamburgerMenu
          size={26}
          className="cursor-pointer"
          onClick={() => setOpen(!open)}
        />
      </div>
      <div className="mt-4 flex flex-col gap-4 relative">
        {menus?.map((menu, i) => (
          <Link
            to={menu?.link}
            key={i}
            className={` ${
              menu?.margin && "mt-5"
            } group  items-center text-sm  gap-3.5 font-medium p-2  rounded-md`}
          >
            {menu.submenu ? (
              <>
                <div className="flex items-center justify-start text-sm  gap-3.5 font-medium">
                  <div>{React.createElement(menu?.icon, { size: "20" })}</div>
                  <div
                    onClick={() => {
                      menu.setState(!menu.state);
                    }}
                    style={{
                      transitionDelay: `${i + 3}00ms`,
                    }}
                    className={`flex w-full   justify-end items-center  whitespace-pre duration-500 gap-[7rem] ${
                      !open && "opacity-0 translate-x-28 overflow-hidden"
                    }`}
                  >
                    <h2 className="flex-1">{menu?.name}</h2>
                    <svg
                      className="w-6 items-end h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
                {menu.state && (
                  <>
                    {menu.submenues.map((e, i) => {
                      return (
                        <>
                          <ul className=" py-2 space-y-1" key={i + 5}>
                            <li
                              style={{
                                transitionDelay: `${i + 3}00ms`,
                              }}
                              className={`flex items-center whitespace-pre duration-500 ${
                                !open &&
                                "opacity-0 translate-x-28 overflow-hidden"
                              }`}
                            >
                              <Link
                                to={e.link}
                                className="flex items-center w-full p-2 gap-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 hover:text-black pl-11"
                              >
                                <div>
                                  {React.createElement(e?.icon, { size: "20" })}
                                </div>

                                {e.name}
                              </Link>
                            </li>
                          </ul>
                        </>
                      );
                    })}
                  </>
                )}
              </>
            ) : (
              <div className="flex items-center text-sm  gap-3.5 font-medium">
                <div>{React.createElement(menu?.icon, { size: "20" })}</div>

                <h2
                  style={{
                    transitionDelay: `${i + 3}00ms`,
                  }}
                  className={` whitespace-pre duration-500 ${
                    !open && "opacity-0 translate-x-28 overflow-hidden"
                  }`}
                >
                  {menu?.name}
                </h2>
              </div>
            )}
            <h2
              className={`${
                open && "hidden"
              } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              {menu?.name}
            </h2>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Sidebar;
