import React, { useState } from "react";
import { Alert, Dropdown, Input } from "../../formGroup/Index";
import axios from "axios";
export default function StudentEdit(props) {
  console.log(props.editFaculty);
  const [almuniName, setAlmuniName] = useState(props.editFaculty.almuniName);
  const [photo, setPhoto] = useState(props.editFaculty.photo);

  const [email, setEmail] = useState(props.editFaculty.email);
  const [mobileNo, setMobileNo] = useState(props.editFaculty.mobileNo);
  const [dob, setDob] = useState(props.editFaculty.dob);
  const [programName, setProgramName] = useState(props.editFaculty.programName);
  const [session, setSession] = useState(props.editFaculty.session);
  const [yearOfAdmission, setYearOfAdmission] = useState(props.editFaculty.yearOfAdmission);
  const [gender, setGender] = useState(props.editFaculty.gender);
  console.log( "props ",props)
  const editUser = () => {
    axios
      .put(`/api/v1/student-feedback-data/${props.editFaculty.id}`, {
        almuniName,
        gender,
        mobileNo,
        email,

      })
      .then(function (response) {
        // console.log(response);
        alert(response.data.message);
        props.setShowModal(false);
        props.updateData();
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.errors);
      });
  };

  return (
    <>
      {props.showModal ? (
        <>
          <div className="justify-center  items-center flex  overflow-y-auto overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-10/12 h-[90%] my-6 mx-auto   ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start gap-2   justify-between p-5 border-b border-solid border-slate-200 rounded-t">

                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="flow-root">

  <dl className="m-3 divide-y px-12 grid grid-cols-2 gap-4 divide-gray-100 text-sm">


    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Name</dt>
      <dd className="text-gray-700 sm:col-span-2">{props.editFaculty.studentName}</dd>
    </div>
    <div
      className="grid grid-cols-1  gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium  text-gray-900">Gender</dt>
      <dd className="text-gray-700 sm:col-span-2">{props.editFaculty.gender}</dd>

    </div>


    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Mobile Number</dt>
      <dd className="text-gray-700 sm:col-span-2">{props.editFaculty.mobileNo}</dd>
    </div>

    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Email</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.email}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Program</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.programName}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Registration No</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.regNo}
      </dd>
    </div>



    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Year of Registration</dt>
      <dd className="text-gray-700 sm:col-span-2">
{props.editFaculty.YearOfReg}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">
Year of Admission</dt>
      <dd className="text-gray-700 sm:col-span-2">
{props.editFaculty.admissionYear}
      </dd>
    </div>


    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium  text-gray-900">Mailing Address</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.addressResOrg}
      </dd>
    </div>

    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-4 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-2 text-gray-900">Full address of the present organization</dt>
      <dd className="text-gray-700 sm:col-span-2">
{props.editFaculty.addressResOrg}
      </dd>
    </div>


    <div className="col-span-full  divide-y font-bold gap-4 divide-gray-100 text-sm">
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">Special Achievement (STET, CTET, SLET, NET, ICSSR, GET/ others)<br/> विशेष उपलब्धि (STET, CTET, SLET, NET, ICSSR, GET/ अन्य) --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.specAchievement}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">1. Curriculum and Syllabi of the Courses is interesting./ पाठ्यचर्या और पाठ्यक्रमों का पाठ्य विवरण रुचिकर/दिलचस्प है --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.q1}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">2. Curriculum and Syllabus are need based./ पाठ्यचर्या और पाठ्यक्रम आवश्यकता आधारित है/ --</dt>
      <dd className="text-gray-700 justify-items-end sm:col-span-2">
        {props.editFaculty.q2}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">3. Course delivery by faculty members in the class properly./ कक्षा में संकाय सदस्यों/शिक्षकों द्वारा पाठ्यक्रम ठीक से प्रेषित/प्रस्तुतीकरण किया गया / --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.q3}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">4. Used of teaching aids and ICT in the class by faculty members to facilitate teaching./ बेहतर शिक्षण हेतु कक्षा में शिक्षण सहायक सामग्री और आई0सी0टी0 का उपयोग शिक्षकों द्वारा किया गया/ --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.q4}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">5. Opportunities are provided for students to participate in internships, field visits or research activities./ छात्रों के लिए इंटर्नशिप, फील्ड विजिट या शोध गतिविधियों में भाग लेने का अवसर दिए गए। --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.q5}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">6. Opportunities for out of class room learning (guest lectures, seminars, workshop, value added programmes, conferences, competitions etc.)./ कक्षा के बाहर सीखने के अवसर (अतिथि व्याख्यान, सेमिनार, कार्यशाला, मूल्य वर्धित कार्यक्रम, सम्मेलन, प्रतियोगिताएं आदि) दिए गए/ --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.q6}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">7. There is fairness in the evaluation procedures (quiz, assignments etc.)./ मूल्यांकन प्रक्रियाओं (प्रश्नोत्तरी, असाइनमेंट आदि) में निष्पक्षता है/  --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.q7}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">8. Exam schedule and result announced on time./ परीक्षा कार्यक्रम और परिणाम की समय पर घोषणा की गई --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.q8}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">9. you recommend others to take admission in this program./ आप दूसरों को इस कार्यक्रम में प्रवेश लेने की सलाह देते हैं। **--</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.q9}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">10. Overall a great learning experience. /कुल मिलाकर सीखने का अनुभव बहुत अच्छा रहा/ ** --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.q10}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">suggestion</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.suggestion}
      </dd>
    </div>
    </div>
  </dl>
</div>
                {/*footer*/}
                <div className="flex items-center justify-end gap-x-4 p-6 border-t border-solid border-slate-200 rounded-b">
                  <div className="flex-1">


                  {/* <button
                    className="text-red-500  background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => props.setShowModal(false)}
                  >
                    Close
                  </button> */}
                  </div>
                  <div className="">
                  <button
                    className="bg-red-500 mx-4 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => props.setShowModal(false)}
                  >
                    Close
                  </button>
                  {/* <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => editUser()}
                    // onClick={() => props.setShowModal(false)}
                  >
                    Accept
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
