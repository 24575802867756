import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import Addstaff from '../Addstaff/Addstaff'
import axios from 'axios';
import Addstafftable from './Addstafftable';

function AddstaffPreview() {
  const [data, setData] = useState([]);
  // console.log(data);
  const staff = () => {
    axios
      .get("/api/v1/get/all/staff")
      .then(function (response) {
        // handle success
        //console.log(response.data);
        setData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  useEffect(() => {
    staff();
  }, []);
    return (
        <div>
            <div style={{ width: '100%',  }}>
        <div>
            <div style={{ width: '100%', height:'100vh', overflow:'scroll'}}>
                <Navbar />
            <div className='flex bg-[#F4F4F5]' style={{ width: '100%' }}>
              <Sidebar />
            <div className='' style={{ width: '100%' }}>
              <Addstaff viewAllStaff={staff}/>
              <Addstafftable data={data} updateData={staff}/>
            </div>

            </div>



            </div>
        </div>
    </div>
        </div>
      )
}

export default AddstaffPreview