import React, { useState } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import uni10 from '../../assests/uni10.jpeg'
import uni11 from '../../assests/uni11.jpeg'
import uni4 from '../../assests/uni4.jpeg'
import uni9 from '../../assests/uni9.jpeg'



function Slider() {
  const slides = [
    {
      url: uni11,
    },
    {
      url: uni10,
    },
    {
      url: uni4,
    },

   
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className='md:max-w-[1400px]   md:h-[580px] m-auto w-full  relative group'>
      <div
        style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
        className='w-full  h-full  bg-center bg-contain  md:bg-cover bg-no-repeat duration-500'
      >
        {
            currentIndex==0 ?
            <>
            <div className='flex h-screen bg-gradient-to-r from-gray-600/95 to-green-300/25 text-black '>
            <div className='m-auto font-mono font-semibold '>
            <h1 className='text-xl '>Welcome to</h1> 
            <h1 className='text-3xl  '>Phycis Department</h1> 
            <button type="button" className="px-8 py-1 rounded-lg font-semibold border  border-red-600 text-black-800">About Us</button>
            </div>
            </div>
            </>
            :null
        
        }
         {
            currentIndex==1 ?
            <>
            
            <div className='flex  h-screen bg-gradient-to-r from-gray-900/95 to-green-300/25 text-black '>
            <div className='m-auto font-mono font-semibold '>
            <button type="button" className="px-8 py-1 rounded-lg font-semibold border  border-red-600 text-black-800">About Us</button>
            
            <h1 className='text-xl '>Lorem ipsum dolor </h1> 
            <h1 className='text-3xl  '>Phycis Department</h1> 

            </div>
            </div>
            </>
            
            :null
        
        }
        {
            currentIndex==2 ?
            <>
            
            <div className='flex  h-screen bg-gradient-to-r from-gray-900/95 to-green-300/25 text-black '>
            <div className='m-auto font-mono font-semibold '>
            <h1 className='text-xl'>make to</h1> 
            <h1 className='text-3xl '>Lorem,  </h1> 

            </div>
            </div>
            </>
            
            :null
        
        }
      </div>
      {/* Left Arrow */}
      <div className=' block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black text-white cursor-pointer'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className='block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black text-white cursor-pointer'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      <div className='flex top-4 justify-center py-2'>
        {slides.map((slide, slideIndex) => (
            <>
            
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className='text-2xl cursor-pointer'
            
            >
            
          </div>
              </>
        ))}
      </div>
    </div>
  );
}

export default Slider;