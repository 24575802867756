import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import axios from "axios";
const PrivateAdmin = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = async() => {
    // const userToken = localStorage.getItem('user-token');
    // if (!userToken || userToken === 'undefined') {
    //   setIsLoggedIn(false);
    //   return navigate('/auth/login');
    // }
    // setIsLoggedIn(true);
    try {
      const response = await axios.get('/api/v1/profile');
    if(response.data.user.role === 1){
      console.log('isAdin')
      setIsLoggedIn(true)
    }
    // if(response.data.user.role === 2){
      
    //   setIsLoggedIn(true)
    // }
    else{
      setIsLoggedIn(false)
      return navigate('/login');
    }
        
    //   console.log(axios.response.data)
    } catch (error) {
          setIsLoggedIn(false);
      return navigate('/login');
    }
  }
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  return (
    <React.Fragment>
      {
        isLoggedIn ? props.children : null
      }
    </React.Fragment>
  );
}
export default PrivateAdmin;