import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {message} from 'antd'
import Spinner from "../../page/facultydashboard/formGroup/Spinner";
function SyllabusTable(props) {
  const [loader, setLoader]=useState(false)
  const [statusBg, setStatusBg] = useState("");
  const setAction = (action,id)=>{
    setLoader(true)
    axios.patch(`/api/v1/syllabus/update/${action ? "inactive":"active"}/${id}`)
    .then(function (response) {
      setLoader(false)
      message.success(response.data.message);
      props.updateData()
      alert('updated successfully')
    })
    .catch(function (error) {
      console.log(error);
      setLoader(false)
      message.error('Something went wrong');
    });
  }
  return (
    <div>
      {loader && <Spinner />}

      {/* table start */}
      <div className="relative overflow-x-auto mt-2">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Course Name
              </th>
              <th scope="col" className="px-6 py-3">
                Title Of File
              </th>
              <th scope="col" className="px-6 py-3">
                Download
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {props.data?.map((e, i) => {
              return (
                <>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      {e.class}
                    </th>
                    <td className="px-6 py-4">{e.title}</td>
                    <td className="px-6 py-4">
                      <Link
                        to={`/api/v1/download-syllabus/${e.fileName}`}
                        target="_blank"
                      >
                        Download
                      </Link>
                    </td>
                    <td className="px-6 py-4">
                      {/* action button */}

                      <div className="my-auto">
                        <select
                          id="category"
                          value={e.publish}
                          onChange={()=>{setAction(e.publish, e._id)}}
                          class={`w-max h-9 mt-2 rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-gray-100`}
                        >
                          <option className="" value="">
                            -----Status-----
                          </option>
                          <option className="" value={true} >
                            Published
                          </option>
                          <option className="" value={false}>
                            Unpublished
                          </option>
                        </select>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* table end */}
    </div>
  );
}

export default SyllabusTable;
