import React from 'react'

function ResearchProject(props) {
  console.log(props)
  return (
    <div>
      <div className='  ' style={{ width: '100%' }}>

<h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Research Projects</h1>
{props.data.researchProj?.map((p, i)=>{
  return(
    <>
    <div className='flex  items-center justify-between mt-7' style={{ width: '100%' }}>
  <div className='edu1'>{p.title} ({p.role})


  </div>
  <div className='edu2'>{p.startYear} {p.endYear}</div>
</div>
<hr className='facultyHr' />

    </>
  )
})}



</div>
    </div>
  )
}

export default ResearchProject
