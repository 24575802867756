import React from 'react'
import { Link } from 'react-router-dom'
import error from '../assests/error.jpg'
function FourOFourPage() {
    return (
        <div>
            {/* <div class={`grid h-screen px-4  bg-cover place-content-center`} style={{backgroundImage:`url(${error})`,backgroundSize:'cover',height:'100vh',padding:'5rem'}}>
            </div> */}
            <img className='p-[5rem 0rem] h-screen w-full' src={error} alt='error'/>

        </div>
    )
}



export default FourOFourPage