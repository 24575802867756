import React,{useState,useEffect} from 'react'
import ImgGallery from '../../page/landing/ImgGallery'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import Gallery from './Gallery'
import axios from 'axios'

function GalleryPreview() {
  const [data,setData] = useState([])
  const getGalleryData = ()=>{
    axios.get('/api/v1/find-file-galley')
  .then(function (response) {
    // handle success
    // console.log(response);
    setData(response.data)

  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  }
  useEffect(()=>{
    getGalleryData()
  },[])
  return (
    <div>
        <div style={{ width: '100%',  }}>
    <div>
        <div style={{ width: '100%', height:'100vh', overflow:'scroll'}}>
            <Navbar />
        <div className='flex bg-[#F4F4F5]' style={{ width: '100%' }}>
          <Sidebar />
        <div className='' style={{ width: '100%' }}>
        <Gallery data={data} onClick={getGalleryData}/>

        </div>
        </div>



        </div>
    </div>
    </div>

    </div>
  )
}

export default GalleryPreview