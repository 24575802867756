import axios, { useEffect } from "axios";
import React, { useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Dropdown from "../../formGroup/Dropdown";
import Input from "../../formGroup/Input";
import Spinner from "../../page/facultydashboard/formGroup/Spinner";
import { message } from "antd";
const datas = ["-----Select---", "PG", "PAT", "PHD"];
function Syllabus(props) {
  const [data, setData] = React.useState({});
  const [loader, setLoader] = useState(false);
  const [courses, setCourses] = useState("");
  const [titleOfFile, settitleOfFile] = useState("");
  const [pdfUpload, setpdfUpload] = useState("");

  // console.log(courses,titleOfFile,pdfUpload)

  const updateSylabous = (event) => {
    event.preventDefault();
    if (!courses || !titleOfFile || !pdfUpload) {
      message.error("All Field are required");
    } else {
      const formData = new FormData();
      formData.append("pdf", pdfUpload);
      formData.append("title", titleOfFile);
      formData.append("classes", courses);
      setLoader(true);

      axios
        .post(`/api/v1/syllabus/upload`, formData)
        .then(function (response) {
          setLoader(false);
          message.success(response.data.message);
          props.updateData();
        })
        .catch(function (error) {
          console.log(error);
          setLoader(false);
          message.error(error.response.data.errors);
        });
    }
  };

  // get data
  const siteData = () => {
    setLoader(true);
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setLoader(false);
        setData(response.data[0]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoader(false);
      });
  };

  React.useEffect(() => {
    siteData();
  }, []);
  return (
    <div>
      {loader && <Spinner />}
      <Breadcrumbs
        page="Syllabus"
        pagelink="#"
        sublink="#"
        subpage={"Content Page "}
      />
      <div className="grid gap-2 rounded-md mx-4 px-8 py-4 bg-white sm:grid-cols-2 w-2/4 my-auto">
        <div className="col-span-full">
          <div>
            <label
              for="category"
              class={`block mb-2 text-sm font-medium text-gray-900  `}
            >
              Course
              <span className="text-red-500 font-bold text-[1.2rem]">*</span>
            </label>
            <select
              value={courses}
              onChange={(e) => {
                setCourses(e.target.value);
              }}
              id="category"
              class={`w-full h-9 mt-2 rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
            >
              <option className="" value="">
                -----Select-----
              </option>
              <option className="" value="PG">
                PG
              </option>
              <option className="" value="PAT">
                PAT
              </option>

              <option className="" value="PHD">
                PHD
              </option>
            </select>
          </div>
        </div>

        <div className="col-span-full">
          <div className="">
            <label
              className={`block mb-2 text-sm font-medium text-gray-900   `}
            >
              Title Of File
              <span className="text-red-500 font-bold text-[1.2rem]">*</span>
            </label>
            <input
              type="text"
              // multiple={multiple}
              // name={name}
              // defaultValue={defaultValue}
              value={titleOfFile}
              onChange={(e) => {
                settitleOfFile(e.target.value);
              }}
              className={`w-full h-9 mt-2 rounded-sm border focus:border-gray-700 p-3 text-sm focus:outline-none focus:bg-white bg-gray-200`}
              placeholder=""
              // required='true'
            />
          </div>
        </div>
        <div className="">
          <label
            className={`block mb-2 text-sm font-medium text-gray-900   `}
          >
            Upload PDF
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <input
            type="file"
            // multiple={multiple}
            // name={name}
            // defaultValue={defaultValue}
            accept=".pdf"
            // value={pdfUpload}
            onChange={(e) => {
              setpdfUpload(e.target.files[0]);
            }}
            className={`w-full mt-2 rounded-sm border focus:border-gray-700 p-3 text-sm focus:outline-none focus:bg-white bg-gray-200`}
            placeholder=""
            // required='true'
          />
        </div>
        {/* action button *

        <div className="my-auto">
          <div className="flex gap-2">
            <label
              for="AcceptConditions"
              class="relative h-6 w-12 cursor-pointer"
            >
              <input
                type="checkbox"
                id="AcceptConditions"
                class="peer sr-only"
              />

              <span class="absolute inset-0 rounded-full bg-gray-300 transition peer-checked:bg-green-500"></span>

              <div></div>
              <span class="absolute inset-0 m-1 h-4 w-4 rounded-full bg-white transition peer-checked:translate-x-6"></span>
            </label>
            <h1>Publish</h1>
          </div>
          </div>*/}
          <div className="sm:hidden"></div>
        <div className="my-auto">
          <h1 className="opacity-0 mt-1">k</h1>
          <button
            className="inline-block mt-1  px-4 py-2 rounded border border-[#273272]   text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
            onClick={updateSylabous}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
}

export default Syllabus;
