import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import Spinner from "../../page/facultydashboard/formGroup/Spinner";
function SSRTable(props) {
  const [loader, setLoader] = useState(false);
  return (
    <div>
      {loader && <Spinner />}

      {/* table start */}
      <div className="relative overflow-x-auto mt-2">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Criteria
              </th>
              <th scope="col" className="px-6 py-3">
                Title Of File
              </th>
              <th scope="col" className="px-6 py-3">
                View
              </th>
            </tr>
          </thead>
          <tbody>
            {props.data?.map((e, i) => {
              return (
                <>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      Criteria 1
                    </th>
                    <td className="px-6 py-4">{e.criteria1}</td>
                    <td className="px-6 py-4">
                      <Link to={`/api/v1/naac/${e.criteria1}`} target="_blank">
                        View
                      </Link>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      Criteria 2
                    </th>
                    <td className="px-6 py-4">{e.criteria2}</td>
                    <td className="px-6 py-4">
                      <Link to={`/api/v1/naac/${e.criteria2}`} target="_blank">
                        View
                      </Link>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      Criteria 3
                    </th>
                    <td className="px-6 py-4">{e.criteria3}</td>
                    <td className="px-6 py-4">
                      <Link to={`/api/v1/naac//${e.criteria3}`} target="_blank">
                        View
                      </Link>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      Criteria 4
                    </th>
                    <td className="px-6 py-4">{e.criteria4}</td>
                    <td className="px-6 py-4">
                      <Link to={`/api/v1/naac/${e.criteria4}`} target="_blank">
                        View
                      </Link>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      Criteria 5
                    </th>
                    <td className="px-6 py-4">{e.criteria5}</td>
                    <td className="px-6 py-4">
                      <Link to={`/api/v1/naac//${e.criteria5}`} target="_blank">
                        View
                      </Link>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      Criteria 6
                    </th>
                    <td className="px-6 py-4">{e.criteria6}</td>
                    <td className="px-6 py-4">
                      <Link to={`/api/v1/naac//${e.criteria6}`} target="_blank">
                        View
                      </Link>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      Criteria 7
                    </th>
                    <td className="px-6 py-4">{e.criteria7}</td>
                    <td className="px-6 py-4">
                      <Link to={`/api/v1/naac/${e.criteria7}`} target="_blank">
                        View
                      </Link>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* table end */}
    </div>
  );
}

export default SSRTable;
