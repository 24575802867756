import React, { useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import { Input } from '../../formGroup/Index'
import axios from 'axios'
import { Widget } from "@uploadcare/react-widget";
import { message } from 'antd'
import Spinner from '../../page/facultydashboard/formGroup/Spinner';
function Addstaff(props) {
    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [dob, setDob] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [emailId, setEmailId] = useState("");
    const [loader, setLoader] = useState(false)
    // image upload
    const [uploadedFile, setUploadedFile] = useState("");
    const [previewPhoto, setPreviewPhoto] = useState(null);



    // const handleSubmit = (event) => {
    //     event.preventDefault();

    // };


    const addstaff = (event) => {
        event.preventDefault();
        setLoader(true)
        axios.post('/api/v1/api/staff/registration', {
            name: name,
            position: position,
            dob: dob,
            mobileNo: mobileNo,
            emailId: emailId,
            profilePic: uploadedFile
        })
            .then(function (response) {
                setLoader(false)
                message.success(response.data.message);
                setName("")
                setEmailId("")
                setDob("")
                setMobileNo("")
                setPosition("")
                setUploadedFile("")
                props.viewAllStaff()
            })
            .catch(function (error) {
                setLoader(false)
                console.log(error.response.data.errors);
                message.error(error.response.data.errors);

            });

    }


    return (
        <>
            {
                loader && <Spinner />
            }

            <Breadcrumbs page="Add Staff" pagelink="/admin/dashboard/addstaff" sublink="/admin/dashboard" subpage="Dashboard" />

            <div className='bg-white rounded-lg w-5/6 mx-4 shadow-xl  px-4 py-2 '>
                <form onSubmit={addstaff} className='w-5/6 grid mx-auto  items-center grid-cols-2 gap-x-4 gap-y-2 '>

                    <Input label="Name" type="text" man="*" value={name} onChange={(e) => setName(e.target.value)} />
                    <Input label="Position" type="text" man="*" value={position} onChange={(e) => setPosition(e.target.value)} />


                    <Input label="Date of Birth" man="*" type="date" value={dob} onChange={(e) => setDob(e.target.value)} />

                    <Input label="Mobile No" man="*" type="tel" value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} />
                    <Input label="Email ID" man="" type="email" value={emailId} onChange={(e) => setEmailId(e.target.value)} />

                    <div className='bg-[#e5e7eb] text-black-500 mt-9'>
                        <Widget
                            publicKey="1e63758e5c94313da33d"
                            onChange={info => setUploadedFile(info.cdnUrl)}
                        />
                    </div>


                    {uploadedFile && (
                        <img src={uploadedFile} alt="Uploaded file" width="100" />
                    )}

                    <div className='col-span-full'>
                        <div className='flex justify-center mt-2'><button className='mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2'>Submit</button></div>
                    </div>




                </form>
            </div>
        </>
    )
}

export default Addstaff