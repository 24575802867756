import React from 'react'

function MasterStudents() {
  return (
    <div>
      <div className='  ' style={{ width: '100%' }}>

<h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Master Students</h1>
         <div class="overflow-x-auto mt-2 rounded-lg border border-gray-200">
  <table class="min-w-full divide-y-2 divide-gray-200 text-sm">
    <thead>
      <tr>
        <th
          class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
        >
          Name
        </th>
        <th
          class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
        >
          topic

        </th>
        <th
          class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
        >
          Status
        </th>
        <th
          class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
        >
          Ended on
        </th>
      </tr>
    </thead>
    </table>

    </div>
<hr className='facultyHr' />
    </div>
    </div>


  )
}

export default MasterStudents