import React from 'react'

function Dropdown({label,name,value,onChange,data}) {
    // console.log(data)
    return (
        <div>
            <label for="category" class="block mb-2 text-sm font-medium text-gray-900 ">{label}<span className='text-red-500 font-bold text-[1.2rem]'>*</span></label>
            <select id="category" name={name}  value={value}  onChange={onChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500">
                {
                    data.map((e,i)=>{

                        return(
                            <option value={e}>{e}</option>
                        )
                    })
                }
            </select>
        </div>
    )
}

export default Dropdown