import React from "react";

function SuccessOfStudent() {
  const data = [
    {
      Years: "2009",
      Admitted: "4",
      Appeared: "4",
      Passed: "4",
      Rate: "100%",
      Distinction: "1Topper",
    },
    {
      Years: "2010",
      Admitted: "14",
      Appeared: "14",
      Passed: "13",
      Rate: "93%",
      Distinction: "Topper",
    },
    {
      Years: "2011",
      Admitted: "9",
      Appeared: "9",
      Passed: "8",
      Rate: "89%",
      Distinction: "Topper",
    },
    {
      Years: "2012",
      Admitted: "21",
      Appeared: "21",
      Passed: "20",
      Rate: "100%",
      Distinction: "Topper",
    },
    {
      Years: "2013",
      Admitted: "21",
      Appeared: "20",
      Passed: "19",
      Rate: "",
      Distinction: "",
    },
  ];
  return (
    <div>
      <div className="mx-auto py-5 px-4 w-full max-w-7xl border-b-2 border-gray-200">
        <h3 className="text-5xl text-black font-black">
          Success rate of Students in M.Sc. Final
        </h3>

        <div className="mt-2 relative overflow-x-auto">
          <table className="w-full border border-black  text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs border border-black text-gray-700 uppercase bg-gray-50  dark:text-gray-400">
              <tr className=" border text-base text-black border-black">
                <th scope="col" className="px-6 border border-black  py-3">
                  Years
                </th>
                <th scope="col" className="px-6  border border-black py-3">
                  Admitted
                </th>
                <th scope="col" className="px-6 border border-black  py-3">
                  Appeared
                </th>
                <th scope="col" className="px-6 border border-black  py-3">
                  Passed
                </th>
                <th scope="col" className="px-6 border border-black  py-3">
                  Rate
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((a) => {
                return (
                  <>
                    <tr className="bg-white border border-black   dark:border-gray-700">
                      <td className="px-6 py-4 border border-black font-medium text-gray-900 whitespace-nowrap ">
                        {a.Years}
                      </td>
                      <td className="px-6 border border-black   font-medium text-gray-900 whitespace-nowrap  py-4">
                        {a.Admitted}
                      </td>
                      <td className="px-6 border border-black  font-medium text-gray-900 whitespace-nowrap   py-4">
                        {a.Appeared}
                      </td>
                      <td className="px-6 border border-black  font-medium text-gray-900 whitespace-nowrap   py-4">
                        {a.Passed}
                      </td>
                      <td className="px-6 border border-black  font-medium text-gray-900 whitespace-nowrap   py-4">
                        {a.Rate}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/*  */}
      <div className="mx-auto py-5 px-4 w-full max-w-7xl border-b-2 border-gray-200">
        <h3 className="text-5xl text-black font-black">
        Distinction/Ranks
        </h3>

        <div className="mt-2 relative overflow-x-auto">
          <table className="w-full border border-black  text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs border border-black text-gray-700 uppercase bg-gray-50  dark:text-gray-400">
              <tr className=" border text-base text-black border-black">
                <th scope="col" className="px-6 border border-black  py-3">
                  Years
                </th>
                <th scope="col" className="px-6  border border-black py-3">
                  Admitted
                </th>
                <th scope="col" className="px-6 border border-black  py-3">
                  Appeared
                </th>
                <th scope="col" className="px-6 border border-black  py-3">
                  Passed
                </th>
                <th scope="col" className="px-6 border border-black  py-3">
                  Distinction
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((a) => {
                return (
                  <>
                    <tr className="bg-white border border-black   dark:border-gray-700">
                      <td className="px-6 py-4 border border-black font-medium text-gray-900 whitespace-nowrap ">
                        {a.Years}
                      </td>
                      <td className="px-6 border border-black   font-medium text-gray-900 whitespace-nowrap  py-4">
                        {a.Admitted}
                      </td>
                      <td className="px-6 border border-black  font-medium text-gray-900 whitespace-nowrap   py-4">
                        {a.Appeared}
                      </td>
                      <td className="px-6 border border-black  font-medium text-gray-900 whitespace-nowrap   py-4">
                        {a.Passed}
                      </td>
                      <td className="px-6 border border-black  font-medium text-gray-900 whitespace-nowrap   py-4">
                        {a.Distinction}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SuccessOfStudent;
