import React, { useState, useEffect } from "react";
import { BsFillGearFill } from "react-icons/bs";
import logo from "../assests/NewLogo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "../page/facultydashboard/formGroup/Spinner";
function Navbar() {
  const navigate = useNavigate();
  const [dateState, setDateState] = useState(new Date());
  const [datas, setData] = React.useState({});
  const [loader, setLoader] = useState(true);
  // console.log(datas)
  const siteData = () => {
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0]);
        setLoader(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  React.useEffect(() => {
    siteData();
    setInterval(() => setDateState(new Date()), 30000);
  }, []);
  const [dropDown, setDropDown] = useState(false);
  return (
    <>
      {loader && <Spinner />}
      <div className="px-8  bg-[#343C49] text-gray-50">
        <div className="flex items-center mx-auto container gap-x-4 justify-end py-2">
          <span className="  w-8 sm:px-0 items-center   cursor-pointer ">
            <img className=" " src={`/api/v1/media/${datas.siteLogo}`} />{" "}
          </span>

          <h1 className="flex-1 text-xl font-black"> BNMU</h1>
          <div>
            <span>&nbsp;</span>
            <a href="#" rel="noopener noreferrer" className="underline"></a>
          </div>
          <a
            to=""
            rel="noopener noreferrer"
            className="items-center gap-2 mx-2 md:flex"
          >
            <span className="hover:underline focus-visible:underline">
              <div className="mt-4 sm:mt-0  mx-auto	">
                <p className="font-bold text-xs text-white">
                  {" "}
                  {dateState.toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
                </p>

                <p className="font-bold md:text-center text-xs text-white">
                  {dateState.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",

                    hour12: true,
                  })}
                </p>
              </div>
            </span>
          </a>
          {/* <a href="#" rel="noopener noreferrer" className="items-center gap-2  md:flex">

			<span className="hover:underline focus-visible:underline">Lectures</span>
		</a> */}
          <a
            href="#"
            rel="noopener noreferrer"
            className="items-center gap-2  md:flex"
          >
            <span className="hover:underline focus-visible:underline ">
              {" "}
              <span
                className="inline-block border-2 border-gray-50 rounded-full overflow-hidden"
                aria-label="avatar"
              >
                <img
                  src="https://fancytailwind.com/static/profile10-9e05bd5638c669c34c11cb0462d95aa9.jpg"
                  alt=""
                  className="w-8 h-8"
                />
              </span>
            </span>
          </a>
          <span className="py-auto">admin </span>
          <BsFillGearFill
            onClick={() => setDropDown(!dropDown)}
            className="py-auto"
          />
          {dropDown ? (
            <>
              <div
                className="absolute cursor-pointer z-50 top-[3.6rem] bg-gray-50 h-24 w-36 "
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
              >
                <div className="grid grid-cols-1 justify-items-center mt-4 ">
                  <p
                    onClick={() => navigate("/logout")}
                    className="text-[#343C49]  font-light text-lg text-center "
                  >
                    Logout
                  </p>

                  {/* <p className='text-[#343C49]  font-light text-lg text-center'>Profile</p>
                <p className='text-[#343C49]  font-light text-lg text-center'>Setting</p>
                <div className='mt-7  w-full'>
                <hr className='divide-x-8  bg-blend-color-dodge'/>
                <p className='text-[#343C49] font-light text-lg text-center '>Logout</p> */}
                </div>
              </div>
              {/* </div> */}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Navbar;
