import React, { useState } from 'react'
import TeacherFeedBackQues from './TeacherFeedBackQues';
import Textarea from '../facultydashboard/formGroup/Textarea';
import Input from '../facultydashboard/formGroup/Input';
import { message } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function TeacherFeedback() {
    const intialValue = {
        name: "",
        department: "",
        designation: "",
        programme: "",
        courseCode: "",
        courseName: "",
        yearSemester: "",

      };
      const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
      };
      const [state, setState] = useState(intialValue);
      const [loader, setLoader] = useState(false)
      const [suggestion, setSuggestion] = useState('')
      const navigate = useNavigate()
      const [questionState, setQuestionState] = useState({
        q0: "",
        q1: "",
        q2: "",
        q3: "",
        q4: "",
        q5: "",
        q6: "",
        q7: "",
        q8: "",
        q9:"",
        q10:"",
      });
      const {
        name,
        department,
        designation,
        programme,
        courseCode,
        courseName,
        yearSemester,
      } = state
      const {  q1, q2, q3, q4, q5, q6, q7, q8,q9,q10 } = questionState;
      const handleChange = (name) => (event) => {
        setQuestionState({ ...questionState, [name]: event.target.value });
      };
      const feedbackTeacher = () => {
        setLoader(true);
        axios
          .post("/api/v1/teacher-feedback", {
            teacherName: name,
            department: department,
            designation: designation,
            programme: programme,
            courseCode: courseCode,
            courseName: courseName,
            semester: yearSemester,
            aimsObjectives: q1,
            syllabusSufficient: q2,
            learningValue: q3,
            curriculumnApplicable: q4,
            syllabusFlexibility: q5,
            timelyCoverage: q6,
            evolutionMethod: q7,
            bookPrescribed: q8,
            overallRating:q9,
            presentSyllabus:q10,
            suggestion:suggestion,

          })
          .then(function (response) {
            // console.log(response);
            setLoader(false)
            message.success(response.data.message)
            navigate("/thank-you");
          })
          .catch(function (error) {
            console.log(error);
            setLoader(false)
            message.error(error.response.data.errors)
        });
      };
      const onSubmit = (e) => {
        // setState({ [i.target.name]: "" })
        e.preventDefault()

        feedbackTeacher()

    }
  return (
    <>

    <div>
        <div className="grid z-50 grid-cols-1 mt-8 gap-y-2 justify-items-center my-auto">
        <div className="bg-white  font-bold  rounded-lg w-5/6 mx-4 shadow  px-4 py-2 ">
          <h1 className="text-4xl  text-center mb-2">
            B. N. Mandal University Madhepura बी. एन. मंडल विश्वविद्यालय,
            मधेपुरा
          </h1>
          <h1 className="text-3xl  text-center mb-2">
          Teacher’s Feedback Form – Syllabus
           शिक्षक प्रतिपुष्टी फ़ार्म – पाठ्यक्रम


          </h1>
          <p c>
            Directions: A score for each statement/item between 1 and 5 as:
          </p>
          (A) Excellent-5, (B) Very Good – 4 (C) Good - 3 (D) Average – 2 (E)
          Poor – 1
          <p>
            Please indicate your level of satisfaction with the following
            statement by choosing between 1 and 5.
          </p>
          <p className="font-bold">
            दिशा-निर्देश: 1 और 5 के बीच प्रत्येक कथन/मद के लिए एक अंक:
          </p>
          * उत्कृष्ट - 5, * बहुत अच्छा - 4 * अच्छा - 3 * औसत - 2 * खराब/ निम्न -
          1 कृपया 1 और 5 के बीच चयन करके निम्नलिखित कथन के साथ अपनी संतुष्टि का
          स्तर बताएं।
        </div>
        <div></div>
        <div className="bg-white tst rounded-lg w-5/6 mx-4 shadow-xl  px-4 py-2 ">
          <form
            onSubmit={onSubmit}
            className="w-5/6 grid mx-auto my-auto items-center md:grid-cols-3 gap-x-4 gap-y-2 "
          >
            <Input
              label="Teacher's Name(शिक्षक का नाम)"
              name="name"
              man="*"
              type="text"
              value={name}
              onChange={onChange}
              placeholder=""
            />


            <Input
              label="Department"
              man="*"
              name="department"
              type="text"
              value={department}
              onChange={onChange}
              placeholder=""
            />
            <Input
              label="Designation(पदनाम)"
              name="designation"
              man="*"
              type="text"
              value={designation}
              onChange={onChange}
              placeholder=""
            />




            <Input
              label="Programme(कार्यक्रम)"
              name="programme"
              man="*"
              type="text"
              value={programme}
              onChange={onChange}
              placeholder=""
            />

            <Input
              label="Course Code(पाठ्यक्रम कोड)"
              name="courseCode"
              man="*"
              type="text"
              value={courseCode}
              onChange={onChange}
              placeholder=""
            />
            <Input
              label="Course Name(पाठ्यक्रम नाम)"
              name="courseName"
              man=""
              placeholder="2018"
              type="text"
              value={courseName}
              onChange={onChange}
            />
            <Input
              label="Year / Semester(वर्ष / सेमेस्टर)"
              name="yearSemester"
              man="*"
              type="text"
              value={yearSemester}
              onChange={onChange}
              placeholder=""
            />




            <div className="col-span-full">
              <TeacherFeedBackQues
                handleChange={handleChange}
                value={questionState}
              />
            </div>
            <div className="col-span-full">
              <Textarea
                label="Any further suggestion. कोई अन्य सुझाव"
                placeholder=""
                row="4"
                value={suggestion}
                onChange={(e) => setSuggestion(e.target.value)}
              />
            </div>
            <div className="col-span-full">
              <div className="flex justify-center mt-2">
                <button className="mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  )
}

export default TeacherFeedback