import React, { useState } from "react";
import Accordion from "./Accordion";
import AccordionData from "./AccordionData";
import Card from "./Card";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import WelcomMsg from "./WelcomMsg";
import axios from "axios";

function Home() {
  const [datas, setData] = React.useState({});
  const [loader, setLoader] = useState(true);
  // console.log(datas)
  const siteData = () => {
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0]);
        setLoader(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  React.useEffect(() => {
    siteData();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <div>
        <div style={{ width: "100%", height: "100vh", overflow: "scroll" }}>
          <Navbar datas={datas} />
          <div className="flex bg-[#F4F4F5]" style={{ width: "100%" }}>
            <Sidebar />
            <div className="" style={{ width: "100%" }}>
              <WelcomMsg />
              <Card />
              {/* <Accordion/> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
