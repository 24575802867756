import React, { useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Input, Dropdown } from "../../formGroup/Index";
import uni1 from "../../assests/uni1.jpeg";
import uni2 from "../../assests/uni2.jpeg";
import uni3 from "../../assests/uni3.jpeg";
import uni4 from "../../assests/uni4.jpeg";
import uni5 from "../../assests/uni5.jpeg";
import uni6 from "../../assests/uni6.jpeg";
import uni7 from "../../assests/uni7.jpeg";
import uni8 from "../../assests/uni8.jpeg";
import uni9 from "../../assests/uni9.jpeg";
import uni10 from "../../assests/uni10.jpeg";
import uni11 from "../../assests/uni11.jpeg";
import uni12 from "../../assests/uni12.jpeg";
import uni13 from "../../assests/uni13.jpeg";
import uni14 from "../../assests/uni14.jpeg";
import uni15 from "../../assests/uni15.jpeg";
import uni16 from "../../assests/uni16.jpeg";
import uni17 from "../../assests/uni17.jpeg";
import uni18 from "../../assests/uni18.jpeg";
import uni19 from "../../assests/uni19.jpeg";
import uni20 from "../../assests/uni20.jpeg";
import uni21 from "../../assests/uni21.jpeg";
import uni22 from "../../assests/uni22.jpeg";
import uni23 from "../../assests/uni23.jpeg";
import uni24 from "../../assests/uni24.jpeg";
import axios from "axios";
import { message } from "antd";
import Spinner from "../../page/facultydashboard/formGroup/Spinner";
function Gallery(props) {
  const allImg = [
    uni1,
    uni2,
    uni3,
    uni4,
    uni5,
    uni6,
    uni7,
    uni8,
    uni9,
    uni10,
    uni11,
    uni12,
    uni13,
    uni14,
    uni15,
    uni16,
    uni17,
    uni18,
    uni19,
    uni20,
    uni21,
    uni22,
    uni23,
    uni24,
  ];
  const campus = [uni1, uni2, uni3, uni4, uni5, uni7, uni8, uni9, uni10, uni13];

  const eventImg = [
    uni6,
    uni14,
    uni15,
    uni16,
    uni17,
    uni18,
    uni19,
    uni20,
    uni21,
    uni22,
    uni23,
    uni24,
  ];
  const [step, setStep] = useState("step1");
  const [category, setCategory] = useState("EVENT");
  // console.log(useState)
  // console.log(step)
  const stepChanges = (value) => {
    // console.log(value)
    setStep(value);
  };
  const functionTwo = () => {
    // console.log("hello world");
  };
  const data = ["----", "Campus", "Event"];
  const [id2Delete, setId2Delete] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const handleModel = (i) => {
    setId2Delete(i);
    setModalOpen(true);
  };
  const [selectedImage, setSelectedImage] = useState([]);
  // console.log({ selectedImage: selectedImage });
  const handleImageChange = (event) => {
    const files = event.target.files;
    setSelectedImage([...selectedImage, ...files]);
  };
  const [state, setState] = useState([]);
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const removeBatch = (id) => {
    const updateBatch = selectedImage.filter((file, index) => {
      return index !== id;
    });
    setSelectedImage(updateBatch);
  };
  // console.log({ state: state });

  const [loader, setLoader] = useState(false);
  //  change logo
  const imgUpload = (event) => {
    event.preventDefault();
    if (!state) {
      message.error("Please Select Image First");
    } else {
      const formData = new FormData();

      formData.append("category", category);
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append("img", selectedImage[i]);
      }
      setLoader(true);
      axios
        .post(`/api/v1/gallery/upload`, formData)
        .then(function (response) {
          setLoader(false);
          message.success(response.data.message);
          setSelectedImage([]);
          props.onClick();
        })
        .catch(function (error) {
          setLoader(false);
          message.error(error.response.data.errors);
        });
    }
  };

  //  delete image
  const deleteImage = (id) => {
    setLoader(true);
    axios
      .delete(`/api/v1/api/gallery/${id}`)
      .then(function (response) {
        // handle success
        setLoader(false);
        message.success(response.data.message);
        props.onClick();
        setModalOpen(false);
      })
      .catch(function (error) {
        setLoader(false);
        // handle error
        console.log(error);
        message.errror(error.response.data.errors);
        setModalOpen(false);
      });
  };
  return (
    <div>
      <Breadcrumbs page={"Gallery"} pagelink="#" sublink="#" />
      {loader && <Spinner />}
      <div className="grid grid-cols-1 md:grid-cols-3 ml-4  gap-2">
        <div>
          <label
            for="category"
            class={`block mb-2 text-sm font-medium text-gray-900  `}
          >
            Course
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            id="category"
            class={`w-full h-9 mt-2 rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="CAMPUS">
              CAMPUS
            </option>
            <option className="" value="EVENT">
              EVENT
            </option>
            <option className="" value="SEMINAR">
              SEMINAR
            </option>
            <option className="" value="CONFERENCE">
              CONFERENCE
            </option>
          </select>
        </div>
        <Input
          type="file"
          multiple
          label="Upload Image"
          onChange={handleImageChange}
          inputCss="h-12"
        />

        <div className="my-auto">
          <h1 className="opacity-0">k</h1>
          <button
            onClick={imgUpload}
            className="mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
          >
            Upload
          </button>
        </div>
        {/* preview */}
        {selectedImage.map((file, index) => (
          <div className="w-1/2 h-1/4" key={index}>
            <span
              onClick={() => removeBatch(index)}
              className="text-center cursor-pointer"
            >
              X
            </span>

            <img src={URL.createObjectURL(file)} alt={file.name} />
            <p>{file.name}</p>
          </div>
        ))}

        <div></div>
      </div>
      <div className="flex justify-center  ">
        <button
          className="text-xs p-2 md:p-5  border rounded-lg hover:bg-red-600 hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-5 font-bold"
          onClick={() => {
            stepChanges("step2");
          }}
        >
          Event
        </button>

        <button
          className="text-xs p-2 md:p-5  border rounded-lg hover:bg-red-600 hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-5 font-bold"
          onClick={() => {
            stepChanges("step3");
          }}
        >
          Campus
        </button>
        <button
          className="text-xs p-2 md:p-5  border rounded-lg hover:bg-red-600 hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-5 font-bold"
          onClick={() => {
            stepChanges("step4");
          }}
        >
          Seminar
        </button>
        <button
          className="text-xs p-2 md:p-5  border rounded-lg hover:bg-red-600 hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-5 font-bold"
          onClick={() => {
            stepChanges("step5");
          }}
        >
          Conference
        </button>
      </div>
      {modalOpen && (
        <div className="fixed inset-0 z-40 min-h-full overflow-y-auto overflow-x-hidden transition flex items-center">
          {/* overlay */}
          <div
            aria-hidden="true"
            className="fixed inset-0 w-full h-full bg-black/50 cursor-pointer"
          ></div>
          {/* Modal */}
          <div className="relative w-full cursor-pointer pointer-events-none transition my-auto p-4">
            <div className="w-full py-2 bg-white cursor-default pointer-events-auto  relative rounded-xl mx-auto max-w-sm">
              <button
                onClick={() => setModalOpen(false)}
                tabIndex={-1}
                type="button"
                className="absolute top-2 right-2 rtl:right-auto rtl:left-2"
              >
                <svg
                  title="Close"
                  tabIndex={-1}
                  className="h-4 w-4 cursor-pointer text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close</span>
              </button>
              <div className="space-y-2 p-2">
                <div className="p-4 space-y-2 text-center text-black ">
                  <h2
                    className="text-xl font-bold tracking-tight"
                    id="page-action.heading"
                  >
                    Delete this photo
                  </h2>
                  <p className="text-gray-500">
                    Are you sure you would like to do this?
                  </p>
                </div>
              </div>
              <div className="space-y-2">
                <div
                  aria-hidden="true"
                  className="border-t dark:border-gray-700 px-2"
                />
                <div className="px-6 py-2">
                  <div className="grid gap-2 grid-cols-[repeat(auto-fit,minmax(0,1fr))]">
                    <button
                      type="button"
                      onClick={() => setModalOpen(false)}
                      className="inline-flex items-center justify-center py-1 gap-1 font-medium rounded-lg border transition-colors outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset dark:focus:ring-offset-0 min-h-[2.25rem] px-4 text-sm text-gray-800 bg-white border-gray-300 hover:bg-gray-50 focus:ring-primary-600 focus:text-primary-600 focus:bg-primary-50 focus:border-primary-600 dark:bg-gray-800 dark:hover:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500 dark:text-gray-200 dark:focus:text-primary-400 dark:focus:border-primary-400 dark:focus:bg-gray-800"
                    >
                      <span className="flex items-center gap-1">
                        <span className="">Cancel</span>
                      </span>
                    </button>
                    <button
                      onClick={() => deleteImage(id2Delete)}
                      type="submit"
                      className="inline-flex items-center justify-center py-1 gap-1 font-medium rounded-lg border transition-colors outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset dark:focus:ring-offset-0 min-h-[2.25rem] px-4 text-sm text-white shadow focus:ring-white border-transparent bg-red-600 hover:bg-red-500 focus:bg-red-700 focus:ring-offset-red-700"
                    >
                      <span className="flex items-center gap-1">
                        <span className="">Confirm</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {step == "step1" ? (
        <>
          <section className="py-6 col-span-full bg-gray-100">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                {props.data?.map((i) => {
                  return (
                    <>
                      <img
                        className="object-cover w-full bg-gray-500 aspect-square"
                        src={`/api/v1/gallery/${i.img}`}
                      />
                    </>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      ) : null}
      {step == "step2" ? (
        <>
          <section className="py-6 transition-all delay-1000 ease-in col-span-full bg-gray-100">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                {props.data?.map((i) => {
                  return (
                    <>
                      {/* <div>

                                        <img className="object-cover w-full bg-gray-500 aspect-square" src={i} />
                                    <span className='block hover:hidden'>ks</span>
                                    </div> */}

                      {i.category === "EVENT" && (
                        <p class="group relative inline-block overflow-hidden border border-indigo-600 px-8  focus:outline-none focus:ring">
                          <span class="absolute mt-2 inset-x-0 bottom-0 h-[2px] bg-black/60 transition-all group-hover:h-full group-active:bg-indigo-500">
                            <div className="absolute inset-0 flex flex-col  items-center justify-center ">
                              <button
                                className="inline-block mt-8  px-4 py-2 rounded border border-[#273272]   text-sm font-medium text-white hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                                onClick={(e) => {
                                  handleModel(i._id);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </span>

                          <img
                            className="object-cover w-full bg-gray-500 aspect-square"
                            src={`/api/v1/gallery/${i.img}`}
                          />
                        </p>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      ) : null}
      {step == "step3" ? (
        <>
          <section className="py-6 col-span-full bg-gray-100">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                {props.data?.map((i) => {
                  return (
                    <>
                      {i.category === "CAMPUS" && (
                        <p class="group relative inline-block overflow-hidden border border-indigo-600 px-8 py-3 focus:outline-none focus:ring">
                          <span class="absolute inset-x-0 bottom-0 h-[2px] bg-black/60 transition-all group-hover:h-full group-active:bg-indigo-500">
                            <div className="absolute inset-0 flex flex-col  items-center justify-center ">
                              <button
                                className="hover:inline-block mt-2  px-4 py-2 rounded border border-[#273272]   text-sm font-medium text-white hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                                onClick={(e) => {
                                  handleModel(i._id);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </span>

                          <img
                            className="object-cover w-full bg-gray-500 aspect-square"
                            src={`/api/v1/gallery/${i.img}`}
                          />
                        </p>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      ) : null}
      {step == "step4" ? (
        <>
          <section className="py-6 col-span-full bg-gray-100">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                {props.data?.map((i) => {
                  return (
                    <>
                      {i.category === "SEMINAR" && (
                        <p class="group relative inline-block overflow-hidden border border-indigo-600 px-8 py-3 focus:outline-none focus:ring">
                          <span class="absolute inset-x-0 bottom-0 h-[2px] bg-indigo-600 transition-all group-hover:h-full group-active:bg-indigo-500">
                            <div className="absolute inset-0 flex flex-col  items-center justify-center ">
                              <>
                                <button
                                  className="inline-block mt-1  px-4 py-2 rounded border border-[#273272]   text-sm font-medium text-white hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                                  onClick={(e) => {
                                    handleModel(i._id);
                                  }}
                                >
                                  Delete
                                </button>
                              </>
                            </div>
                          </span>

                          <img
                            className="object-cover w-full bg-gray-500 aspect-square"
                            src={`/api/v1/gallery/${i.img}`}
                          />
                        </p>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      ) : null}
      {step == "step5" ? (
        <>
          <section className="py-6 col-span-full bg-gray-100">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                {props.data?.map((i) => {
                  return (
                    <>
                      {i.category === "CONFERENCE" && (
                        <p class="group relative inline-block overflow-hidden border border-indigo-600 px-8 py-3 focus:outline-none focus:ring">
                          <span class="absolute inset-x-0 bottom-0 h-[2px] bg-indigo-600 transition-all group-hover:h-full group-active:bg-indigo-500">
                            <div className="absolute inset-0 flex flex-col  items-center justify-center ">
                              <>
                                <button
                                  className="inline-block mt-16  px-4 py-2 rounded border border-[#273272]   text-sm font-medium text-white hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                                  onClick={(e) => {
                                    handleModel(i._id);
                                  }}
                                >
                                  Delete
                                </button>
                              </>
                            </div>
                          </span>

                          <img
                            className="object-cover w-full bg-gray-500 aspect-square"
                            src={`/api/v1/gallery/${i.img}`}
                          />
                        </p>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      ) : null}
    </div>
  );
}

export default Gallery;
