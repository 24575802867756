import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import Addstu from '../Addstudent/Addstu'
import Addstafftable from '../Addstaff/Addstafftable';
import axios from 'axios';
import AddstuTable from './AddstuTable';

function AddstuPreview() {
  const [data, setData] = useState([]);
  // console.log(data);
  const student = () => {
    axios
      .get("/api/v1/get-all-student")
      .then(function (response) {
        // handle success
        //console.log(response.data);
        setData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  useEffect(() => {
    student();
  }, [])
  return (
    <div>
        <div style={{ width: '100%',  }}>
    <div>
        <div style={{ width: '100%', height:'100vh', overflow:'scroll'}}>
            <Navbar />
        <div className='flex bg-[#F4F4F5]' style={{ width: '100%' }}>
          <Sidebar />
        <div className='' style={{ width: '100%' }}>
          <Addstu viewAllStudent={student}/>
          <AddstuTable data={data} viewAllStudent={student}/>
        </div>
        </div>



        </div>
    </div>
</div>
    </div>
  )
}

export default AddstuPreview