import React, { useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import { Input } from '../../formGroup/Index'
import axios from 'axios'
import { message } from 'antd'
import Spinner from '../../page/facultydashboard/formGroup/Spinner'
function Slider() {

  const [slider1, setSlider1] = useState('')
  const [slider2, setSlider2] = useState('')
  const [slider3, setSlider3] = useState('')



  // get data
  const [data, setData] = React.useState({})
  const [loader, setLoader] = useState(false)
  // console.log(data)
  const siteData = () => {
    setLoader(true)
    axios.get('/api/v1/api/site/data')
      .then(function (response) {
        // handle success
        // console.log(response);
        setLoader(false)
        setData(response.data[0])
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoader(false)
      })
  }


  const slider1change = (event) => {
    event.preventDefault()
    const formData = new FormData();
    formData.append('img', slider1);
    setLoader(true)
    axios.put(`/api/v1/slider1/${data._id}`, formData,)
      .then(function (response) {
        setLoader(false)
        message.success(response.data.message)
        siteData()
      })
      .catch(function (error) {
        setLoader(false)
        message.error(error.response.data.errors)
       
      });

  }
  const slider2change = (event) => {
    event.preventDefault()
    const formData = new FormData();
    formData.append('img', slider2);
    setLoader(true)
    axios.put(`/api/v1/slider2/${data._id}`, formData,)
      .then(function (response) {
        setLoader(false)
        message.success(response.data.message)
        siteData()
      })
      .catch(function (error) {
        setLoader(false)
        message.error(error.response.data.errors)

      });

  }
  const slider3change = (event) => {
    event.preventDefault()
    const formData = new FormData();
    formData.append('img', slider3);
    setLoader(true)
    axios.put(`/api/v1/slider3/${data._id}`, formData,)
      .then(function (response) {
        setLoader(false)
        message.success(response.data.message)
        siteData()
      })
      .catch(function (error) {
        setLoader(false)
        message.error(error.response.data.errors)

      });

  }

  React.useEffect(() => {
    siteData()
  }, [])
  return (
    <div>
      {
        loader && <Spinner />
      }
      <Breadcrumbs page={"Change slider image"} pagelink="#" sublink='#' subpage={"Content Update"} />
      <section className="py-6 bg-gray-100">
        <div className="container flex flex-col justify-center p-4 mx-auto">
          <div className="grid grid-cols-1 gap- lg:grid-cols-3 sm:grid-cols-2">
            <div className='px-4  grid gap-1'>

              <img className="object-cover mx-auto w-1/2 bg-gray-500 " src={`/api/v1/media/${data.slider1}`} />
              <div className='w-1/2 mx-auto'>

                <Input type='file' label='Change slider 1 ' inputCss={"inline-block  mx-auto px-2 py-1 rounded border border-[#273272] mt-2  text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500"} onChange={(e) => { setSlider1(e.target.files[0]) }} />
                <center>
                  <button onClick={slider1change} type="button" class="mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Update</button>

                </center>
              </div>
              <div className='flex justify-center space-x-2'>

                <div className=' my-auto'>



                </div>
              </div>
            </div>

            <div className='px-4  grid gap-1'>

              <img className="object-cover mx-auto w-1/2 bg-gray-500 " src={`/api/v1/media/${data.slider2}`} />
              <div className='w-1/2 mx-auto'>

                <Input type='file' label='Change slider 2' inputCss={"inline-block  mx-auto px-2 py-1 rounded border border-[#273272] mt-2  text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500"} onChange={(e) => { setSlider2(e.target.files[0]) }} />

                <center>
                  <button onClick={slider2change} type="button" class="mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Update</button>

                </center>
              </div>
              <div className='flex justify-center space-x-2'>

                <div className=' my-auto'>



                </div>
              </div>
            </div>
            <div className='px-4  grid gap-1'>



              <img className="object-cover mx-auto w-1/2 bg-gray-500 " src={`/api/v1/media/${data.slider3}`} />
              <div className='w-1/2 mx-auto'>

                <Input type='file' label='Change slider 3' inputCss={"inline-block  mx-auto px-2 py-1 rounded border border-[#273272] mt-2 pb-0 text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500"} onChange={(e) => { setSlider3(e.target.files[0]) }} />

                <center>
                  <button onClick={slider3change} type="button" class="mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Update</button>

                </center>
              </div>

            </div>
          </div>
        </div>


      </section>

      {/* <div class="overflow-x-auto  mt-4  px-4">
  <table class="w-[30rem] border  divide-y-2 divide-gray-200 text-sm">
    <thead>
      
    </thead>
    <tbody class="divide-y border  divide-gray-200">
    <tr>
                <td class="whitespace-nowrap border   px-4 py-2 font-medium text-gray-900">
			<img className="object-contain w-1/2 mx-auto  bg-gray-500 aspect-auto" src="https://source.unsplash.com/random/300x300/?1" />
              
                </td>
                <td class="whitespace-nowrap border  px-4 py-2">
                  <button
                     
                    

                    class="inline-block  rounded bg-red-600 px-4 py-2 text-xs font-medium text-white hover:bg-red-700"
                    >
                    replace

                  </button>
                </td>
              </tr> 
              <tr>
                <td class="whitespace-nowrap border   px-4 py-2 font-medium text-gray-900">
			<img className="object-contain w-1/2   mx-auto bg-gray-500 aspect-auto" src="https://source.unsplash.com/random/300x300/?1" />
              
                </td>
                <td class="whitespace-nowrap border  px-4 py-2">
                  <button
                     
                    

                    class="inline-block  rounded bg-red-600 px-4 py-2 text-xs font-medium text-white hover:bg-red-700"
                    >
                    replace

                  </button>
                </td>
              </tr> 
      </tbody>
</table>
</div>
 */}
    </div>
  )
}

export default Slider