import React from 'react'
import { Link } from 'react-router-dom'

function Breadcrumbs(props) {
	return (
		<div>
			<nav aria-label="breadcrumb" className="w-full p-4 bg-gray-100 text-gray-800">
				<ol className="flex justify-end h-8 space-x-2">
					<Link to={props.pagelink} className='my-auto md:text-3xl text-red-600 flex items-center px-1 capitalize flex-1'>{props.page}</Link>
					<li className="flex  items-center">
						<Link to='#' title="Back to homepage" className="hover:underline">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 text-gray-600">
								<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
							</svg>
						</Link>
					</li>
					<li className="flex items-center space-x-2">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current text-gray-400">
							<path d="M32 30.031h-32l16-28.061z"></path>
						</svg>
						<Link rel="noopener noreferrer" to={props.sublink} className="flex items-center px-1 capitalize hover:underline">{props.subpage}</Link>
					</li>
					<li className="flex items-center space-x-2">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current text-gray-400">
							<path d="M32 30.031h-32l16-28.061z"></path>
						</svg>
						<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:underline">{props.page}</a>
					</li>

				</ol>
			</nav>
		</div>
	)
}

export default Breadcrumbs