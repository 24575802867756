import React, { useState, useEffect } from "react";
import { Input } from "../formGroup/Index";
import axios from "axios";
import { message } from "antd";

function Misc(props) {
  const [edustate, setEduState] = useState(false);
  const initialState = {
    misc: "",
  };
  const [editState, setEditState] = useState(false);
  const [state, setState] = useState(initialState);
  const [tde, setTde] = useState([]);
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const addmore = (e) => {
    setTde([...tde, { misc: state.misc }]);
    setEduState(false);
    setState({ [e.target.name]: "" });
  };
  const removeBatch = (id) => {
    props.loader(true);
    axios
      .delete(`/api/v1/miscellaneous/delete/${id}`)
      .then(function (response) {
        props.loader(false);
        setEduState(false);
        message.success(response.data.message);
        miscData();
      })
      .catch(function (error) {
        message.error(error.response.data.message);
        console.log(error);
        console.log(error.response.data.message);
        props.loader(false);
      });
  };

  const handleEdit = (id) => {
    setEditState(true);
  };

  const [hdn, setHdn] = useState(false);

  const [k, setK] = useState("hidden");
  const addD = () => {
    setEduState(!edustate);
    setHdn(!hdn);
    if (hdn === false) {
      setK("block");
    } else {
      setK("hidden");
    }
  };

  // send data to backend
  const [miscellaneous, setMiscellaneous] = useState("");
  const [authorName, setAuthorName] = useState(props.data.name);
  // console.log(authorName)
  const onSubmit = (e) => {
    e.preventDefault();
    props.loader(true);
    axios
      .post("/api/v1/miscellaneous/misc", {
        miscellaneous: miscellaneous,
        authorName: authorName,
      })
      .then(function (response) {
        props.loader(false);
        setMiscellaneous("");
        setEduState(false);
        message.success(response.data.message);
        miscData();
        // setHide(true)
      })
      .catch(function (error) {
        props.loader(false);
        message.error(error.response.data.message);
      });
  };

  const [data, setData] = useState([]);
  // console.log(data)
  const miscData = () => {
    props.loader(true);
    axios
      .get("/api/v1/miscellaneous/misc")
      .then(function (response) {
        // handle success
        setData(response.data.data);
        props.loader(false);
        // console.log(response.data.data);
        // console.log(response)
      })
      .catch(function (error) {
        // handle error
        props.loader(false);
        console.log(error);
      });
  };
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const editA = (id, miscellaneous) => {
    console.log(id, miscellaneous);
    setId(id);
    setMiscellaneous(miscellaneous);
    setIsEdit(true);
    setEduState(!edustate);
  };

  const updateEdit = () => {
    axios
      .patch(`api/v1/miscellaneous/misc/${id}`, {
        miscellaneous: miscellaneous,
        authorName: authorName,
      })
      .then(function (response) {
        props.loader(false);
        setMiscellaneous("");
        setEduState(false);
        setIsEdit(false);
        message.success(response.data.message);
        miscData();
        // setHide(true)
      })
      .catch(function (error) {
        props.loader(false);
        message.error(error.response.data.message);
      });
  };
  console.log(data, "data in misc");
  useEffect(() => {
    miscData();
  }, []);
  return (
    <>
      <div className=" ">
        <div className="   " style={{ width: "100%" }}>
          <div className="flex space-x-2 justify-between ">
            <h1 className=" text-[1.7rem] mt-[2rem]" id="edu">
              misc
            </h1>
            <div className="flex gap-2">
              <button
                className="mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                onClick={addD}
              >
                Add
              </button>
              <button
                className={`${k} mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`}
                onClick={addD}
              >
                close
              </button>
            </div>
          </div>

          {edustate ? (
            <>
              <div className="grid grid-cols-3 space-x-2 justify-between gap-2">
                <div className="col-span-2">
                  <Input
                    placeholder=""
                    label="miscs"
                    name="misc"
                    type="text"
                    value={miscellaneous}
                    onChange={(e) => {
                      setMiscellaneous(e.target.value);
                    }}
                  />
                </div>

                <div className="my-auto">
                  <h1 className="opacity-0"> k</h1>
                  <button
                    className="mt-auto px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none"
                    onClick={isEdit ? updateEdit : onSubmit}
                  >
                    {isEdit ? "update" : "submit"}
                  </button>
                </div>
              </div>
            </>
          ) : null}

          {data?.map((elm, ind) => {
            return (
              <>
                {props.data._id === elm.authorId && (
                  <>
                    <div
                      className="flex gap-2 items-center justify-between mt-7"
                      style={{ width: "100%" }}
                      key={ind}
                    >
                      <div className="edu1">{elm.miscellaneous}</div>
                      <div className="edu2">
                        {elm.author} {elm.year}
                      </div>
                      <div className="flex space-x-4">
                        <button
                          className=" px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                          onClick={() => editA(elm._id, elm.miscellaneous)}
                        >
                          Edit
                        </button>

                        <button
                          className=" px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                          onClick={() => removeBatch(elm._id)}
                        >
                          delete
                        </button>
                      </div>
                    </div>
                    <hr className="facultyHr" />
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Misc;
