import React from 'react'

function Collab(props) {
  return (
    <div>
       <div className='  ' style={{ width: '100%' }}>

<h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Collaboration</h1>

      {
        props.data.collaborations?.map((p,i)=>{
          return(
            <>

            <div className='flex  items-center justify-between mt-7' style={{ width: '100%' }}>
    <div className='edu1'>{p.collaboration}

    </div>
    <div className='edu2'></div>
</div>
<hr className='facultyHr' />

            </>
          )
        })
      }




</div>
    </div>
  )
}

export default Collab
