import React, { useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import { Input } from '../../formGroup/Index'
import axios from 'axios'
import { message } from 'antd'
import adr from "../../assests/Add_research.xlsx"
import Spinner from '../../page/facultydashboard/formGroup/Spinner';
function Addstu(props) {
    const [name, setName] = useState("");
    const [thesisTitle, setThesisTitle] = useState("");
    const [phdRegYear, setPhdRegYear] = useState("");
    const [phdRegNo, setPhdRegNo] = useState("");
    const [guideName, setGuideName] = useState("");
    const [loader, setLoader] = useState(false);
    const [researchSelect, setResearchSelect] = useState()
    const [email, setEmail] = useState('')
    const [mobileNo, setMobileNo] = useState('')
    const uploadBulkResearchData = () => {
        setLoader(true)
        const formData = new FormData();
        formData.append('file', researchSelect);
        axios.post('/api/v1/upload/research-scholor/bulk-data', formData).then((response) => {
            alert('Research Data Upload Successfully')
            props.viewAllStudent()
            setLoader(false)
        }).catch((error) => {
            console.log('Something went wrong', error)
            alert('Error in Document Upload ')
            setLoader(false)
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoader(true)
        axios.post('/api/v1/research/add', {
            name, thesisTitle, phdRegYear, phdRegNo, guideName, email, mobileNo
        })
            .then(function (response) {
                setLoader(false)
                message.success(response.data.message);
                setName("")
                setThesisTitle("")
                setPhdRegYear("")
                setPhdRegNo("")
                setGuideName("")
                setEmail('')
                setMobileNo('')
                props.viewAllStudent()
            })
            .catch(function (error) {
                setLoader(false)
                console.log(error.response.data.errors);
                message.error(error.response.data.errors);

            });


    };





    return (
        <>
            {
                loader && <Spinner />
            }

            <Breadcrumbs page="Add Research Scholar" pagelink="/admin/dashboard/add/research" sublink="/admin/dashboard" subpage="Dashboard" />

            <div className='bg-white rounded-lg w-5/6 mx-4 shadow-xl  px-4 py-2 '>
                <form onSubmit={handleSubmit} className='w-5/6 grid mx-auto  items-center md:grid-cols-2 gap-x-4 gap-y-2 '>

                    <Input label="Name" type="text" man="*" value={name} onChange={(e) => setName(e.target.value)} />
                    <Input label="Thesis Title" type="text" man="*" value={thesisTitle} onChange={(e) => setThesisTitle(e.target.value)} />
                    <Input label="PHD Registration Year" type="text" man="*" value={phdRegYear} onChange={(e) => setPhdRegYear(e.target.value)} />
                    <Input label="PHD Registration No" type="text" man="*" value={phdRegNo} onChange={(e) => setPhdRegNo(e.target.value)} />
                    <Input label="Name of the Guide" man="*" type="text" value={guideName} onChange={(e) => setGuideName(e.target.value)} />
                    <Input label="Email" man="*" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <Input label="Mobile No." man="*" type="text" value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} />


                    <div className='col-span-full'>
                        <div className='flex justify-center mt-2'><button className='mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2'>Submit</button></div>
                    </div>
                </form>
            </div>
            <div className='bg-white mt-2 rounded-lg w-5/6 mx-4 shadow-xl grid grid-cols-2  px-4 py-2 '>
                <fieldset className="w-full space-y-1 dark:text-gray-100">
                    <label for="files" className="block text-sm font-medium">Or Upload in bulk </label>
                    <div className="flex">
                        <input type="file" name="files" accept='.xls,.xlsx' id="files" className="px-8 py-12 border-2 border-dashed rounded-md dark:border-gray-700 dark:text-gray-400 " onChange={(r) => setResearchSelect(r.target.files[0])} />
                    </div>
                    <div className=' w-[75%]'>
                        <div className='flex justify-center mt-2'><button className='mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2' onClick={uploadBulkResearchData}>Upload</button></div>
                    </div>
                </fieldset>
                <div className='my-auto'>

                    <a
                        class="inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                        href={adr}
                    >
                        click here to download format
                    </a>
                </div>

            </div>
        </>
    )
}

export default Addstu