import React from 'react'

function AdministrativePositions(props) {
  return (
    <div>

<div className='  ' style={{ width: '100%' }}>

<h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Administrative Positions</h1>
{
  props.data.adminPosition?.map((p,i)=>{
    return(
      <>


<div className='flex  items-center justify-between mt-7' style={{ width: '100%' }}>
    <div className='edu1'>{p.administrative}

    </div>
    <div className='edu2'>{p.startingYear} to {p.endingYear}</div>
</div>
<hr className='facultyHr' />
      </>

)
})
}
    </div>
</div>

  )
}

export default AdministrativePositions