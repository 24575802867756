import React, { useState, useEffect } from "react";
// import Sidebar from './Sidebar'
//Bootstrap and jQuery libraries
// import 'bootstrap/dist/css/bootstrap.min.css';
import "jquery/dist/jquery.min.js";
// //Datatable Modules
// import EditUserModal from './EditUserModal';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from "jquery";
//For API Requests
import axios from "axios";
import Breadcrumbs from "../../components/Breadcrumbs";
import Edit from "./Edit";
import Spinner from "../../page/facultydashboard/formGroup/Spinner";
import { message } from "antd";
import ViewPlaced from "./ViewPlaced";
// import Delete from './Delete';
const PlacementList = () => {
  const [data, setState] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [deleteShowModal, setDeleteShowModal] = React.useState(false);
  const [passwordChangesModal, setPasswordChangesModal] = React.useState(false);
  const [editFaculty, setEditFaculty] = useState({});
  const [loader, setLoader] = useState(false);
  console.log("state", data.data);
  // State array variable to save and show data
  const fetchData = () => {
    axios.get("/api/v1/placement").then((res) => {
      //Storing users detail in state array object
      console.log(res, "test");
      setState({ data: res.data });
      console.log("dataAPI", res);
    });
    $(document).ready(function () {
      setTimeout(function () {
        $("#example").DataTable({
          pagingType: "full_numbers",
          pageLength: 5,
          processing: true,
          dom: "Bfrtip",
          buttons: ["copy", "csv", "print"],
        });
      }, 1000);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  console.log("new", data);

  // const handleEditChange = (id,  name, gender,email, mobileNo, dob, program, session, yearOfAdmission,photo) => {
  //     setShowModal(true)
  //     setEditFaculty({ id: id, photo:photo,name: name, gender:gender ,mobileNo: mobileNo, email: email, dob: dob, program: program, session: session, yearOfAdmission })
  // }
  const handleEditChange = (result) => {
    console.log(result);
    setShowModal(true);
    setEditFaculty(result);
  };
  const delAlumni = (id) => {
    setLoader(true);
    axios
      .delete(`/api/v1/placement/${id}`)

      .then((response) => {
        setLoader(false);
        fetchData();
        message.success(`Deleted successfully`);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };
  // .
  //Datatable HTML
  return (
    <>
      {loader && <Spinner />}
      <Breadcrumbs
        page={"Placed Alumni"}
        pagelink="#"
        sublink="#"
        subpage={"Alumni"}
      />
      <div className="flex flex-col" style={{ width: "100%" }}>
        {/* <h1 className='flex justify-center pt-4 text-black  text-2xl  mb-0'>User list</h1> */}
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className=" overflow-x-auto">
              {/* <DataTable columns={columns} data={student} pagination
                                                fixedHeader
                                                fixedHeaderScrollHeight='400px'
                                                highlightOnHover
                                            // actions={<button className='bg-blue-900 text-white pl-4 pr-4' style={{}}>{actionsMemo}</button>}
                                            /> */}
              <div className="MainDiv">
                <div className="container p-5">
                  {/* <div className='flex justify-end mr-8 '>
<button className='inline-block rounded border border-[#273272] px-12 py-2 text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500'>Add user</button>
           </div> */}
                  <table id="example" class="table table-hover table-bordered ">
                    <thead>
                      <tr class="text-black bg-neutral-200">
                        <th>Sr No.</th>
                        <th>Name</th>

                        <th>Mobile No</th>
                        <th>Program</th>
                        <th>Status</th>

                        <th>View</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.data &&
                        data.data.map((result, index) => {
                          return (
                            <tr class="text-black ">
                              <td>{index + 1}</td>
                              <td>{result.studentName}</td>

                              <td>{result.mobileNo}</td>
                              <td>{result.program} </td>
                              <td className="font-bold ">
                                {result.designation}
                              </td>

                              <td>
                                <button
                                  onClick={() => {
                                    handleEditChange(result);
                                  }}
                                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                  View
                                </button>
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    delAlumni(result._id);
                                  }}
                                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {showModal === true && (
                    <>
                      <ViewPlaced
                        showModal={showModal}
                        setShowModal={setShowModal}
                        editFaculty={editFaculty}
                        updateData={fetchData}
                      />
                    </>
                  )}{" "}
                  {/*
                                    {deleteShowModal === true && <>
                                        <Delete deleteShowModal={deleteShowModal} setDeleteShowModal={setDeleteShowModal} editFaculty={editFaculty} updateData={fetchData} />
                                    </>}

                                    </>} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PlacementList;
