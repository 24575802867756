import React, { useEffect, useState } from 'react'


import mscStudent from '../../assests/mscStudent.json'
import axios from 'axios'
import Spinner from '../facultydashboard/formGroup/Spinner'
function AlumniList() {
    const [datas, setData] = useState({})
  const [loader,setLoader] = useState(true)
  // console.log(datas)

  const [stdata, setStdata] = useState([]);

  const student = () => {
    axios
      .get("/api/v1/almuni-registration-data/accept")
      .then(function (response) {
        // handle success
        //console.log(response.data);
        setStdata(response.data);
        setLoader(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  useEffect(() => {
    student();
  }, [])
  return (
    <div>
    {
      loader && <Spinner/>
    }

      <div className='  md:mx-16 my-4 md:grid '>
          <div className=''>
      <div class="overflow-x-auto rounded-lg border border-gray-200">
<table class="min-w-full divide-y-2 divide-gray-200 text-sm">
  <thead>
    <tr>
      <th
        class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
      >
        Name
      </th>
      <th
        class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
      >
        Program

      </th>
      <th
        class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
      >
        Session
      </th>
      <th
        class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
      >
        Pass out
      </th>
    </tr>
  </thead>
  <tbody class="divide-y divide-gray-200">
  {
      stdata.almuniFeedback?.map((e)=>{
          return(



              <>
                                      <tr className="bg-white border-b  dark:border-gray-700">

                                          <td className="px-6 py-4">{e.name}</td>
                                          <td className="px-6 py-4">{e.program}</td>
                                          <td className="px-6 py-4">{e.session}</td>
                                          <td className="px-6 py-4">{e.yearOfDegree}</td>


                                      </tr>
                                  </>

)
})
}

</tbody>
</table>
</div>
</div>
</div>


  </div>
  )
}

export default AlumniList