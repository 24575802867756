import React from 'react'

function Publication(props) {
  return (
    <div>
      <div className='  ' style={{ width: '100%' }}>

<h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Publications</h1>
{props.data.publication?.map((a,i)=>{
  return(
    <>

    <div className='flex  items-center justify-between mt-7' style={{ width: '100%' }}>
    <article className="rounded-xl bg-white p-4 ring ring-indigo-50 sm:p-6 lg:p-8">
  <div className="flex items-start sm:gap-8">


    <div>


      <h3 className="mt-4 text-lg font-medium sm:text-xl">
        <a href="" className="hover:underline">{a.title} </a>
      </h3>

      <p className="mt-1 text-sm text-gray-700">

      </p>

      <div className="mt-4 sm:flex text-end sm:items-center sm:gap-2">
        <div className="flex items-center gap-1 text-gray-500">


          <p className="text-xs font-medium"></p>
        </div>

        <span className="hidden sm:block" aria-hidden="true">&middot;</span>

        <p className="mt-2 text-xs font-medium text-gray-500 sm:mt-0">
           <a href="#" className="underline hover:text-gray-700">{a.author}</a>,
          <a href="#" className="underline hover:text-gray-700">{a.workType} </a>
          <a href="#" className="underline hover:text-gray-700">{a.year}</a>
        </p>
      </div>
    </div>
  </div>
</article>
  {/* <div className='edu1'>{a.workType}  {a.journal} {a.title} {a.volume} {a.page}

  </div>
  <div className='edu2'>{a.author} {a.year}</div>
</div> */}
</div>
<hr className='facultyHr' />

    </>
  )
})}



</div>
    </div>
  )
}

export default Publication
