import React, { useState } from "react";

function AlumniFeedBackQues({handleChange,value}) {
    const questions = {
      q0:"",
        q1:"",
        q2:"",
        q3:"",
        q4:"",
        q5:"",
        q6:"",
        q7:"",
        q8:"",
    }
    const [question , setQuestion] = useState(questions)
    const [achievement , setAchievement] = useState('')


  return (
    <div>
      <div className=" grid grid-cols-1 justify-items-center w-1/2 gap-x-4 gap-y-2 ">
        <div className="col-span-full">
          <label for="question">
            Special Achievement (STET, CTET, SLET, NET, ICSSR, GET/ others)
            विशेष उपलब्धि (STET, CTET, SLET, NET, ICSSR, GET/ अन्य)
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
            value={value.q0}
            onChange={
              handleChange('q0')
            }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="STET">
              STET
            </option>
            {/* <option className="" value='1'>Admin</option> */}
            <option className="" value="CET">
              CET
            </option>
            <option className="" value="SLET">
              SLET
            </option>
            <option className="" value="NET">
              NET
            </option>
            <option className="" value="ICSSR">
              ICSSR
            </option>
            <option className="" value="GET">
              GET
            </option>
            <option className="uppercase" value="others">
              others
            </option>
          </select>
        </div>
        <div>
          <label for="question">
            1. How much do you rate the current syllabus in terms of subject
            relevance? विषय प्रासंगिकता के संदर्भ में आप वर्तमान पाठ्यक्रम को
            कितना आंकते हैं/ अंक देते हैं?
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
             value={value.q1}
             onChange={
               handleChange('q1')
             }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
            2. How much would you rate the quality of education imparted in the
            department? आप विभाग में प्रदान की जाने वाली शिक्षा की गुणवत्ता को
            कितना आंकेगे / अंक प्रदान करेंगे?
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
                value={value.q2}
                onChange={
                  handleChange('q2')
                }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
            3. How would you rate the course content delivery? आप पाठ्यक्रम
            सामग्री प्रस्तुतीकरण को कैसे गणना करेंगे? / को कितना अंक प्रदान
            करेंगे?
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
               value={value.q3}
               onChange={
                 handleChange('q3')
               }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
            4. How would you rate the Innovative Teaching- Learning
            methodologies on course curriculum for fulfilling your expectations?
            आप अपनी अपेक्षाओं को पूरा करने के लिए पाठ्यक्रम/पाठ्यचर्या पर अभिनव
            शिक्षण-अधिगम पद्धतियों को कितना आंकेगे / अंक प्रदान करेंगे?
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
                value={value.q4}
                onChange={
                  handleChange('q4')
                }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
            5. How do you rate the academic initiatives taken by the department
            to bridge the gap between society & academia? आप समाज और शिक्षा के
            बीच की खाई को पाटने के लिए विभाग द्वारा की गई शैक्षणिक पहल को कितना
            आंकेगे / अंक प्रदान करेंगे
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
                value={value.q5}
                onChange={
                  handleChange('q5')
                }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
            6. How do you rate the relevance of your degree to your present job?
            आप अपनी वर्तमान नौकरी के लिए अपनी डिग्री की प्रासंगिकता को कितना अंक
            प्रदान करेंगे?
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
            value={value.q6}
            onChange={
              handleChange('q6')
            }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
            7. How would you rate the motivation created by the syllabus to
            pursue higher education? उच्च शिक्षा प्राप्त करने के लिए पाठ्यक्रम
            की प्रभावशीलता/ अभिप्रेरणा को आप कितना अंक प्रदान करेंगे?
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
             value={value.q7}
             onChange={
               handleChange('q7')
             }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
        <div>
          <label for="question">
            8.How do you rate development activities organized by the
            college/department for your overall development? आप अपने समग्र विकास
            के लिए कॉलेज द्वारा आयोजित विकास गतिविधियों का मूल्यांकन कैसे करते
            हैं/अंक प्रदान करेंगे?
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <select
             value={value.q8}
             onChange={
               handleChange('q8')
             }
            id="category"
            class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
          >
            <option className="" value="">
              -----Select-----
            </option>
            <option className="" value="5">
              Excellent उत्कृष्ट -5
            </option>

            <option className="" value="4">
              Very Good बहुत अच्छा – 4
            </option>
            <option className="" value="3">
              Good अच्छा - 3
            </option>
            <option className="" value="2">
              Average औसत – 2
            </option>
            <option className="" value="1">
              Poor खराब/ निम्न– 1
            </option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default AlumniFeedBackQues;
