import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'

import axios from 'axios';

import AddResearchTopic from './AddResearchTopic';
import AddResearchTopicTable from './AddResearchTopicTable';

function AddResearchTopicPrev() {
    const [data, setData] = useState([]);
  // console.log(data);
  const student = () => {
    axios
      .get("/api/v1/research/get-all-research-topic")
      .then(function (response) {
        // handle success
        //console.log(response.data);
        setData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  useEffect(() => {
    student();
  }, [])
  return (
    <div>
    <div style={{ width: '100%', }}>
      <div>
        <div style={{ width: '100%', height: '100vh', overflow: 'scroll' }}>
          <Navbar />
          <div className='flex bg-[#F4F4F5]' style={{ width: '100%' }}>
            <Sidebar />
            <div className='' style={{ width: '100%' }}>
              <AddResearchTopic viewAllStudent={student} />
              <AddResearchTopicTable data={data} viewAllStudent={student} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AddResearchTopicPrev