import React, { useEffect, useState } from 'react'
import {TfiAnnouncement} from 'react-icons/tfi'
import {MdEventAvailable} from 'react-icons/md'
import {GrFormNext} from 'react-icons/gr'
import {data,Newsdata} from './NoticeData'
import HeroSection from './HeroSection'
import Footer from './Footer'
import Topbar from './page/landing/Topbar'
import Navbar from './components/Navbar'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Spinner from './page/facultydashboard/formGroup/Spinner'
import blink from './assests/download.gif'
function NoticeFull() {
    const [notice, setNotice] = useState([])
    const [anncmnt, setAnncmnt] = useState([])

    const [loader, setLoader] = useState(true)
    // console.log("j", notice)
    const siteData = () => {
      axios.get('/api/v1/find-file-news')
        .then(function (response) {
          setNotice(response.data)
          setLoader(false)
        })
        .catch(function (error) {
          console.log(error);
        })
    }
    useEffect(() => {
      siteData()
    }, [])
    const annData = () => {
      axios.get('/api/v1/find-file-anouncement')
        .then(function (response) {
          setAnncmnt(response.data)
          setLoader(false)
        })
        .catch(function (error) {
          console.log(error);
        })
    }
    useEffect(() => {
      annData()
    }, [])
    const [datas, setData] = React.useState({})
  // console.log(datas)
  const sitesData = () => {
    axios.get('/api/v1/api/site/data')
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0])
        setLoader(false)

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }
  React.useEffect(() => {
    sitesData()
  }, [])

  return (
    <>
    {
      loader && <Spinner/>
    }
    <Topbar/>
    <Navbar datas={datas}/>
      <div className='bg-[#e7e7e]'>
        <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 m-2 sm:m-[4.3rem]'>
            <div className='border border-black bg-white rounded-md noticeBorder'>

                <center><TfiAnnouncement className='text-[#d12326] text-5xl font-bold m-4'/></center>
               <h1 className='text-center font-bold text-2xl mb-8'>ANNOUNCEMENTS</h1>
               <div>
               {
                anncmnt.toReversed().map((e,i)=>{
                    return(
                      <>
                       {e.publish &&  <div className='flex mt-4 ml-6 mb-2 mr-6 items-start gap-2'>
                    <div className='border rounded-3xl bg-gray-400 p-0 mt-1'><GrFormNext className='font-bold'/></div>
                    <Link to={`/api/v1/download-anouncement/${e.fileName}`} target='_blank' className='text-[#d12326] flex'>{e.title} {i  < 3  &&<><sup><img src={blink}/></sup></> }</Link>
                </div>}
                       </>
                    )
                })
               }
               </div>
            </div>
            <div className='border border-black bg-white rounded-md noticeBorder'>
            <center><MdEventAvailable className='text-[#d12326] text-5xl font-bold m-4'/></center>
            <h1 className='text-center font-bold text-2xl mb-8'>NEWS & EVENTS</h1>
            <div>
               {
                notice.toReversed().map((e,i)=>{
                    return(
                      <>
                      {e.publish &&  <div className='flex mt-4 ml-6 mb-2 mr-6 items-start gap-2'>
                   <div className='border rounded-3xl bg-gray-400 p-0 mt-1'><GrFormNext className='font-bold'/></div>
                   <Link to={`/api/v1/download-news/${e.fileName}`} target='_blank' className='text-[#d12326] flex'>{e.title} {i  < 3  &&<><sup><img src={blink}/></sup></> }</Link>
               </div>}
                     </>
                    )
                })
               }
               </div>

            </div>

        </div>



    </div>
    <Footer datas={datas}/>
    </>
  )
}

export default NoticeFull