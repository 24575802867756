import React from 'react'
import { HiEnvelope } from "react-icons/hi2";
import { HiLocationMarker } from "react-icons/hi";
import { ImPhone } from "react-icons/im"
import { useNavigate } from 'react-router-dom';
function Card(props) {
  console.log(props)
  const navigate = useNavigate()
  return (
    <div>
      <section class="text-black ">
        <div class="container px-5 pt-10 mx-auto ">

          <div class="flex flex-wrap ">

            <div class="w-full">
<div class="bg-gray-100  rounded-lg shadow-lg p-4" style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                <img class="h-64  rounded-t-xl w-full object-cover 2xl:object-contain object-center mb-6" src={props.img ? props.img : "https://www.shutterstock.com/image-photo/man-poses-passport-photo-260nw-207377266.jpg"} alt="content" />
                <div className='flex justify-center flex-col items-center'>
                  <h2 class="text-lg text-gray-900 font-semibold title-font ">{props.title} {props.name}</h2>
                  <h3 class="tracking-widest text-gray-900 text-xs font-medium title-font ">{props.profession}</h3>
                  <div className='flex flex-col item-start'>

                  <h3 class="tracking-widest text-gray-900 text-xs font-bold title-font"><span className='text-[#9C060C]'>Email:-</span>{props.email}</h3>
                  <div className='flex flex-col items-center'>
                  <h3 class="tracking-widest text-gray-900 text-xs font-semibold title-font mb-4"><span className='text-[#9C060C]'>Mob :-</span>{props.mobile}</h3></div>
                  </div>

                  <span class="leading-relaxed text-base pb-5 w-3/4"><button onClick={()=>{navigate(`/faculty/profile/${props.id}`)}} type="button" className="px-8 py-1.5 font-semibold border rounded bg-[#9C060C] text-gray-100 w-full">Know More</button></span>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>

      {/* <a
        href="#"
        class="relative block overflow-hidden rounded-sm border-2 border-gray-200 p-8 my-4 w-auto md:w-96 shadow-lg"
      >


        <div class="justify-between sm:flex  ">
          <div class="  flex-shrink-0 block">
            <img
              alt=""
              src={props.img ? props.img : "https://www.shutterstock.com/image-photo/man-poses-passport-photo-260nw-207377266.jpg"}
              class="h-24 w-24 rounded-lg object-cover shadow-sm"
            />
          </div>
          <div className='ml-1'>
            <h3 class="text-xl font-bold text-gray-900">
              {props.name}
            </h3>

            <p class="mt-1 text-xs font-medium text-gray-600">{props.profession}</p>
            <span className='grid grid-cols-2 items-end'><p class="mt-1 text-xs font-medium text-gray-600 ">{props.email} </p><p><HiEnvelope /></p></span><HiLocationMarker />

            <span className='grid grid-cols-2 items-center justify-start'><p class="mt-1 text-xs font-medium text-gray-600">{props.mobile}</p><p><ImPhone /></p></span>

          </div>


        </div>

        <div class="mt-4 sm:pr-8  h-20">
          <p class="text-sm text-gray-500">
            {props.about}
          </p>
        </div>

        <dl class="mt-6 grid-cols-3">
          <div class="">

          </div>

          <div class="ml-3 flex justify-end sm:ml-6 col-span-2  ">
            <button onClick={() => { navigate('/faculty/profile') }} className='bg-[#d12326] pt-2 pb-2 pl-4 pr-4 text-white text-[0.8rem] rounded mt-4 mb-4'>KNOW MORE </button>

          </div>
        </dl>

      </a> */}
    </div>


  )
}

export default Card