import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import axios from "axios";
import { message } from "antd";
import Spinner from "../../page/facultydashboard/formGroup/Spinner";
function AboutDept() {
  const [aboutContentTitle, setaboutContentTitle] = useState("");
  const [aboutbottomContent, setaboutbottomContent] = useState("");
  const [selectedDeptImage, setSelectedDeptImage] = useState(null);
  const [hodImage, setHodImage] = useState("");
  const [deptImage, setDeptImage] = useState("");

  // console.log(hodImage, deptImage)
  // get data

  const [data, setData] = React.useState({});
  const [loader, setLoader] = useState(false);
  // console.log(data)
  const siteData = () => {
    setLoader(true);
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setLoader(false);
        setData(response.data[0]);
        setaboutContentTitle(response.data[0].aboutContentTitle);
        setaboutbottomContent(response.data[0].aboutbottomContent);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoader(false);
      });
  };

  const handleDeptImageChange = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("img", deptImage);
    setLoader(true);
    axios
      .put(`/api/v1/api/site/about-content/${data._id}`, formData)
      .then(function (response) {
        setLoader(false);
        message.success(response.data.message);
        siteData();
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error);
        message.error(error.response.data.errors);
      });
  };
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("img", hodImage);
    setLoader(true);
    axios
      .put(`/api/v1/api/site/landing-content/${data._id}`, formData)
      .then(function (response) {
        setLoader(false);
        message.success(response.data.message);
        siteData();
      })
      .catch(function (error) {
        setLoader(false);
        message.error(error.response.data.errors);
      });
  };
  // console.log(props.data.data.aboutContentTitle)
  const update = () => {
    setLoader(true);
    axios
      .put(`/api/v1/api/site/about-content-title/${data._id}`, {
        aboutContentTitle: aboutContentTitle,
        aboutbottomContent,
      })
      .then(function (response) {
        setLoader(false);
        // console.log(response);
        message.success(response.data.message);
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error);
        message.error("something went wrong");
      });
  };

  const setPrevValue = () => [setPrevValue()];

  useEffect(() => {
    siteData();
  }, []);

  return (
    <div>
      {loader && <Spinner />}
      <Breadcrumbs
        page="aboutDept"
        pagelink="#"
        sublink="#"
        subpage="dashboard"
      />

      <div class="mx-auto  px-4 py-8">
        <section class="rounded-lg bg-white p-8">
          <div class="grid grid-cols-1 gap-12 sm:grid-cols-3 sm:items-center">
            {/* . */}
            <blockquote class="sm:col-span-2">
              <div>
                <label
                  for="message"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Top Content Area
                </label>
                <textarea
                  value={aboutContentTitle}
                  onChange={(e) => {
                    setaboutContentTitle(e.target.value);
                  }}
                  id="message"
                  rows="4"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write message here..."
                ></textarea>
              </div>

              <div className="mt-7">
                <label
                  for="message"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Director's Message
                </label>
                <textarea
                  value={aboutbottomContent}
                  onChange={(e) => {
                    setaboutbottomContent(e.target.value);
                  }}
                  id="message"
                  rows="4"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write message here..."
                ></textarea>
              </div>
            </blockquote>
            <div className="flex gap-5">
              <div className="h-full w-full">
                <h1 className="font-semibold">H.O.D Image</h1>

                <img
                  src={`/api/v1/media/${data.landingContentImg}`}
                  alt="Preview"
                  className="w-full h-52 rounded "
                />
                <input
                  class="block text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none  dark:border-gray-600 dark:placeholder-gray-400 mt-4 w-full p-2 "
                  aria-describedby="user_avatar_help"
                  id="user_avatar"
                  type="file"
                  onChange={(e) => {
                    setHodImage(e.target.files[0]);
                  }}
                />
                <center>
                  <button
                    onClick={handleImageChange}
                    type="button"
                    class="mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                  >
                    Update
                  </button>
                </center>
              </div>
              <div className="h-full w-full">
                <h1 className="font-semibold">Dept Image</h1>

                <img
                  src={`/api/v1/media/${data.aboutContentImg}`}
                  alt="Preview"
                  className="w-full h-52 rounded "
                />
                <input
                  class="block text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:border-gray-600 dark:placeholder-gray-400 mt-4 w-full p-2 "
                  aria-describedby="user_avatar_help"
                  id="user_avatar"
                  type="file"
                  onChange={(e) => {
                    setDeptImage(e.target.files[0]);
                  }}
                />
                <center>
                  <button
                    onClick={handleDeptImageChange}
                    type="button"
                    class="mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                  >
                    Update
                  </button>
                </center>
              </div>
            </div>
          </div>
        </section>
        <div className="flex justify-center mt-4">
          <button
            onClick={update}
            type="button"
            class="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
}

export default AboutDept;
