import React, { useState } from "react";
import { Alert, Dropdown, Input } from "../../formGroup/Index";
import axios from "axios";
export default function TeacherEdit(props) {
  console.log(props.editFaculty);
  const [almuniName, setAlmuniName] = useState(props.editFaculty.almuniName);
  const [photo, setPhoto] = useState(props.editFaculty.photo);

  const [email, setEmail] = useState(props.editFaculty.email);
  const [mobileNo, setMobileNo] = useState(props.editFaculty.mobileNo);
  const [dob, setDob] = useState(props.editFaculty.dob);
  const [programName, setProgramName] = useState(props.editFaculty.programName);
  const [session, setSession] = useState(props.editFaculty.session);
  const [yearOfAdmission, setYearOfAdmission] = useState(props.editFaculty.yearOfAdmission);
  const [gender, setGender] = useState(props.editFaculty.gender);
  console.log( "props ",props)
  const editUser = () => {
    axios
      .put(`/api/v1/teacher-feedback/${props.editFaculty.id}`, {
        almuniName,
        gender,
        mobileNo,
        email,

      })
      .then(function (response) {
        // console.log(response);
        alert(response.data.message);
        props.setShowModal(false);
        props.updateData();
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.errors);
      });
  };

  return (
    <>
      {props.showModal ? (
        <>
          <div className="justify-center  items-center flex  overflow-y-auto overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-10/12 h-[90%] my-6 mx-auto   ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start gap-2   justify-between p-5 border-b border-solid border-slate-200 rounded-t">

                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="flow-root">

  <dl className="m-3 divide-y px-12 grid grid-cols-2 gap-4 divide-gray-100 text-sm">


    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Name</dt>
      <dd className="text-gray-700 sm:col-span-2">{props.editFaculty.teacherName}</dd>
    </div>
    <div
      className="grid grid-cols-1  gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium  text-gray-900">Department</dt>
      <dd className="text-gray-700 sm:col-span-2">{props.editFaculty.department}</dd>

    </div>


    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Designation</dt>
      <dd className="text-gray-700 sm:col-span-2">{props.editFaculty.designation}</dd>
    </div>

    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Programme</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.programme}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Course Name</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.courseName}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Course Code </dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.courseCode}
      </dd>
    </div>



    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Year / Semester</dt>
      <dd className="text-gray-700 sm:col-span-2">
{props.editFaculty.semester}
      </dd>
    </div>




    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-4 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-2 text-gray-900">Suggestion</dt>
      <dd className="text-gray-700 sm:col-span-2">
{props.editFaculty.suggestion}
      </dd>
    </div>


    <div className="col-span-full  divide-y font-bold gap-4 divide-gray-100 text-sm">
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">1. Aims, objectives and outcomes of the syllabus are well defined and clear to teachers.<br/> पाठ्यक्रम के उद्देश्य और परिणाम से शिक्षक भली भांति परिचित हैं।* --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.aimsObjectives}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">2. Syllabus is sufficient to bridge the gap between industry standards /current global scenarios and academia. यह पाठ्यक्रम/कोर्स वैश्विक मानकों के अनुरूप उद्योग और अकादमिक जगत को जोड़ने में सहायक है</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.syllabusSufficient}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">3. Learning value (in terms of skills, concepts, knowledge, analytical abilities or broadening perspectives). अधिगम/सीखने के मूल्य (कौशल, अवधारणाओं, ज्ञान, विश्लेषणात्मक क्षमताओं या व्यापक दृष्टिकोण के संदर्भ में) हैं।</dt>
      <dd className="text-gray-700 justify-items-end sm:col-span-2">
        {props.editFaculty.learningValue}
      </dd>
    </div>

    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">4. The curriculum is applicable/relevant to real life situations. पाठ्यक्रम वास्तविक जीवन स्थितियों के लिए प्रयोज्य/प्रासंगिक है।</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.curriculumnApplicable}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">5. Present syllabus ensures active engagement of students & other stake holders. वर्तमान पाठ्यक्रम छात्रों/ विद्यार्थी और अन्य संबंधितों की सक्रिय भागीदारी/सहभागिता सुनिश्चित करता है।</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.presentSyllabus}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">6. Syllabus has flexibility to adopt new techniques/strategies of teaching such as presentations, group discussions and learners’ participations. पाठ्यक्रम में शिक्षण की नई तकनीकों/रणनीतियों जैसे प्रस्तुतिकरण, समूह चर्चा आदि में शिक्षार्थियों को शामिल करने के पर्याप्त अवसर है</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.syllabusFlexibility}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">7. Timely coverage of syllabus is possible in the mentioned number of hours. शिक्षण के निर्धारित घंटों के अनुसार पाठ्यक्रम को ससमय में पूरा किया जा सकता है।</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.timelyCoverage}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">8. The evaluation methods mentioned in the syllabus are sufficient for providing proper assessment. पाठ्यक्रम में वर्णित पद्धतियाँ समुचित मूल्यांकन के लिए पर्याप्त हैं| --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.evolutionMethod}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">9. The books prescribed/listed as reference materials are relevant, updated and appropriate. संदर्भ सामग्री के रूप में निर्धारित/सूचीबद्ध पुस्तकें प्रासंगिक, अद्यतन और उपयुक्त हैं। --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.bookPrescribed}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">10. Overall Rating for curriculum पाठ्यक्रम के लिए समग्र रेटिंग --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.overallRating}
      </dd>
    </div>
    </div>
  </dl>
</div>
                {/*footer*/}
                <div className="flex items-center justify-end gap-x-4 p-6 border-t border-solid border-slate-200 rounded-b">
                  <div className="flex-1">


                  {/* <button
                    className="text-red-500  background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => props.setShowModal(false)}
                  >
                    Close
                  </button> */}
                  </div>
                  <div className="">
                  <button
                    className="bg-red-500 mx-4 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => props.setShowModal(false)}
                  >
                    Close
                  </button>
                  {/* <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => editUser()}
                    // onClick={() => props.setShowModal(false)}
                  >
                    Accept
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
