import { message } from 'antd';
import axios from 'axios';
import React, { useState,useEffect } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import {Input, Dropdown} from '../../formGroup/Index'
import Spinner from '../../page/facultydashboard/formGroup/Spinner';

function Notice(props) {
const [pdf ,setPdf]=useState('')
const [title ,setTitle]=useState('')
const [loader, setLoader] = useState(false)
const [data, setData] = React.useState({})
const addAnnoucement = (event) => {
  event.preventDefault()
  if (!pdf || !title ) {
    message.error('All Field are required')
  }
  else {
    const formData = new FormData();
    formData.append('pdf', pdf);
    formData.append('title', title);
    setLoader(true)

    axios.post(`/api/v1/news/upload`, formData,)
      .then(function (response) {
        setLoader(false)
        message.success(response.data.message)
        setPdf("")
        setTitle("")
        props.onClick()
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        message.error(error.response.data.errors)

      });
  }

}



// get data
const siteData = () => {
  setLoader(true)
  axios.get('/api/v1/api/site/data')
    .then(function (response) {
      // handle success
      // console.log(response);
      setLoader(false)
      setData(response.data[0])
    })
    .catch(function (error) {
      // handle error
      console.log(error);
      setLoader(false)
    })
}

React.useEffect(() => {
  siteData()
}, [])
  return (
    <div>
    {
      loader && <Spinner />
    }
        <Breadcrumbs page={"News & Events"} pagelink="#" sublink='#' subpage={"Content Page "}/>
        <div className=' grid gap-2 rounded-md mx-4  px-8 py-4 bg-white grid-cols-1 w-2/4 my-auto'>
        <div className='col-span-full'>

        <Input label={"Title"} value={title} onChange={(e) => { setTitle(e.target.value)}} inputCss={" h-12"}/>
        </div>
        <div className='col-span-full'>

        <Input type="file"  accept=".pdf" onChange={(e) => { setPdf(e.target.files[0]) }} label={"upload pdf (20mb max)"} inputCss={" h-12"}/>
        </div>
                        {/* action button */}


        <div className='  m-auto'>
        <h1 className='opacity-0'>k</h1>
        <button className='mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2' onClick={addAnnoucement}>Upload</button>
        </div>
        </div>
    </div>
  )
}

export default Notice