import React from 'react'

function WelcomMsg() {
  return (
    <div>
        <div className="px-8  bg-white text-gray-900">
	<div className="flex items-center mx-auto container gap-x-2 justify-start py-2">
		<div>
			
			<h1  className=" text-xl font-black text-gray-900">Welcome, Admin</h1>
		</div>
		{/* <Link to="/study-material" rel="noopener noreferrer" className="items-center gap-2  md:flex">
			
			<span className="hover:underline focus-visible:underline">Study material</span>
		</Link>
        {/* <a href="#" rel="noopener noreferrer" className="items-center gap-2  md:flex">
			
			<span className="hover:underline focus-visible:underline">Lectures</span>
		</a> */}
        {/* <a href="#" rel="noopener noreferrer" className="items-center gap-2  md:flex">
			
			<span className="hover:underline focus-visible:underline">Webmail</span>
		</a>  */}
	</div>
</div>
    </div>
  )
}

export default WelcomMsg