import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "./formGroup/Spinner";
import { useNavigate } from "react-router-dom";
import logo from "../../assests/NewLogo.png";
import { Link } from "react-router-dom";
import { message } from "antd";
import Spinner from "./formGroup/Spinner";
function ForgotPassword() {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  console.log(email);
  const handleForgetPassword = () => {
    setLoader(true);

    axios
      .post(`/api/v1/forgot-password`, { email })
      .then((response) => {
        message.success(response.data.message);
        setLoader(false);
        alert("check your email inbox");
      })
      .catch((error) => {
        console.log(error);
        message.warning(error.message);
        setLoader(false);
      });
  };
  return (
    <div className="flex h-screen bg-[#000428]">
      {loader && <Spinner />}
      <div className="flex m-auto flex-col w-5/6 md:w-full max-w-md p-12 space-y-4 text-center bg-white text-gray-800 ">
        <div className="flex justify-center">
          <img src={logo} alt="" className="h-20 w-20 " />
        </div>
        <a className="text-sm text-gray-600" href="/">
          Enter your email address and we'll send you an email with instructions
          to reset your password.
        </a>
        <div className="space-y-4 ng-untouched ng-pristine ng-valid">
          <div className="flex gap-1 flex-col">
            <label for="email" className="sr-only">
              Email address
            </label>
            <input
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              type="email"
              placeholder="Email address"
              className="rounded-md border-gray-400 bg-gray-50 text-gray-800 focus:ring-[#000428] focus:border-[#000428] focus:ring-2"
            />
          </div>

          <button
            type="submit"
            onClick={handleForgetPassword}
            className="px-8 py-3 space-x-2 font-semibold rounded bg-[#000428] text-gray-50"
          >
            Reset Password
          </button>
          <div className="flex justify-center">
            <span>Back to </span>
            <span>
              <Link
                className="text-sm text-black font-semibold pl-1"
                to="/login"
              >
                Sign in
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
