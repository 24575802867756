import React,{useState} from 'react'
import {Input, Dropdown} from '../formGroup/Index'

function Phd() {
    const [edustate, setEduState]=useState(false)
    const initialState={
      name:"",
      status:"",
      cmptdate:"",
      topic:"",
    
  }
  const sts = ["---SELECT STATUS---","ONGOING","ENDED"]
    const [editState,setEditState]=useState(false)
    const [state, setState] = useState(initialState)
    const [tde, setTde]=useState([])
    const onChange = (e) => {

        setState({ ...state, [e.target.name]: e.target.value })
    }
    const addmore = (e)=>{
      setTde([...tde, {  "name": state.name, "status": state.status,"topic": state.topic, "topic":state.topic, "cmptdate":state.cmptdate, "status":state.status }]);

        setEduState(false)
        setState({  [e.target.name]:"" })
    }
    const removeBatch = (id) => {
        const updateList = tde.filter((elm, ind) => {
            return ind !== id;
        });
        setTde(updateList)
    }
    const editA=()=>{
        setEditState(true)
        
    }
    const [hdn, setHdn]=useState(false)

    const [k, setK]=useState("hidden")
    const addD=()=>{
        setEduState(!edustate)
        setHdn(!hdn)
        if(hdn===false){
            setK("block")
            
        }
        else{
            setK("hidden")
        }
    }
  return (
    <>
 <div className=' '>
       <div className='   ' style={{ width: '100%' }}>
                            <div className='flex space-x-2 justify-between '>

                            <h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Phd Students</h1>
                            <div className='animate-fade  gap-2'>

<button className='mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none 'onClick={addD} >Add Manually</button>

<input

      id="photobutton"
      type="file"
      className="  cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
    />
    
</div>
</div>
                            
{edustate ? <>
                            <div className='grid grid-cols-2 space-x-2 justify-between gap-2'>
                            <div className='w-full'>

                            <Input placeholder=""  label="Student Name" name="name" type="text" value={state.name} onChange={onChange} />
                            </div>
                            <Input placeholder="xxxx-xxxx"  label="Topic name" name="topic" type="text" value={state.topic} onChange={onChange} />
                            
                     <Dropdown data={sts} placeholder=""  label="Status" name="status"  value={state.status} onChange={onChange} />
                            {
                              state.status === "ENDED"?<>
                              <Input placeholder=""  label="Ended on" name="cmptdate" type="date" value={state.cmptdate} onChange={onChange} />
                              
                              </>:<div></div>
                            }
                            
                            
                           
                            <button className='mt-auto px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none'onClick={addmore} >submit</button>
                            <button className={`${k} mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`} onClick={addD}>close</button>
                            </div>
                                   
    

                            </>:null

                            }
                                <div class="overflow-x-auto mt-2 rounded-lg border border-gray-200">
  <table class="min-w-full divide-y-2 divide-gray-200 text-sm">
    <thead>
      <tr>
        <th
          class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
        >
          Name
        </th>
        <th
          class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
        >
          topic

        </th>
        <th
          class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
        >
          Status
        </th>
        <th
          class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
        >
          Ended on
        </th>
      </tr>
    </thead>

                            
                                {
                                    tde.map((a ,ind)=>{
                                        return(
                                            <tbody class="divide-y divide-gray-200" key={ind}>
                                            <tr>
                                              <td class="whitespace-nowrap  border px-4 py-2 font-medium text-gray-900">
                                                {a.name}
                                              </td>
                                              <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">{a.topic}</td>
                                              <td class="whitespace-nowrap border px-4 py-2 text-gray-700">{a.status}</td>
                                            
                                          <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">{a.cmptdate}</td>
                                          <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">
                                          <button className='  px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-emerald-400 hover:text-black border-2 border-green-900 focus:outline-none' onClick={editA}>edit</button>
                                            
                                             </td>
                                          <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">
                                          <button className=' px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none'  onClick={() => removeBatch(ind)}>delete</button>

                                          </td>
                                          
                                        
                                        
                                            </tr>
                                      
                                            
                                      
                                            
                                          </tbody>
                                        )
                                    })
                                }
                            

                               </table>
                           
                                
                            
                        </div>
    </div> 

    </div>

    </>
  )
}

export default Phd