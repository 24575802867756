import React, { useState } from "react";
import { Alert, Dropdown, Input } from "../../formGroup/Index";
import axios from "axios";
export default function Edit(props) {
  console.log(props.editFaculty);
  const [almuniName, setAlmuniName] = useState(props.editFaculty.almuniName);
  const [photo, setPhoto] = useState(props.editFaculty.photo);

  const [email, setEmail] = useState(props.editFaculty.email);
  const [mobileNo, setMobileNo] = useState(props.editFaculty.mobileNo);
  const [dob, setDob] = useState(props.editFaculty.dob);
  const [programName, setProgramName] = useState(props.editFaculty.programName);
  const [session, setSession] = useState(props.editFaculty.session);
  const [yearOfAdmission, setYearOfAdmission] = useState(props.editFaculty.yearOfAdmission);
  const [gender, setGender] = useState(props.editFaculty.gender);
  console.log( "props ",props)
  const editUser = () => {
    axios
      .put(`/api/v1/almuni-feedback-data/${props.editFaculty.id}`, {
        almuniName,
        gender,
        mobileNo,
        email,

      })
      .then(function (response) {
        // console.log(response);
        alert(response.data.message);
        props.setShowModal(false);
        props.updateData();
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.errors);
      });
  };

  return (
    <>
      {props.showModal ? (
        <>
          <div className="justify-center  items-center flex  overflow-y-auto overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-10/12 h-[90%] my-6 mx-auto   ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start gap-2   justify-between p-5 border-b border-solid border-slate-200 rounded-t">

                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="flow-root">

  <dl className="m-3 divide-y px-12 grid grid-cols-2 gap-4 divide-gray-100 text-sm">


    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Name</dt>
      <dd className="text-gray-700 sm:col-span-2">{props.editFaculty.almuniName}</dd>
    </div>
    <div
      className="grid grid-cols-1  gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium  text-gray-900">Gender</dt>
      <dd className="text-gray-700 sm:col-span-2">{props.editFaculty.gender}</dd>

    </div>


    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Mobile Number</dt>
      <dd className="text-gray-700 sm:col-span-2">{props.editFaculty.mobileNo}</dd>
    </div>

    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Email</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.email}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Program</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.programName}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Registration No</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.regNo}
      </dd>
    </div>



    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Year of Registration</dt>
      <dd className="text-gray-700 sm:col-span-2">
{props.editFaculty.YearOfReg}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">
Year of Passing</dt>
      <dd className="text-gray-700 sm:col-span-2">
{props.editFaculty.YearOfPass}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-4 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-2 text-gray-900">Current Orgganization/Institution name with Designation</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.currOrg}
      </dd>
    </div>

    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium  text-gray-900">Mailing Address</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.addressResOrg}
      </dd>
    </div>

    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-4 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-2 text-gray-900">Full address of the present organization</dt>
      <dd className="text-gray-700 sm:col-span-2">
{props.editFaculty.addressResOrg}
      </dd>
    </div>


    <div className="col-span-full  divide-y font-bold gap-4 divide-gray-100 text-sm">
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">Special Achievement (STET, CTET, SLET, NET, ICSSR, GET/ others)<br/> विशेष उपलब्धि (STET, CTET, SLET, NET, ICSSR, GET/ अन्य) --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.specAchievement}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">1. How much do you rate the current syllabus in terms of subject relevance?<br/> विषय प्रासंगिकता के संदर्भ में आप वर्तमान पाठ्यक्रम को कितना आंकते हैं/ अंक देते हैं? --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.rateCurrSyllabus}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">2. How much would you rate the quality of education imparted in the department? <br/>आप विभाग में प्रदान की जाने वाली शिक्षा की गुणवत्ता को कितना आंकेगे / अंक प्रदान करेंगे? --</dt>
      <dd className="text-gray-700 justify-items-end sm:col-span-2">
        {props.editFaculty.rateQualityEdu}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">3. How would you rate the course content delivery?<br/> आप पाठ्यक्रम सामग्री प्रस्तुतीकरण को कैसे गणना करेंगे? / को कितना अंक प्रदान करेंगे? --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.rateCourContent}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">4. How would you rate the Innovative Teaching- Learning methodologies on course curriculum for fulfilling your expectations?<br/> आप अपनी अपेक्षाओं को पूरा करने के लिए पाठ्यक्रम/पाठ्यचर्या पर अभिनव शिक्षण-अधिगम पद्धतियों को कितना आंकेगे / अंक प्रदान करेंगे? --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.rateInnovativeTeaching}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">5. How do you rate the academic initiatives taken by the department to bridge the gap between society & academia?<br/> आप समाज और शिक्षा के बीच की खाई को पाटने के लिए विभाग द्वारा की गई शैक्षणिक पहल को कितना आंकेगे / अंक प्रदान करेंगे --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.rateAcademicInitiatives}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">6. How do you rate the relevance of your degree to your present job?<br/> आप अपनी वर्तमान नौकरी के लिए अपनी डिग्री की प्रासंगिकता को कितना अंक प्रदान करेंगे? --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.rateRelevanceDegree}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">7. How would you rate the motivation created by the syllabus to pursue higher education?<br/> उच्च शिक्षा प्राप्त करने के लिए पाठ्यक्रम की प्रभावशीलता/ अभिप्रेरणा को आप कितना अंक प्रदान करेंगे? --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.rateMotivationSyllabus}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">8.How do you rate development activities organized by the college/department for your overall development?<br/> आप अपने समग्र विकास के लिए कॉलेज द्वारा आयोजित विकास गतिविधियों का मूल्यांकन कैसे करते हैं/अंक प्रदान करेंगे? --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.rateDevelopmentactivities}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-6 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-4 text-gray-900">Special Achievement (STET, CTET, SLET, NET, ICSSR, GET/ others) विशेष उपलब्धि (STET, CTET, SLET, NET, ICSSR, GET/ अन्य) --</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.specAchievement}
      </dd>
    </div>
    </div>
  </dl>
</div>
                {/*footer*/}
                <div className="flex items-center justify-end gap-x-4 p-6 border-t border-solid border-slate-200 rounded-b">
                  <div className="flex-1">


                  {/* <button
                    className="text-red-500  background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => props.setShowModal(false)}
                  >
                    Close
                  </button> */}
                  </div>
                  <div className="">
                  <button
                    className="bg-red-500 mx-4 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => props.setShowModal(false)}
                  >
                    Close
                  </button>
                  {/* <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => editUser()}
                    // onClick={() => props.setShowModal(false)}
                  >
                    Accept
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
