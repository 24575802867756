import React, { useState, useEffect } from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import Syllabus from './Syllabus'
import SyllabusTable from './SyllabusTable'
import axios from 'axios'
import Spinner from '../../page/facultydashboard/formGroup/Spinner'
function SyllabusPreview() {

  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)
  // console.log(data)
  const siteData = () => {
    axios.get('/api/v1/find-file-syllabus')
      .then(function (response) {
        setData(response.data)
      })
      .catch(function (error) {
        console.log(error);
      })
  }
  useEffect(() => {
    siteData()
  }, [])
  console.log(data)
  return (
    <div>
      {
        loader && <Spinner></Spinner>
      }
      <div style={{ width: '100%', }}>
        <div>
          <div style={{ width: '100%', height: '100vh', overflow: 'scroll' }}>
            <Navbar />
            <div className='flex bg-[#F4F4F5]' style={{ width: '100%' }}>
              <Sidebar />
              <div className='' style={{ width: '100%' }}>
                <Syllabus updateData={siteData}/>
                <div className='m-4'> <SyllabusTable data={data} updateData={siteData}/></div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default SyllabusPreview