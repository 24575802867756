import React, { useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs';
import Spinner from '../../page/facultydashboard/formGroup/Spinner';
import { message } from 'antd';
import axios from 'axios';

function SSR(props) {
    const [data, setData] = React.useState({});
    const [loader, setLoader] = useState(false);
    const [courses, setCourses] = useState("");
    const [titleOfFile, settitleOfFile] = useState("");
    const [pdfUpload, setpdfUpload] = useState("");
    const [criteria, setCriteria] = useState("")
    const uploadNaac = (event) => {
      event.preventDefault();
      if (!pdfUpload) {
        message.error("All Field are required");
      } else {
        const formData = new FormData();
        formData.append("pdf", pdfUpload);


        setLoader(true);

        axios
          .post(`/api/v1/naac/ssr/download/upload/${criteria}`, formData)
          .then(function (response) {
            setLoader(false);
            message.success(response.data.message);
            props.updateData();
          })
          .catch(function (error) {
            console.log(error);
            setLoader(false);
            message.error(error.response.data.errors);
          });
      }
    };

  return (
    <div>
      {loader && <Spinner />}
      <Breadcrumbs
        page="Naac SSR"
        pagelink="#"
        sublink="#"
        subpage={"Content Page"}
      />
      <div className="grid gap-2 rounded-md mx-4 px-8 py-4 bg-white sm:grid-cols-2 w-2/4 my-auto">
        <div className="col-span-full">
          <div>
            <label
              for="category"
              class={`block mb-2 text-sm font-medium text-gray-900  `}
            >
              Criteria
              <span className="text-red-500 font-bold text-[1.2rem]">*</span>
            </label>
            <select
              value={criteria}
              onChange={(e) => {
                setCriteria(e.target.value);
              }}
              id="category"
              class={`w-full h-9 mt-2 rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
            >
              <option className="" value="">
                -----Select-----
              </option>
              <option className="" value="1">
              Criteria 1
              </option>
              <option className="" value="2">
              Criteria 2
              </option>

              <option className="" value="3">
              Criteria 3
              </option>
              <option className="" value="4">
              Criteria 4
              </option>
              <option className="" value="5">
              Criteria 5
              </option>

              <option className="" value="6">
              Criteria 6
              </option>
              <option className="" value="7">
              Criteria 7
              </option>
            </select>
          </div>
        </div>


        <div className="col-span-full">
          <label
            className={`block mb-2 text-sm font-medium text-gray-900   `}
          >
            Upload PDF
            <span className="text-red-500 font-bold text-[1.2rem]">*</span>
          </label>
          <input
            type="file"
            // multiple={multiple}
            // name={name}
            // defaultValue={defaultValue}
            accept=".pdf,.xlsx"
            // value={pdfUpload}
            onChange={(e) => {
              setpdfUpload(e.target.files[0]);
            }}
            className={`w-full mt-2 rounded-sm border focus:border-gray-700 p-3 text-sm focus:outline-none focus:bg-white bg-gray-200`}
            placeholder=""
            // required='true'
          />
        </div>
        {/* action button *

        <div className="my-auto">
          <div className="flex gap-2">
            <label
              for="AcceptConditions"
              class="relative h-6 w-12 cursor-pointer"
            >
              <input
                type="checkbox"
                id="AcceptConditions"
                class="peer sr-only"
              />

              <span class="absolute inset-0 rounded-full bg-gray-300 transition peer-checked:bg-green-500"></span>

              <div></div>
              <span class="absolute inset-0 m-1 h-4 w-4 rounded-full bg-white transition peer-checked:translate-x-6"></span>
            </label>
            <h1>Publish</h1>
          </div>
          </div>*/}
          <div className="sm:hidden"></div>
        <div className="my-auto">
          <h1 className="opacity-0 mt-1">k</h1>
          <button
            className="inline-block mt-1  px-4 py-2 rounded border border-[#273272]   text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
            onClick={uploadNaac}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  )
}

export default SSR