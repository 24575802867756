import axios from 'axios'
import React, { useState } from 'react'

function Hod() {
  const [datas, setData] = React.useState({})
  const [loader,setLoader] = useState(false)
  // console.log(datas)
  const siteData = () => {
    axios.get('/api/v1/api/site/data')
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0])
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }
  React.useEffect(() => {
    siteData()
  }, [])
  return (
    <>
      <div className="py-16 bg-white">
    <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
      <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
        <div className="md:4/12 lg:w-4/12">
          <img
            src={`/api/v1/media/${datas.landingContentImg}`}
            alt="image"
            loading="lazy"
            width=""
            height=""
          />
        </div>
        <div className="md:7/12 lg:w-6/12">
          <h2 className="text-2xl text-red-600 font-bold md:text-4xl">
          Message from HOD
          </h2>
          <p className="mt-6 text-justify text-gray-600">
            {datas.aboutbottomContent}
          </p>
          <p className="mt-4 text-gray-600">
            {" "}
          </p>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}

export default Hod