import React from 'react'

function Honours(props) {
  // console.log(props.data.honours)
  return (
    <div>
          <div className='  ' style={{ width: '100%' }}>
          <h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Honours</h1>

          {props.data.honours?.map((r,t)=>{
  return(
<>
<div className='flex  items-center justify-between mt-7' style={{ width: '100%' }} >
    <div className='edu1'>{r.awardName} by {r.awardedBy}

    </div>
    <div className='edu2'>{r.awardedYear} </div>
</div>
<hr className='facultyHr' />





</>

  )
})}







</div>
    </div>
  )
}

export default Honours