import { message } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Input } from "../formGroup/Index";
function Honours(props) {
  const [edustate, setEduState] = useState(false);

  const [awardName, setAwardName] = useState("");
  const [awardedYear, setAwardedYear] = useState("");
  const [awardedBy, setAwardedBy] = useState("");
  // console.log(props.data);

  const [editState, setEditState] = useState(false);
  const [state, setState] = useState('');
  const [tde, setTde] = useState([]);
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const addmore = (e) => {
    setTde([
      ...tde,
      { awardName: awardName, awardedYear: awardedYear, awardedBy: awardedBy },
    ]);
    setEduState(false);
    setState({ [e.target.name]: "" });
  };
  const removeBatch = (id) => {
    const updateList = tde.filter((elm, ind) => {
      return ind !== id;
    });
    setTde(updateList);
  };
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const editA = (awardName, awardedBy, awardedYear, id) => {
// console.log(awardName, awardedBy, awardedYear, id)
    setAwardName(awardName);
    setAwardedBy(awardedBy);
    setAwardedYear(awardedYear);
    setId(id);
    setEduState(!edustate);
    setIsEdit(true);
  };
  const [hdn, setHdn] = useState(false);

  const [k, setK] = useState("hidden");
  const addD = () => {
    setEduState(!edustate);
    setAwardName("");
    setAwardedBy("");
    setAwardedYear("");
    setId("");
    setIsEdit(false);
    setHdn(!hdn);
    if (hdn === false) {
      setK("block");
    } else {
      setK("hidden");
    }
  };
  const cancel = () => {
    setAwardName("");
    setAwardedBy("");
    setAwardedYear("");
    isEdit(false);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/v1/honours/awards", {
        awardName: awardName,
        awardedBy: awardedBy,
        awardedYear: awardedYear,
      })
      .then(function (response) {
        // console.log(response);
        // alert(response.data.message);
        // console.log({role:response.data.role});
        // setTde([...tde, {  "subject": subject, "exptr": exptr, "brief":brief }]);
        setEduState(false);
        setState({ [e.target.name]: "" });
        // console.log(response.data);
        message.success(response.data.message);
        props.onClick()
        // alert('response.data.message')
        // setState({ ...intialValue })
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response.data.errors);
        message.error(error.response.data.errors);
      });
  };
  const checkFillidOrNot = () => {
    if (props.data.expertise.length >= 1) {
      // setHide(true)
      // setEduState(true)
      // setExpertExist(true)
      // setExpert(props.data.expertise[0].specialization)
      // setBrief(props.data.expertise[0].briefIntro)
      // setId(props.data.expertise[0].id)
    }
  };
  const remove = (id) => {
    // alert(id)
    props.loader(true);
    axios
      .delete(`/api/v1/honours/awards/${id}`)
      .then(function (response) {
        props.loader(false);
        setEduState(false);
        message.success(response.data.message);
        props.onClick();
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response.data.errors);
        props.loader(false);
        message.error(error.response.data.errors);
      });
  };
  const update = () => {
    props.loader(true);
    axios
      .patch(`/api/v1/honours/awards/${id}`, {
        awardName: awardName,
        awardedBy: awardedBy,
        awardedYear: awardedYear,
      })
      .then(function (response) {
        // console.log(response.data);
        props.loader(false);
        setAwardName("");
        setAwardedBy("");
        setAwardedYear("");
        message.success(response.data.message);
        props.onClick();
        setIsEdit(false);
        setEduState(false);

      })
      .catch(function (error) {
        props.loader(false);
        console.log(error);
        console.log(error.response.data.errors);

        message.error(error.response.data.errors);
      });
  };

  useEffect(() => {
    checkFillidOrNot();
  });
  return (
    <>
      <div>
        <div className="  " style={{ width: "100%" }}>
          <div className="flex space-x-2 justify-between ">
            <h1 className=" text-[1.7rem] mt-[2rem]" id="edu">
              Honours and Awards
            </h1>
            <div className="flex gap-2">
              <button
                className="mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                onClick={addD}
              >
                Add
              </button>
            </div>
          </div>

          {edustate ? (
            <>
              <div className="grid grid-cols-2  justify-between gap-2">
                <div className="w-full">
                  <Input
                    placeholder="Award's name"
                    label="Award's name"
                    name="awardName"
                    type="text"
                    value={awardName}
                    onChange={(e)=>setAwardName(e.target.value)}
                  />
                </div>
                <Input
                  placeholder="Awarded by"
                  label="Awarded by"
                  name="awardedBy"
                  type="text"
                  value={awardedBy}
                  onChange={(e)=>setAwardedBy(e.target.value)}
                />
                <div className="col-span-full">
                  <Input
                    placeholder="Awarded in"
                    label="Awarded year"
                    name="awardedYear"
                    type="number"
                    value={awardedYear}
                    onChange={(e)=>setAwardedYear(e.target.value)}
                  />
                </div>

                <button
                    type="submit"
                    className="mt-auto px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-green-600 hover:text-black border-2 border-gray-900 focus:outline-none"
                    onClick={isEdit ? update : onSubmit}
                  >
                    {isEdit ? "Update" : "Submit"}
                  </button>
                  <button
                    className={`${k} mt-[2rem]  px-6 py-2 transition ease-in duration-200 uppercase rounded-lg hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none`}
                    onClick={isEdit ? cancel : addD}
                  >
                    {isEdit ? "Cancel" : "Close"}{" "}
                  </button>
              </div>
            </>
          ) : null}

          {props.data.honours.map((elm, ind) => {
            return (
              <>
                <div
                  className="flex gap-2 items-center justify-between mt-7"
                  style={{ width: "100%" }}
                  key={ind}
                >
                  <div className="edu1">
                    {elm.awardName} from {elm.awardedBy}
                  </div>
                  <div className="edu2"> {elm.awardedYear}</div>
                  <div className="flex space-x-4">
                    <button
                      className=" my-auto  px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-emerald-400 hover:text-black border-2 border-green-900 focus:outline-none"
                      onClick={() => {
                        editA(elm.awardName, elm.awardedBy, elm.awardedYear, elm.id);
                      }}
                    >
                      edit
                    </button>
                    <button
                      className=" px-4 py-1 transition ease-in duration-200 uppercase rounded-lg hover:bg-red-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                      onClick={() => remove(elm.id)}
                    >
                      delete
                    </button>
                  </div>
                </div>
                <hr className="facultyHr" />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Honours;
