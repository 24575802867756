import React, { useState } from "react";
import { Alert, Dropdown, Input } from "../../formGroup/Index";
import axios from "axios";
export default function Edit(props) {
  console.log(props.editFaculty);
  const [name, setName] = useState(props.editFaculty.name);
  const [photo, setPhoto] = useState(props.editFaculty.photo);

  const [email, setEmail] = useState(props.editFaculty.email);
  const [mobileNo, setMobileNo] = useState(props.editFaculty.mobileNo);
  const [dob, setDob] = useState(props.editFaculty.dob);
  const [program, setProgram] = useState(props.editFaculty.program);
  const [session, setSession] = useState(props.editFaculty.session);
  const [yearOfAdmission, setYearOfAdmission] = useState(props.editFaculty.yearOfAdmission);
  const [gender, setGender] = useState(props.editFaculty.gender);
  console.log( "props ",props)
  const editUser = () => {
    axios
      .put(`/api/v1/almuni-registration-data/${props.editFaculty.id}`, {
        gender,
        name,
        email,
        mobileNo,
        dob,
        program,
        session,

        yearOfAdmission,
      })
      .then(function (response) {
        // console.log(response);
        alert(response.data.message);
        props.setShowModal(false);
        props.updateData();
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.errors);
      });
  };

  return (
    <>
      {props.showModal ? (
        <>
          <div className="justify-center  items-center flex  overflow-y-auto overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-10/12  my-6 mx-auto   ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start gap-2   justify-between p-5 border-b border-solid border-slate-200 rounded-t">

                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="flow-root">

  <dl className="m-3 divide-y grid grid-cols-3 gap-4 divide-gray-100 text-sm">
      <div className="pl-12">

    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Name</dt>
      <dd className="text-gray-700 sm:col-span-2">{props.editFaculty.name}</dd>
    </div>
    <div
      className="grid grid-cols-1  gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium  text-gray-900">Gender</dt>
      <dd className="text-gray-700 sm:col-span-2">{props.editFaculty.gender}</dd>

    </div>

    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Date of Birth</dt>
      <dd className="text-gray-700 sm:col-span-2">{props.editFaculty.dob}</dd>
    </div>

    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Mobile Number</dt>
      <dd className="text-gray-700 sm:col-span-2">{props.editFaculty.mobileNo}</dd>
    </div>

    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Email</dt>
      <dd className="text-gray-700 sm:col-span-2">
       {props.editFaculty.email}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Program</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.program}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium  text-gray-900">Mailing Address</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.residentOrganization}
      </dd>
    </div>
    </div>
    <div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Session</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.session}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Year of Admission</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.yearOfAdmission}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Year of Degree Awarded</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.yearOfDegree}
      </dd>
    </div>

    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Registration No</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.registrationNo}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-gray-900">Present Designation</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.presentDesignation}
      </dd>
    </div>
    <div
      className="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-4 sm:gap-4"
    >
      <dt className="font-medium sm:col-span-2 text-gray-900">Full address of the present organization</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {props.editFaculty.presentOrganization}
      </dd>
    </div>

    </div>
    <div>
      <div className="flex">
        <div className="m-auto">
        <img className="border-2 m-auto w-1/2 h-1/2 border-black" src={props.editFaculty.photo} alt="picture"/>

        </div>
      </div>

    </div>
  </dl>
</div>
                {/*footer*/}
                <div className="flex items-center justify-end gap-x-4 p-6 border-t border-solid border-slate-200 rounded-b">
                  <div className="flex-1">


                  {/* <button
                    className="text-red-500  background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => props.setShowModal(false)}
                  >
                    Close
                  </button> */}
                  </div>
                  <div className="">
                  <button
                    className="bg-red-500 mx-4 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => props.setShowModal(false)}
                  >
                    Close
                  </button>
                   {/*
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => editUser()}
                    // onClick={() => props.setShowModal(false)}
                  >
                    Accept
                  </button>
                   */}'
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
