import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function StudyMaterial(props) {
  const [study, setStudy] = useState([])
  const [loader, setLoader]= useState(false)
  // console.log(study)
  const studyMaterials = () => {
    setLoader(true)
    axios.get('/api/v1/find-file')
      .then(function (response) {
        // handle success
        setStudy(response.data)
        loader(false)
        // console.log(response.data.userList);
      })
      .catch(function (error) {
        // handle error
        setLoader(false)
        console.log(error);
      })
  }
  const studyMaterialsDownload = (fileName) => {

    setLoader(true)
    axios.get(`/api/v1/download-study-material/${fileName}`)
      .then(function (response) {
        // handle success

        setLoader(false)
        // console.log(response.data.userList);
      })
      .catch(function (error) {
        // handle error
        setLoader(false)
        console.log(error);
      })
  }
  useEffect(() => {
    studyMaterials()
  }, [])
  return (
    <div>

      <div className="  " style={{ width: "100%" }}>
        <h1 className=" text-[1.7rem] mt-[2rem]" id="edu">
          StudyMaterial
        </h1>
        <div class="overflow-x-auto mt-2 rounded-lg border border-gray-200">
          <table class="min-w-full divide-y-2 divide-gray-200 text-sm">
            <thead>
              <tr>
                <th class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Semester
                </th>
                <th class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Name of topic
                </th>

                <th class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  File
                </th>
                <th class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Date of upload
                </th>
              </tr>
            </thead>
            {
                study.toReversed().map((a, ind) => {
                  // console.log( props.data._id, "l")
                  return (
                    <>
                      {
                          props.data._id === a.authorId &&
                        <tbody class="divide-y divide-gray-200" key={ind}>
                          <tr>
                            <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">{a.semester}</td>
                            <td class="whitespace-nowrap  border px-4 py-2 font-medium text-gray-900">
                              {a.topicName}
                            </td>
                            <td>
                              <center>
                                <Link to={`/api/v1/download-study-material/${a.fileName}`} target="_blank" class="whitespace-nowrap border px-4 py-2 text-gray-700"
                                  onClick={() => { studyMaterialsDownload(a.fileName) }}

                                >Download</Link>
                              </center>
                            </td>

                            <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">{new Date(a.Date).getDate() + " / " + new Date(a.Date).getMonth() + " / " + new Date(a.Date).getFullYear()}</td>


                          </tr>
                        </tbody>
                      }
                    </>
                  )
                })
              }
          </table>
        </div>
        <hr className="facultyHr" />
      </div>
    </div>
  );
}

export default StudyMaterial;
