import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

import Announcement from "./Annoucement";
import AnnouncementTable from "./AnnouncementTable";
import axios from "axios";
import Spinner from "../../page/facultydashboard/formGroup/Spinner";
function AnnouncementPreview() {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = useState(false);
  // console.log(data)
  const siteData = () => {
    axios
      .get("/api/v1/find-file-anouncement")
      .then(function (response) {
        setData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  React.useEffect(() => {
    siteData();
  }, [data]);
  // console.log(data)
  return (
    <div>
      {loader && <Spinner></Spinner>}
      <div style={{ width: "100%" }}>
        <div>
          <div style={{ width: "100%", height: "100vh", overflow: "scroll" }}>
            <Navbar />
            <div className="flex bg-[#F4F4F5]" style={{ width: "100%" }}>
              <Sidebar />
              <div className="" style={{ width: "100%" }}>
                <Announcement onClick={siteData} />
                <div className="m-4">
                  {" "}
                  <AnnouncementTable onClick={siteData} data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnnouncementPreview;
