import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Slider from "./Slider";
import Notice from "../../Notice";
import Hero from "./Hero";
import ImgGallery from "./ImgGallery";
import Footer from "../../Footer";
import Topbar from "./Topbar";
import Crousal from "./Crousal";
import Gallerypreview from "./Gallerypreview";
import axios from "axios";
import Spinner from "../../page/facultydashboard/formGroup/Spinner";
import HeroSection from "../../HeroSection";

function LandingPreview() {
  const [datas, setData] = React.useState({})
  const [loader,setLoader] = useState(true)
  // console.log(datas)
  const siteData = () => {
    axios.get('/api/v1/api/site/data')
      .then(function (response) {

        // handle success
        // console.log(response);
        setData(response.data[0])
        setLoader(false)

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }
  React.useEffect(() => {
    siteData()
  }, [])
  return (
    <>
     {
                loader && <Spinner />
            }
      <Topbar  />
      <Navbar  datas={datas}  />
      <Crousal  datas={datas} />
      <Notice  datas={datas} />
      <HeroSection datas={datas}/>

      <Gallerypreview  datas={datas} />
      <Footer datas={datas}  />
    </>
  );
}

export default LandingPreview;
